import { ApolloError, gql, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { GraphQLError } from 'graphql';

import { Analytics } from 'src/lib/analytics';
import { appLinkContext } from 'src/lib/apollo/link';
import {
  BillingPlanTier,
  NewOrgMutation,
  NewOrgMutationVariables,
} from 'src/lib/graphqlTypes/types';
import { useLDFlag } from 'src/lib/launchDarkly';

import { FormData } from '../utils';

const newOrgMutation = gql<NewOrgMutation, NewOrgMutationVariables>`
  mutation NewOrgMutation(
    $id: ID!
    $companyUrl: String
    $organizationName: String
    $planId: String
  ) {
    newAccount(
      id: $id
      companyUrl: $companyUrl
      organizationName: $organizationName
      planId: $planId
    ) {
      id
      currentPlan {
        id
        tier
      }
    }
  }
`;

export function hasAccountExistsErrorMsg(msg: string) {
  return new RegExp(/already exists/i).test(msg);
}

function isAccountExistsError(error: GraphQLError) {
  return hasAccountExistsErrorMsg(error.message);
}

export interface CreateOrgArgs {
  userId?: string;
  values: FormData;
  planId?: string;
}

export interface SuccessfulResponse {
  isSuccess: true;
  isUsageTier: boolean | undefined;
  orgId?: string;
}
export interface ErrorResponse {
  isSuccess?: false;
  error?: unknown | ApolloError;
  formErrors: Record<string, string> | undefined;
}

export type Result = Promise<SuccessfulResponse | ErrorResponse>;

export function useCreateOrg() {
  const [newAccount, { loading }] = useMutation(newOrgMutation, {
    context: appLinkContext({
      ignoreSentryReporting: [isAccountExistsError],
    }),
  });

  const enterpriseTrialForAllEnabled = useLDFlag(
    'betelgeuse-enterprise-trial-for-all',
  );

  const createOrg = async ({
    userId,
    values,
    planId,
  }: CreateOrgArgs): Result => {
    let result;

    /**
     * Historically, when provisioning a new account/org in Apollo Studio
     * the system would defer to the planId passed in this `createOrg` helper,
     * which most recently was defaulted to `SERVERLESS`.
     *
     * For the purpose of the June 2024 br-096-onboarding-charlie
     * Big Rock experiment (see https://docs.google.com/document/d/1C_-OLjmLXZAnnNp9uNxZ3wn8zFu1IHgfal7Z0O0R0xg/edit#heading=h.kf5br2ajmwm4), we are setting the default plan for a new organization
     * as `Enterprise Trial` if the `enterpriseTrialForAllEnabled` LaunchDarkly
     * Flag is set to true (work for this was done in this PR: https://github.com/mdg-private/studio-ui/pull/10744).
     *
     * Before removing this flag, we will need to optimize how this default is generated
     * (i.e. a plan helper) in order to have more control in providing a default plan, rather than
     * deferring to a planId prop. A ticket to track this implementation is in the frontend backlog
     * here: https://apollographql.atlassian.net/browse/DEBT-182
     */
    const defaultValueForPlanId = enterpriseTrialForAllEnabled
      ? 'sub-engine-ent-trial'
      : undefined;

    try {
      result = await newAccount({
        variables: {
          id: values.accountId,
          companyUrl: values.companyUrl || null,
          organizationName: values.accountName || null,
          planId: planId || defaultValueForPlanId,
        },
      });
      if (result?.errors) {
        throw new Error(
          result.errors.map((e) => e.message).join('; ') ||
            'Unable to create new organization.',
        );
      }
      if (userId) {
        Analytics.track(userId, 'Org Created', {
          category: 'Onboarding',
        });
      }

      return {
        orgId: result.data?.newAccount?.id,
        isSuccess: true,
        isUsageTier:
          result.data?.newAccount?.currentPlan?.tier ===
          BillingPlanTier.USAGE_BASED,
      };
    } catch (error) {
      Sentry.captureException(`Unable to create account: ${error}`);
      return {
        isSuccess: false,
        error,
        formErrors: result?.errors?.reduce(
          (errs: Record<string, string>, cur) => {
            return { ...errs, [cur.name]: cur.message };
          },
          {},
        ),
      };
    }
  };

  return {
    createOrg,
    loading,
  };
}
