import { ApolloError } from '@apollo/client';
import { Alert } from '@apollo/orbit';
import React from 'react';

import { ClickableText } from '../common/clickableText/ClickableText';
import { ContactSupportLink } from '../support-request/contactSupportLink/ContactSupportLink';

interface ContactSupportMessageProps {
  message?: React.ReactNode;
}
const ContactSupportMessage = ({ message }: ContactSupportMessageProps) => (
  <>
    {message ?? 'Something went wrong.'} Please try again or{' '}
    <ClickableText
      className="font-semibold underline"
      as={<ContactSupportLink />}
    >
      contact support
    </ClickableText>{' '}
    if this continues.
  </>
);

export const ApolloErrorMessage = ({ error }: { error: ApolloError }) => {
  const match = error.message.match(/^.*?: (.*)$/) ?? [];
  const message = match?.length > 0 ? match[1] : error.message;
  return <>{message}</>;
};

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    'title'
  > {
  error?: ApolloError;
}

/**
 * Renders `children` as the content
 */
export const ErrorMessage = ({
  children,
  className,
  error,
  ...otherProps
}: Props) => (
  <Alert {...otherProps} className={className} status="error">
    {children ?? (error && <ApolloErrorMessage error={error} />) ?? (
      <ContactSupportMessage />
    )}
  </Alert>
);
