import { ServerError, ServerParseError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { GraphQLError } from 'graphql';

import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';

const ignoredHttpStatusCodes = [401];

const isTransportError = (
  networkError: Error | ServerError | ServerParseError,
): networkError is ServerError | ServerParseError =>
  Object.prototype.hasOwnProperty.call(networkError, 'statusCode');

export interface SentryReportingContext {
  /**
   * Callback functions which should return true if the error should be ignored,
   * otherwise should return false.  Filtered errors will still be forwarded
   * through the link chain, just not reported to Sentry.
   */
  ignoreSentryReporting?: ((error: GraphQLError) => boolean)[];
}

export const sentryErrorReporting = onError(
  ({ graphQLErrors, networkError, operation }) => {
    // attempt to make this somewhat more type-safe
    const { ignoreSentryReporting }: SentryReportingContext =
      operation.getContext();

    graphQLErrors
      ?.filter((e) => {
        try {
          const wasIgnored = [
            ignorePermissionsErrors,
            ...(ignoreSentryReporting ?? []),
          ].some((ignoreError) => ignoreError(e));
          return !wasIgnored;
        } catch (error) {
          console.warn(error); // eslint-disable-line no-console
        }
        // if not ignored, send to Sentry
        return true;
      })
      .forEach((e) => {
        Sentry.captureMessage(e.message, {
          level: 'error',
          tags: {
            component: 'graphql',
            operationName: operation.operationName,
          },
        });
      });

    if (
      networkError &&
      isTransportError(networkError) &&
      !ignoredHttpStatusCodes.includes(networkError.statusCode)
    ) {
      Sentry.captureMessage(networkError.message, {
        // use warning level here as it most likely is an auth or transport issue
        level: 'warning',
        tags: {
          component: 'link.network',
          statusCode: `${networkError.statusCode}`,
          operationName: operation.operationName,
        },
      });
    }
  },
);
