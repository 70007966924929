import { GraphQLSchema, isInterfaceType, isObjectType } from 'graphql';
import React from 'react';
import { useHistory } from 'react-router';

import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { useTrackCustomEvent } from 'src/hooks/useTrackCustomEvent';
import Config from 'src/lib/config';
import { mergeQueryParams } from 'src/lib/routing';

import { parseGraphRef } from '../../hooks/useGraphRef';
import { useInternalGraphLinking } from '../../hooks/useInternalGraphLinking';
import { SandboxConnectionSettings } from '../connectionSettingsModal/sandboxConnectionSettingsModal/SandboxConnectionSettingsModal';
import { FieldWithParent } from '../search/searchSchema/searchSchema';

import { EditorTabState } from './useExplorerState/useEditorTabState/useEditorTabState';
import { HeaderEntry } from './useExplorerState/useHeadersManagerContext/shared';
import { headersStringFromArray } from './useExplorerState/useHeadersManagerContext/useHeadersManagerContext';
import { SearchStateAction } from './useExplorerState/useSearchState';
import { useHandleOpenSavedOperation } from './useHandleOpenSavedOperation';
import {
  useLocalStorageGraphIdentifier,
  useReadFromPerGraphIdentifierLocalStorage,
} from './usePerGraphIdentifierLocalStorage';

/**
 * Print headers state to be stored in the url. This will match the expected
 * input shape for safeParseHeadersState, and also the format we ask customers
 * to send from their direct explorer links (eg apollo server).
 *
 * Expected format is '{ [header name]: [header value], ... }'
 *
 * Header entries not marked as 'checked' will be ignored.
 */
export function printHeadersState(headers: HeaderEntry[]): string | undefined {
  const filteredHeaders = headers.filter(
    ({ checked, headerName, value }) => checked && headerName && value,
  );
  return filteredHeaders.length
    ? JSON.stringify(
        Object.fromEntries(
          filteredHeaders.map(({ headerName, value }) => [headerName, value]),
        ),
      )
    : undefined;
}

/**
 * Attempt to parse headers state from the url string, return partial headers
 * if full object is not valid.
 *
 * Expected format is '{ [header name]: [header value], ... }'
 */
export function safeParseHeadersState(headersString: string): HeaderEntry[] {
  try {
    const maybeHeadersObject = JSON.parse(headersString);
    if (
      !maybeHeadersObject ||
      typeof maybeHeadersObject !== 'object' ||
      Array.isArray(maybeHeadersObject)
    ) {
      return [];
    }
    return Object.entries(maybeHeadersObject).flatMap(([headerName, value]) => {
      if (typeof value !== 'string') {
        return [];
      }
      return { headerName, value, checked: true, key: Math.random() };
    });
  } catch {
    // ignore errors
    return [];
  }
}

function getSelectedItem(
  searchQuery: string,
  schema: GraphQLSchema,
): FieldWithParent | null {
  const [parentName, fieldName] = searchQuery.split('.');
  const parentNode = schema.getType(parentName);
  if (
    !parentNode ||
    !(isInterfaceType(parentNode) || isObjectType(parentNode))
  ) {
    return null;
  }
  const fieldNode = parentNode.getFields()[fieldName];
  if (!fieldNode) {
    return null;
  }
  return {
    parentMatch: {
      type: parentNode,
      matchedSegments: [{ text: parentName, matched: true }],
    },
    fieldMatch: {
      field: fieldNode,
      matchedSegments: [{ text: parentName, matched: true }],
    },
  };
}

const STABLE_EMPTY_OBJECT = {};

/**
 * Checks the url for a shared document and variables. It will clear them out
 * and restore them in the editors
 */
export const useLoadExplorerStateFromURL = ({
  dispatchSearchAction,
  schema,
  editorTabState,
  sandboxConnectionSettingsInitialValue,
}: {
  dispatchSearchAction: (action: SearchStateAction) => void;
  schema: GraphQLSchema;
  editorTabState: EditorTabState;
  sandboxConnectionSettingsInitialValue: SandboxConnectionSettings;
}) => {
  const history = useHistory();
  const { currentExplorerRouteConfig } = useInternalGraphLinking();
  const {
    document,
    variables,
    headers,
    preflightOperationScript,
    postflightOperationScript,
    savedCollectionId,
    savedCollectionEntryId,
    sharedCollectionOriginVariantRef,
    searchQuery,
    includeCookies,
  } = useRouteParams(currentExplorerRouteConfig);
  const [sandboxUrl] = useLocalStorage('sandboxUrl');

  const trackCustomEvent = useTrackCustomEvent();

  const wipeStateFromURL = React.useCallback(() => {
    history.replace({
      ...history.location,
      search: mergeQueryParams(history.location.search, {
        [Config.queryParameters.ExplorerDocument]: undefined,
        [Config.queryParameters.ExplorerVariables]: undefined,
        [Config.queryParameters.ExplorerHeaders]: undefined,
        [Config.queryParameters.ExplorerPreflightOperationScript]: undefined,
        [Config.queryParameters.ExplorerPostflightOperationScript]: undefined,
        [Config.queryParameters.ExplorerSearchQuery]: undefined,
        [Config.queryParameters.ExplorerURLState]: undefined,
        [Config.queryParameters.ExplorerURLIncludeCookies]: undefined,
        [Config.queryParameters.SavedCollectionEntryId]: undefined,
        [Config.queryParameters.SavedCollectionId]: undefined,
        [Config.queryParameters.SharedCollectionOriginVariantRef]: undefined,
      }),
    });
  }, [history]);

  const localStorageGraphIdentifier =
    // we don't store headers by sandbox endpoint, we just store them for the sandbox as a whole
    useLocalStorageGraphIdentifier({
      shouldNamespaceSandboxByEndpoint: false,
      shouldOnlyStoreForRegisteredGraphs: false,
    });
  const readHeaderDefinitionsPerTab = useReadFromPerGraphIdentifierLocalStorage(
    {
      key: 'tabbedHeaderDefinitions',
      graphIdentifier: localStorageGraphIdentifier,
      stableInitialValue: STABLE_EMPTY_OBJECT,
    },
  );

  const { handleOpenSavedOperation } = useHandleOpenSavedOperation(
    editorTabState.dispatchSavedOperationLocalStateAction,
  );

  const [sandboxConnectionSettings, _, setSandboxConnectionSettingsForKey] =
    usePerKeyLocalStorage({
      localStorageKey: 'sandboxConnectionSettings',
      key: sandboxUrl,
      initialValue: sandboxConnectionSettingsInitialValue,
    });
  React.useEffect(() => {
    if (
      !(
        document ||
        variables ||
        headers ||
        preflightOperationScript ||
        postflightOperationScript ||
        savedCollectionId ||
        searchQuery ||
        includeCookies
      )
    ) {
      return;
    }

    const {
      createNewTab,
      tabIds,
      setSelectedTab,
      getInitialOperationByTab,
      getInitialVariablesByTab,
      getInitialPreflightOperationScriptByTab,
      getInitialPostflightOperationScriptByTab,
    } = editorTabState;
    const headerDefinitionsPerTab = readHeaderDefinitionsPerTab();

    if (
      document ||
      variables ||
      headers ||
      preflightOperationScript ||
      postflightOperationScript
    ) {
      // If we have an existing tab that matches the url operation open it,
      // otherwise create a new tab
      const matchingTab = tabIds.find((tabId) => {
        if (document && document !== getInitialOperationByTab(tabId)) {
          return false;
        }
        if (variables && variables !== getInitialVariablesByTab(tabId)) {
          return false;
        }
        const headersForTab = headerDefinitionsPerTab[tabId] ?? [];
        if (headers && headers !== headersStringFromArray(headersForTab)) {
          return false;
        }
        if (
          preflightOperationScript &&
          preflightOperationScript !==
            getInitialPreflightOperationScriptByTab(tabId)
        ) {
          return false;
        }
        if (
          postflightOperationScript &&
          postflightOperationScript !==
            getInitialPostflightOperationScriptByTab(tabId)
        ) {
          return false;
        }
        return true;
      });
      if (matchingTab) {
        setSelectedTab(matchingTab);
      } else {
        createNewTab({
          operation: document,
          variables,
          headers:
            headers === undefined ? undefined : safeParseHeadersState(headers),
          preflightOperationScript,
          postflightOperationScript,
        });
        trackCustomEvent({
          action: 'load_document_from_url',
          category: 'Explorer',
        });
      }
    }

    if (savedCollectionId && savedCollectionEntryId) {
      handleOpenSavedOperation({
        collectionId: savedCollectionId,
        collectionEntryId: savedCollectionEntryId,
        sharedCollectionOriginVariantRef: sharedCollectionOriginVariantRef
          ? parseGraphRef(sharedCollectionOriginVariantRef) ?? undefined
          : undefined,
      });
    }

    if (includeCookies) {
      const sendCookies = includeCookies === 'true';
      if (sendCookies !== sandboxConnectionSettings.sendCookies) {
        setSandboxConnectionSettingsForKey(
          (currentSandboxConnectionSettings) => ({
            ...currentSandboxConnectionSettings,
            sendCookies,
          }),
          sandboxUrl,
        );
      }
    }

    if (searchQuery) {
      dispatchSearchAction({
        type: 'set search query',
        searchQuery,
      });
      const selectedItem = getSelectedItem(searchQuery, schema);
      if (selectedItem) {
        dispatchSearchAction({
          type: 'select field',
          selectedItem,
        });
      }
    }

    wipeStateFromURL();
  }, [
    document,
    variables,
    searchQuery,
    includeCookies,
    wipeStateFromURL,
    dispatchSearchAction,
    schema,
    headers,
    editorTabState,
    readHeaderDefinitionsPerTab,
    trackCustomEvent,
    setSandboxConnectionSettingsForKey,
    sandboxConnectionSettings,
    sandboxUrl,
    history,
    savedCollectionId,
    handleOpenSavedOperation,
    savedCollectionEntryId,
    sharedCollectionOriginVariantRef,
    preflightOperationScript,
    postflightOperationScript,
  ]);
};
