import {
  CardHeader as ChakraCardHeader,
  CardHeaderProps as ChakraCardHeaderProps,
  forwardRef,
} from '@chakra-ui/react';
import React, { CSSProperties, Children, isValidElement } from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

interface StyleProps extends CSSProperties {
  '--child-count': number;
}

export type CardHeaderProps = OmitChakraProps<ChakraCardHeaderProps>;

export const CardHeader = forwardRef<CardHeaderProps, 'div'>(
  ({ children, ...props }, ref) => {
    return (
      <ChakraCardHeader
        ref={ref}
        style={
          {
            '--child-count': Children.count(
              Children.toArray(children).filter(isValidElement),
            ),
          } as StyleProps
        }
        {...props}
      >
        {children}
      </ChakraCardHeader>
    );
  },
);
