import classnames from 'classnames';
import React from 'react';

/**
 * A text wrapper component that adds cursor and hover underline styles
 * to its children.
 */

type NoneOf<T> = { [key in keyof T]?: never };
type Props = BaseProps & (LinkProps | NoneOf<Omit<LinkProps, 'as'>>);

interface BaseProps {
  className?: string;

  /**
   * The text content
   */
  children?: React.ReactNode;

  /**
   * Override how the `button` is rendered. You can pass either an intrinisic jsx element as a string (like "a") or a react element (`<a />`)
   *
   * If you pass a react element, props that we add are spread onto the input.
   *
   * @default "button"
   */
  as?: React.ReactElement<{ className?: string }> | keyof JSX.IntrinsicElements;

  /**
   * `onClick` handler to be passed to the element
   */
  onClick?: React.MouseEventHandler;

  /**
   * Whether the link is currently disabled
   */
  disabled?: boolean;
}

interface LinkProps {
  as: 'a';

  /**
   * `href` link to be passed to the element
   */
  href?: string;

  /**
   * `target` to be passed to the element (for links)
   */
  target?: string;

  /**
   * `rel` to be passed to the element (for links)
   */
  rel?: string;

  /**
   * `title` to be passed to the element (for links)
   */
  title?: string;
}

const defaultTextElement: React.ReactElement<{ className?: string }> = (
  <button type="button" />
);

export const ClickableText = React.forwardRef<HTMLElement, Props>(
  (
    { className, children, disabled, as = defaultTextElement, ...otherProps },
    ref,
  ) => {
    const handleClick = (e: React.MouseEvent) => {
      if (disabled) {
        e.preventDefault();
        e.stopPropagation();
      }

      otherProps.onClick?.(e);
    };

    const props = {
      className: classnames(
        disabled ? '' : 'cursor-pointer hover:underline',
        className,
        React.isValidElement(as) ? as.props.className : null,
      ),
      ...(disabled ? { 'aria-disabled': true } : {}),
      onClick: handleClick,
      children,
      ref,
      ...otherProps,
    };

    return React.isValidElement(as)
      ? React.cloneElement(as, props)
      : React.createElement(as, props);
  },
);
