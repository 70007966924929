import { ApolloError, gql } from '@apollo/client';
import IconSuccess from '@apollo/icons/default/IconSuccess.svg';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  Spinner,
} from '@apollo/orbit';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as orgRoutes from 'src/app/account/routes';
import { ClickableText } from 'src/components/common/clickableText/ClickableText';
import { Form as FormWrapper } from 'src/components/common/form/Form';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { URLField } from 'src/components/urlField/URLField';
import { useClearMeFromApolloCache } from 'src/hooks/useClearMeFromApolloCache';
import { useDebouncedQuery } from 'src/hooks/useDebouncedQuery';
import { useIdentity } from 'src/hooks/useIdentity';
import Config from 'src/lib/config';
import { FormErrors } from 'src/lib/forms/FormErrors';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { PricingRouter } from 'src/lib/routers';
import { OrgIDValidator } from 'src/lib/validators';

import { CreateOrgArgs, useCreateOrg } from '../../hooks';
import { validateAccountId, validateAccountInfo } from '../../utils';

const { appHost } = Config;

type FormData = CreateOrgArgs['values'];

const DEFAULT_FORM_DATA: FormData = {
  accountName: '',
  accountId: '',
  companyUrl: '',
};

export const accountIdAvailableQuery = gql`
  query UI__AccountIdAvailableQuery($accountId: ID!) {
    accountIDAvailable(id: $accountId)
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const NewOrganizationModal = ({ isOpen, onClose }: Props) => {
  const { me } = useIdentity();
  const { createOrg, loading } = useCreateOrg();
  const history = useHistory();
  const clearMeFromCache = useClearMeFromApolloCache();

  const [rawValues, setValues] = useState<FormData>(DEFAULT_FORM_DATA);
  const [shouldDefaultId, setShouldDefaultId] = React.useState(true);
  const [createOrgError, setCreateOrgError] = useState<string>();
  const [formErrors, setFormErrors] = useState<FormErrors<FormData>>({});
  const [availabilityState, setAvailabilityState] =
    useState<AvailabilityState>('unknown');

  React.useEffect(() => {
    if (rawValues.accountId) {
      setShouldDefaultId(false);
    }
  }, [rawValues.accountId, setShouldDefaultId]);

  const values = {
    ...rawValues,
    accountId: shouldDefaultId
      ? OrgIDValidator.makeFromName(rawValues.accountName)
      : rawValues.accountId,
  };

  React.useEffect(() => {
    // If modal is closed, reset states
    if (!isOpen) {
      if (Object.values(rawValues).some(Boolean)) setValues(DEFAULT_FORM_DATA);
      if (!shouldDefaultId) setShouldDefaultId(true);
      if (createOrgError) setCreateOrgError('');
      if (Object.values(formErrors).some(Boolean)) setFormErrors({});
      if (availabilityState !== 'unknown') setAvailabilityState('unknown');
    }
  }, [
    availabilityState,
    createOrgError,
    formErrors,
    isOpen,
    rawValues,
    shouldDefaultId,
  ]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loading) {
      return;
    }

    const validationErrors = validateAccountInfo(values);
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      return;
    }

    try {
      const createOrgResult = await createOrg({
        userId: me?.id,
        values,
      });
      if (createOrgResult.isSuccess) {
        onClose();
        if (me?.id) clearMeFromCache({ userId: me.id });
        const pathname = orgRoutes.graphs.path({
          orgId: values.accountId,
        });
        history.push({
          pathname,
        });
      } else if (createOrgResult.formErrors) {
        setFormErrors(createOrgResult.formErrors);
      } else if (createOrgResult.error instanceof ApolloError) {
        setCreateOrgError(createOrgResult.error.message);
      }
    } catch (err) {
      setCreateOrgError(
        'An error occurred while trying to create your organization. If this persists, please contact support.',
      );
    }
  };

  const handleCancel = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.preventDefault();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <FormWrapper onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>Create a new organization</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {createOrgError && <ErrorMessage>{createOrgError}</ErrorMessage>}
            <AccountDetailsInput
              values={values}
              setValues={setValues}
              errors={formErrors}
              setErrors={setFormErrors}
              setAvailabilityState={setAvailabilityState}
            />
            <div className="flex flex-col gap-4 text-xs leading-tight text-placeholder">
              <p>You will be the account owner for this organization.</p>
              <p>
                Your organization will be created on the{' '}
                <b>Serverless (Free)</b> plan. Learn more about our plans on our{' '}
                <ClickableText
                  as="a"
                  href={PricingRouter.path('Home')}
                  className="text-link hover:no-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  pricing page
                </ClickableText>
                .
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="secondary"
                data-analytics-label="Cancel org creation"
                data-analytics-category="Org Creation Flow"
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                data-analytics-label="Create new org"
                data-analytics-category="Org Creation Flow"
                type="submit"
                isLoading={loading}
                loadingText="Create"
                isDisabled={availabilityState === 'unavailable'}
              >
                Create
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </FormWrapper>
    </Modal>
  );
};

export type AvailabilityState =
  | 'unknown'
  | 'checking'
  | 'available'
  | 'unavailable';

interface AccountDetailsInputProps {
  values: FormData;
  setValues: React.Dispatch<React.SetStateAction<FormData>>;
  errors: FormErrors<FormData>;
  setErrors: React.Dispatch<React.SetStateAction<FormErrors<FormData>>>;
  setAvailabilityState: React.Dispatch<React.SetStateAction<AvailabilityState>>;
}

function AccountDetailsInput({
  values,
  setValues,
  errors,
  setErrors,
  setAvailabilityState,
}: AccountDetailsInputProps) {
  const isValidAccountId =
    Object.keys(validateAccountId(values.accountId)).length === 0;
  const availabilityResult = useDebouncedQuery<
    GraphQLTypes.UI__AccountIdAvailableQuery,
    GraphQLTypes.UI__AccountIdAvailableQueryVariables
  >(
    accountIdAvailableQuery,
    {
      variables: { accountId: values.accountId },
      // Don't cache accountIDAvailable results, since we want to always
      // check with the backend for this.
      fetchPolicy: 'no-cache',
    },
    {
      delayMs: 500,
      suspend: !isValidAccountId,
    },
  );

  let availabilityState: AvailabilityState = 'unknown';
  if (availabilityResult.error) {
    /* eslint-disable-next-line no-console  */
    console.warn('Unable to check org ID.', availabilityResult.error);
  } else if (availabilityResult.loading) {
    availabilityState = 'checking';
  } else if (availabilityResult.data && values.accountId) {
    availabilityState = availabilityResult.data.accountIDAvailable
      ? 'available'
      : 'unavailable';
  }

  React.useEffect(
    () => setAvailabilityState(availabilityState),
    [setAvailabilityState, availabilityState],
  );

  return (
    <>
      <FormControl isRequired isInvalid={!!errors.accountName}>
        <FormLabel>Organization name</FormLabel>
        <FormHelperText>
          This is where you'll manage your data graphs and invite collaborators.
        </FormHelperText>
        <Input
          autoFocus
          name="name"
          type="text"
          placeholder="Ex. Wayne Enterprises"
          value={values.accountName}
          onChange={(event) => {
            const accountName = event.target.value;
            setValues((oldValues) => ({ ...oldValues, accountName }));
            setErrors((oldErrors) => ({
              ...oldErrors,
              accountName: undefined,
            }));
          }}
        />
        <FormErrorMessage>
          Organization name {errors.accountName}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isInvalid={availabilityState === 'unavailable' || !!errors.accountId}
      >
        <FormLabel>Organization ID</FormLabel>
        <FormHelperText>
          A unique identifier composed of letters, numbers, and dashes.
        </FormHelperText>
        <Input
          placeholder="Ex. wayne-enterprises"
          value={values.accountId}
          onChange={(event) => {
            const accountId = event.target.value;
            setValues((oldValues) => ({ ...oldValues, accountId }));
            setErrors((oldErrors) => ({
              ...oldErrors,
              accountId: undefined,
            }));
          }}
          rightElement={
            availabilityState === 'checking' ? (
              <Spinner size="tiny" />
            ) : availabilityState === 'available' ? (
              <IconSuccess className="text-icon-success" />
            ) : null
          }
        />
        {availabilityState === 'unavailable' && (
          <FormErrorMessage>
            That organization ID is not available.
          </FormErrorMessage>
        )}
        {errors.accountId && (
          <FormErrorMessage>
            Organization ID {errors.accountId}
          </FormErrorMessage>
        )}
        {values.accountId && (
          <FormHelperText>
            {appHost}/org/{values.accountId}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.companyUrl}>
        <FormLabel>
          Organization domain{' '}
          <span className="text-sm font-normal">(optional)</span>
        </FormLabel>
        <FormHelperText>
          The URL of your company's email addresses and/or primary website.
        </FormHelperText>
        <URLField
          isSocket={false}
          onChange={(companyUrl) => {
            setValues((oldValues) => ({ ...oldValues, companyUrl }));
            setErrors((oldErrors) => ({
              ...oldErrors,
              companyUrl: undefined,
            }));
          }}
          value={values.companyUrl}
          placeholder="http://example.com"
        />
        <FormErrorMessage>
          Organization domain ${errors.companyUrl}
        </FormErrorMessage>
      </FormControl>
    </>
  );
}
