import classnames from 'classnames';
import React from 'react';

export const Pre = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <pre
      className={classnames(
        'my-4 flex rounded bg-silver-light px-4 py-3 text-sm',
        className,
      )}
    >
      {children}
    </pre>
  );
};
