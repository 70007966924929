export type AmplitudeHelpersShouldTrackUserParams = {
  ampliTrackingEnabled: boolean;
  ampliAnonTrackingEnabled?: boolean;
  isLoginScreen?: boolean;
  userIsAnonymous?: boolean;
};

export const shouldOptOutOfAmplitude = (
  args: AmplitudeHelpersShouldTrackUserParams,
) => {
  return !shouldAmplitudeTrackUser(args);
};

export const shouldAmplitudeTrackUser = ({
  ampliTrackingEnabled,
  ampliAnonTrackingEnabled,
  isLoginScreen,
  userIsAnonymous,
}: AmplitudeHelpersShouldTrackUserParams) => {
  if (!ampliTrackingEnabled || isLoginScreen) {
    return false;
  }

  if (userIsAnonymous) {
    return ampliAnonTrackingEnabled;
  } else {
    return true;
  }
};
