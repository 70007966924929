import { gql, useQuery } from '@apollo/client';
import { ThemeProvider as OrbitThemeProvider } from '@apollo/orbit';
import {
  SpaceKitProvider,
  useSpaceKitProvider,
} from '@apollo/space-kit/SpaceKitProvider';
import * as Sentry from '@sentry/react';
import isChromatic from 'chromatic';
import classnames from 'classnames';
import React, { CSSProperties, useEffect } from 'react';

import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { useLDFlag } from 'src/lib/launchDarkly';

import { useIsLoggedIn } from '../../app/graph/hooks/useIsLoggedIn';

export enum ThemeName {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum BrandName {
  ORBIT = 'orbit',
  SPACE_KIT = 'space-kit',
}

type ThemeState = ReturnType<typeof useThemeState>;

const ThemeContext = React.createContext<ThemeState | null>(null);

const themedClasses = [
  'theme-container-new-system-light',
  'theme-container-new-system-dark',
  'theme-container-light',
  'theme-container-dark',
];
const getThemeContainerClass = (themeName: ThemeName, useNewTheme: boolean) => {
  if (useNewTheme) {
    return themeName === 'light'
      ? 'theme-container-new-system-light'
      : 'theme-container-new-system-dark';
  }

  return themeName === 'light'
    ? 'theme-container-light'
    : 'theme-container-dark';
};

export const ThemeProvider = ({
  children,
  className,
  styles,
  overrideThemeName,
  overrideBrandName,
  setGlobalTheme = false,
  __isInternalWIP = false,
}: {
  children: React.ReactNode;
  styles?: CSSProperties;
  overrideThemeName?: ThemeName;
  overrideBrandName?: BrandName;
  className?: string;
  /**
   * TODO(rebrand-rollout)
   * This is a boolean that we pass into our app-level theme wrapper in
   * main.tsx.
   *
   * At some point, if we want to roll theming out across the whole app,
   * we would remove the nested <ThemeProvider> around the Explorer
   * and just have the whole app wrapped in this one provider instead.
   *
   * For now, we use localStorage to remember this preference for internal
   * users who are contributing to the WIP.
   *
   */
  __isInternalWIP?: boolean;
  /**
   * TODO(dark-mode)
   * We need this to set some CSS straight on `body`, at the top most level
   * off the app. It's only used in main.tsx. We will be able to remove this bool
   * once there is only one ThemeProvider for the app and no more nesting. This
   * should be possible when we're ready to roll out dark  mode.
   */
  setGlobalTheme?: boolean;
}) => {
  const themeState = useThemeState(overrideThemeName, __isInternalWIP);
  const themeContainerRef = React.createRef<HTMLDivElement>();

  const orbitFeatureFlagIsOn = useLDFlag('studio-rebrand');
  /**
   * TODO(rebrand-rollout)
   * Remove the local override toggle once Orbit is rolled out in prod & stable
   */
  const [localBrand] = useLocalStorage('temporaryInternalGlobalBrandName');
  const useOrbit =
    localBrand === BrandName.SPACE_KIT
      ? false
      : overrideBrandName === BrandName.ORBIT || orbitFeatureFlagIsOn;

  useEffect(() => {
    if (
      themeState.themeName === 'light' &&
      themeContainerRef.current?.closest('.theme-container-dark')
    ) {
      Sentry.captureException(
        new Error(
          "light mode used in dark mode. tailwind can't handle this nested class case",
        ),
      );
    }
  }, [themeContainerRef, themeState.themeName]);

  useEffect(() => {
    if (setGlobalTheme) {
      // we want to do this globally on the <body> tag to encompass portals
      document.body.classList.remove(...themedClasses);
      document.body.classList.add(
        ...[
          getThemeContainerClass(themeState.themeName, useOrbit),
          // this needs to be here and not just on <body> in index.html
          // so that our storybook stories are treated the same as our app itself
          'text-body',
          'bg-secondary-inverted',
        ],
      );
    }
  }, [setGlobalTheme, themeState, useOrbit]);

  const spaceKitProvider = useSpaceKitProvider();
  /**
   * TODO(rebrand-rollout)
   * When we roll out Orbit globally after our FF has been stable for a little bit,
   * can we remove the SpaceKitProvider wrapper here entirely? What does it still do?
   * Can we merge OrbitThemeProvider with ThemeProvider and just have one?
   */
  return (
    <ThemeContext.Provider value={themeState}>
      <SpaceKitProvider
        {...spaceKitProvider}
        disableAnimations={isChromatic() || spaceKitProvider.disableAnimations}
        theme={themeState.themeName}
      >
        <OrbitThemeProvider useNewTheme={useOrbit}>
          <div
            className={classnames(
              className,
              !setGlobalTheme &&
                // eslint-disable-next-line local/no-conditional-class-names
                getThemeContainerClass(themeState.themeName, useOrbit),
            )}
            // we add colorScheme here so we can get native dark-mode scroll bars
            // from the user's browser when appropriate
            style={{ ...styles, colorScheme: themeState.themeName }}
            ref={themeContainerRef}
          >
            {children}
          </div>
        </OrbitThemeProvider>
      </SpaceKitProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};

const useThemeState = (
  overrideThemeName?: ThemeName,
  __isInternalWIP?: boolean,
) => {
  const { isLoggedIn } = useIsLoggedIn();
  const [temporaryInternalGlobalThemeName] = useLocalStorage(
    'temporaryInternalGlobalThemeName',
  );
  const { data } = useQuery(
    gql<GraphQLTypes.UserThemeQuery, GraphQLTypes.UserThemeQueryVariables>`
      query UserThemeQuery {
        studioSettings {
          id
          themeName
        }
      }
    `,
    { skip: Boolean(overrideThemeName) || __isInternalWIP || !isLoggedIn },
  );

  const [{ themeName: localThemeName }] = useLocalStorage(
    'localExplorerSettings',
  );
  const [hasTouchedDarkModeToggle] = useLocalStorage(
    'hasTouchedDarkModeToggle',
  );
  const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'DARK'
    : 'LIGHT';

  const pickedTheme = isLoggedIn
    ? data?.studioSettings?.themeName
    : localThemeName;
  const explorerSettingThemeName =
    !hasTouchedDarkModeToggle &&
    // This check is to ensure users who have switched from default to dark mode
    // don't have their preferences reset.
    pickedTheme !== 'DARK'
      ? systemTheme
      : pickedTheme;

  const themeName =
    overrideThemeName ??
    (__isInternalWIP
      ? temporaryInternalGlobalThemeName
      : explorerSettingThemeName
      ? ThemeName[explorerSettingThemeName]
      : ThemeName.LIGHT);

  /* when using for classNames, always wrap with tw`` or use `classnames` function  */
  const themedValue = React.useCallback(
    // eslint will format this without the generic value so we need to disable the rule
    // eslint-disable-next-line prefer-arrow-callback
    function fn<T>(dict: Record<ThemeName, T>) {
      return dict[themeName];
    },
    [themeName],
  );

  return React.useMemo(
    () => ({
      themeName,
      /**
       * @deprecated use semantic Tailwind tokens instead
       */
      themedValue,
    }),
    [themeName, themedValue],
  );
};
