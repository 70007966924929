/* eslint-disable func-names */
import type History from 'history';
import pathToRegExp from 'path-to-regexp';
import { ParsedQuery, parse, stringify } from 'query-string';
import { matchPath } from 'react-router-dom';

import Config from '../config';

import { addFaultToleranceToParse } from './addFaultToleranceToParse';
import { locationToPath } from './locationToPath';

/**
 * Generic taking two objects and returning the parameters that are required to
 * complete `To` when spread all `From` values.
 */
type Needed<From, To> = {
  [Key in Exclude<RequiredKeys<To>, RequiredKeys<From>> & {
    [key: string]: never;
  }]: To[Key];
};

export type RouteConfigParams<Config> = Config extends RouteConfig<
  infer MatchParams,
  infer SearchParams,
  infer State,
  infer Hash
>
  ? MatchParams & SearchParams & State & Hash
  : never;

interface LocationOptions {
  /**
   * Return an absolute URL
   *
   * @default `false`
   */
  absolute?: boolean;
}

/**
 * This interface exists solely to allow `RouteConfig` constructor arguments and
 * the class itself to conform to the same interface with the same jsdoc
 * comments.
 *
 * Note that the _types_ of the class parameters can be inferred as of
 * TypeScript 4; but the jsdoc comments will not.
 */
interface RouteConfigConstructorParams<
  MatchParams extends Record<string, unknown>,
  SearchParams extends Record<string, unknown>,
  State,
  Hash,
> {
  /**
   * Definition of the route string. This will be used in `<Route path={...} />`
   */
  definition?: string;

  /**
   * Indicates if we want to merge this child's defintion with the parent's
   *
   * @default true
   */
  mergeDefinition?: boolean;

  /**
   * Function to parse an unknown object of match params into the expected match
   * params format. The type of `MatchParams` will be inferred from the return
   * of this function.
   *
   * @default `() => ({})`
   */
  parseMatchParams?: (
    params: undefined | Record<string, string | undefined>,
  ) => MatchParams;

  /**
   * Function to parse an unknown object of query string params into the
   * expected shape. The type of `SearchParams` will be inferred from the
   * return of this function.
   *
   * @default `() => ({})`
   */
  parseSearchParams?: (queryStringParams: ParsedQuery) => SearchParams;

  /**
   * Function to parse an unknown location state into the expected match shape.
   * The type of `State` will be inferred from the return of this
   * function.
   *
   * @default `() => ({})`
   */
  parseState?: (state: unknown) => State;

  /**
   * Function to parse an string into the expected hash shape.
   * The type of `Hash` will be inferred from the return of this
   * function. RouteConfig instance needs to write their own one of these
   *
   * Since hash strings don't have name/value pairs that can be parsed,
   * consumer of RouteConfig using hash will need to write a custom `parseHash`
   * to parse the hash string to the expected hash params shape -> there is no way
   * to parse a hash string into an object generally
   *
   * @default `() => ({})`
   */
  parseHash?: (hash: string) => Hash;

  /**
   * Accept parameters of type `MatchParams` and convert that into a value to be
   * used in `History.LocationDescriptorObject`'s `pathname`.
   */
  locationPathname?: (
    this: RouteConfig<MatchParams, SearchParams, State, Hash>,
    params: MatchParams,
  ) => History.LocationDescriptorObject<State>['pathname'];

  /**
   * Accept parameters of type `SearchParams` and convert that into a value
   * to be used in `History.LocationDescriptorObject`'s `search`.
   */
  locationSearch?: (
    this: RouteConfig<MatchParams, SearchParams, State, Hash>,
    params: SearchParams,
  ) => History.LocationDescriptorObject<State>['search'];

  /**
   * Accept parameters of type `State` and convert that into a value to be
   * used in `History.LocationDescriptorObject`'s `state`.
   */
  locationState?: (
    this: RouteConfig<MatchParams, SearchParams, State, Hash>,
    params: State,
  ) => History.LocationDescriptorObject<State>['state'];

  /**
   * Accept parameters of type `Hash` and convert that into a value to be
   * used in `History.LocationDescriptorObject`'s `hash`.
   * By default, this function doesn't return anything,
   * you need to pass your own `locationHash` function based on the structure of
   * your hash string to get the string from the params object
   */
  locationHash?: (
    this: RouteConfig<MatchParams, SearchParams, State, Hash>,
    params: Hash,
  ) => string;
}

/**
 * Abstraction to unify definition of and access of routes, intended to
 * encapsulate all the logic for generating routes and encoding/decoding the
 * data stored in the route.
 *
 * Each `RouteConfig` has functions to parse match parameters, search strings,
 * and state. Along with those functions, each `RouteConfig` has functions to
 * translate those parameters into values for a
 * `History.LocationDescriptorObject` (`pathname`, `search`, and `state`). All
 * of the parse functions and location functions have defaults, so none are
 * required.
 */
export class RouteConfig<
  MatchParams extends Record<string, unknown>,
  SearchParams extends Record<string, unknown>,
  State,
  Hash,
> implements
    RouteConfigConstructorParams<MatchParams, SearchParams, State, Hash>
{
  public static DELETE = Symbol('delete');

  public readonly definition;
  public readonly mergeDefinition;

  public readonly locationPathname;
  public readonly locationSearch;
  public readonly locationState;
  public readonly locationHash;

  // These have types because they self-reference in the constructor and,
  // therefore, the types can't be inferred.
  public readonly parseMatchParams: (
    params: undefined | Record<string, string | undefined>,
  ) => MatchParams;
  public readonly parseSearchParams: (
    queryStringParams: ParsedQuery,
  ) => SearchParams;
  public readonly parseState: (state: unknown) => State;
  public readonly parseHash: (hash: string) => Hash;

  protected compiledPathToRegExp: pathToRegExp.PathFunction;

  constructor({
    definition = '',
    mergeDefinition = true,
    parseMatchParams = () => ({} as MatchParams),
    parseSearchParams = () => ({} as SearchParams),
    parseState = () => ({} as State),
    parseHash = () => ({} as Hash),
    locationPathname = function (params) {
      return this.compiledPathToRegExp(params);
    },
    locationSearch = function (params) {
      return stringify(
        this.parseSearchParams(params as ParsedQuery<string>) as never,
      );
    },
    locationState = parseState,
    locationHash = () => '',
  }: RouteConfigConstructorParams<MatchParams, SearchParams, State, Hash>) {
    this.definition = definition;
    this.mergeDefinition = mergeDefinition;
    this.locationPathname = locationPathname;
    this.locationSearch = locationSearch;
    this.locationState = locationState;
    this.locationHash = locationHash;
    this.parseMatchParams = addFaultToleranceToParse(parseMatchParams);
    this.parseSearchParams = addFaultToleranceToParse(parseSearchParams);
    this.parseState = addFaultToleranceToParse(parseState);
    this.compiledPathToRegExp = pathToRegExp.compile(definition);
    this.parseHash = parseHash;
  }

  public location(
    this: RouteConfig<MatchParams, SearchParams, State, Hash>,
    params: MatchParams & SearchParams & State & Hash,
    locationOptions: LocationOptions = { absolute: false },
  ): History.LocationDescriptorObject<State> {
    return {
      pathname:
        (locationOptions.absolute
          ? Config.absoluteUrl.replace(/\/$/, '')
          : '') + this.locationPathname(params),
      search: this.locationSearch(params),
      state: this.locationState(params),
      hash: this.locationHash(params),
    };
  }

  /**
   * Create a new `LocationDescriptorObject` that routes from one route config
   * to the same route config with modified parameters or to another route
   * config, also with patched parameters.
   *
   * Type TypeScript types require you to:
   *
   * 1. Require all params in the route config you're navigating to not
   *    satisfied in route config you're navigating from, and
   * 2. Optionally patch to params or delete non-required params for the route
   *    config you're navigating to (which can be the same route config you're
   *    navigating from, in which case, there are no new required params)
   *
   * This should then be used in a `Link` or to navigate with `history.push` or
   * `history.replace`.
   *
   * This function does not perform any navigation, it generates a new
   * `LocationDescriptorObject` that can be used for navigation.
   *
   * Also, all generics are intended to be inferred; do not pass them.
   */
  public locationFrom<
    FromMatchParams extends Record<string, unknown>,
    FromSearchParams extends Record<string, unknown>,
    FromState,
    FromHash,
  >(
    this: RouteConfig<MatchParams, SearchParams, State, Hash>,
    {
      location,
      patch,
      fromRouteConfig,
    }: {
      location: History.LocationDescriptorObject<unknown>;
      /**
       * Parameters to change. To delete a key/value pair, pass the key with
       * value `RouteConfig.DELETE`.
       *
       * This TypeScript definition will require that all keys exist in
       * `MatchParams & SearchParams & State`. Also, if the key is required,
       * then this will disallow you from setting it to `RouteConfig.DELETE`
       */
      patch?: undefined extends typeof fromRouteConfig
        ? Patch<
            RouteConfigParams<
              RouteConfig<MatchParams, SearchParams, State, Hash>
            >,
            typeof RouteConfig.DELETE
          >
        : Needed<
            RouteConfigParams<typeof fromRouteConfig>,
            RouteConfigParams<
              RouteConfig<MatchParams, SearchParams, State, Hash>
            >
          > &
            Patch<
              RouteConfigParams<
                RouteConfig<MatchParams, SearchParams, State, Hash>
              >,
              typeof RouteConfig.DELETE
            >;

      fromRouteConfig?: RouteConfig<
        FromMatchParams,
        FromSearchParams,
        FromState,
        FromHash
      >;
    },
    locationOptions?: LocationOptions,
  ): History.LocationDescriptorObject<State> {
    const params = (fromRouteConfig || this).parseParams(location);

    // The output must be `RouteConfig` with these params. We need the previous
    // params `&` new params to satisfy the constraints.

    if (patch) {
      Object.entries(patch).forEach(([key, value]) => {
        if (value === RouteConfig.DELETE) {
          delete params[key];
        } else if (typeof value !== 'undefined') {
          // @ts-expect-error TODO: improve types
          params[key] = value;
        }
      });
    }
    return this.location(
      params as MatchParams & SearchParams & State & Hash,
      locationOptions,
    );
  }

  /**
   * Create a string that routes from one route config to the same route config
   * with modified parameters or to another route config, also with patched
   * parameters.
   *
   * Type TypeScript types require you to:
   *
   * 1. Require all params in the route config you're navigating to not
   *    satisfied in route config you're navigating from, and
   * 2. Optionally patch to params or delete non-required params for the route
   *    config you're navigating to (which can be the same route config you're
   *    navigating from, in which case, there are no new required params)
   *
   * This should then be used in a `Link` or to navigate with `history.push` or
   * `history.replace`.
   *
   * This function does not perform any navigation, it generates a string that
   * can be used for navigation.
   *
   * Also, all generics are intended to be inferred; do not pass them.
   */
  public pathFrom<
    FromMatchParams extends Record<string, unknown>,
    FromSearchParams extends Record<string, unknown>,
    FromState,
    FromHash,
  >(
    this: RouteConfig<MatchParams, SearchParams, State, Hash>,
    {
      location,
      patch,
      fromRouteConfig,
    }: {
      location: History.LocationDescriptorObject<unknown>;
      /**
       * Parameters to change. To delete a key/value pair, pass the key with
       * value `RouteConfig.DELETE`.
       *
       * This TypeScript definition will require that all keys exist in
       * `MatchParams & SearchParams & State`. Also, if the key is required,
       * then this will disallow you from setting it to `RouteConfig.DELETE`
       */
      patch?: undefined extends typeof fromRouteConfig
        ? Patch<
            RouteConfigParams<
              RouteConfig<MatchParams, SearchParams, State, Hash>
            >,
            typeof RouteConfig.DELETE
          >
        : Needed<
            RouteConfigParams<typeof fromRouteConfig>,
            RouteConfigParams<
              RouteConfig<MatchParams, SearchParams, State, Hash>
            >
          > &
            Patch<
              RouteConfigParams<
                RouteConfig<MatchParams, SearchParams, State, Hash>
              >,
              typeof RouteConfig.DELETE
            >;

      fromRouteConfig?: RouteConfig<
        FromMatchParams,
        FromSearchParams,
        FromState,
        FromHash
      >;
    },
    locationOptions?: LocationOptions,
  ): string {
    return locationToPath(
      this.locationFrom({ fromRouteConfig, location, patch }, locationOptions),
    );
  }

  public parseParams(location: History.LocationDescriptorObject<unknown>) {
    return {
      ...this.parseMatchParams(
        matchPath(location.pathname || '', { path: this.definition })?.params,
      ),
      ...this.parseSearchParams(parse(location.search || '')),
      ...this.parseState(location.state || {}),
      // location.hash includes the `#`, parse it out before sending to functions
      ...this.parseHash(location.hash ? location.hash.substring(1) : ''),
    };
  }

  /**
   * Function to generate a `string` given route match parameters and query
   * string values.
   *
   * This should _only_ be used when we need a string, and `cy.visit`:
   *
   * ```ts
   * cy.visit(...)
   * ```
   *
   * ```ts
   * cy.visit(fieldsRouteConfig.location({ graphId: 'engine' }))
   *   .url()
   *   .should(endWith(fieldsRouteConfig.path({ graphId: 'engine' })));
   * ```
   *
   * Things like `initialEntries` and `<Link to={...} />` should use `location`
   */
  public path(
    this: RouteConfig<MatchParams, SearchParams, State, Hash>,
    /**
     * Combination of match parameters, query string parameters, and location
     * state
     */
    parameters: MatchParams & SearchParams & State & Hash,
    locationOptions?: LocationOptions,
  ): string {
    return locationToPath(this.location(parameters, locationOptions));
  }

  /**
   * Extend an existing RouteConfig. Accepts a new RouteConfig as it's only
   * argument and will decorate the existing config with the new one.
   *
   * All behavior is additive; nothing can be taken away with an extension. We
   * might want to change this in the future to allow for "migration"
   * extensions.
   */
  public extend<
    ExtendedMatchParams extends Record<string, unknown>,
    ExtendedSearchParams extends Record<string, unknown>,
    ExtendedState,
    ExtendedHash,
  >(
    this: RouteConfig<MatchParams, SearchParams, State, Hash>,
    config: RouteConfig<
      ExtendedMatchParams,
      ExtendedSearchParams,
      ExtendedState,
      ExtendedHash
    >,
  ) {
    const parent: RouteConfig<MatchParams, SearchParams, State, Hash> = this;

    return new RouteConfig<
      ExtendedMatchParams & MatchParams,
      ExtendedSearchParams & SearchParams,
      ExtendedState & State,
      ExtendedHash
    >({
      definition: config.mergeDefinition
        ? `${parent.definition}${config.definition}`
        : config.definition,
      mergeDefinition: config.mergeDefinition,
      // match params are merged
      parseMatchParams: (params) => {
        return {
          ...parent.parseMatchParams(params),
          ...config.parseMatchParams(params),
        };
      },
      // merge parent and extended query string params
      parseSearchParams: (params) => ({
        ...parent.parseSearchParams(params),
        ...config.parseSearchParams(params),
      }),
      // Return the merged child and parent state
      parseState: (params) => ({
        ...parent.parseState(params),
        ...config.parseState(params),
      }),
      // Return the merged child only
      parseHash: (params) => {
        return config.parseHash(params);
      },
      locationPathname(params) {
        return this.mergeDefinition
          ? `${parent.locationPathname(
              parent.parseMatchParams(params as Record<string, string>),
            )}${config.locationPathname(
              config.parseMatchParams(params as Record<string, string>),
            )}`
          : config.locationPathname({
              ...parent.parseMatchParams(params as Record<string, string>),
              ...config.parseMatchParams(params as Record<string, string>),
            });
      },
      locationSearch(params) {
        return stringify({
          ...parse(parent.locationSearch(params) || ''),
          ...parse(config.locationSearch(params) || ''),
        });
      },
      locationState(params) {
        return {
          ...parent.locationState(params),
          ...config.locationState(params),
        } as State & ExtendedState;
      },
      // Return child hash string only
      locationHash(params) {
        return config.locationHash(params);
      },
    });
  }
}
