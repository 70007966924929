import IconMegaphone from '@apollo/icons/default/IconMegaphone.svg';
import {
  Button,
  ButtonGroup,
  ExternalLinkIcon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  Link as OrbitLink,
} from '@apollo/orbit';
import { captureEvent } from '@sentry/react';
import classnames from 'classnames';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { LazyUrlModal } from 'src/components/common/LazyUrlModal';
import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import Config from 'src/lib/config';
import { mergeQueryParams } from 'src/lib/routing';
import { SafeMarkdown } from 'src/lib/safeCompileMarkdown';
import { triggerWorkerUpdateAndReload } from 'src/serviceWorkerSetup/serviceWorkerRegistration';

export const ChangeLogModalLayout = ({
  markdown,
  onRequestClose,
  isOpen,
  newVersionAvailable,
}: {
  markdown: string;
  onRequestClose: () => void;
  isOpen: boolean;
  newVersionAvailable: boolean;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      size="3xl"
      scrollBehavior="inside"
      onClose={onRequestClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalTitle>What's new in Studio</ModalTitle>
          {!!process.env.GIT_HASH && process.env.GIT_HASH !== 'unavailable' ? (
            <ModalDescription>
              You're on version {process.env.GIT_HASH.slice(0, 6)}
            </ModalDescription>
          ) : null}
        </ModalHeader>
        <ModalBody>
          <SafeMarkdown
            markdown={markdown}
            includeDefaultOverrides={true}
            className="flex flex-col gap-4"
          />
        </ModalBody>
        <ModalFooter>
          <OrbitLink
            isExternal
            href="https://github.com/apollographql/apollo-studio-community/blob/main/CHANGELOG.md"
            rel="noopener noreferrer"
            target="_blank"
          >
            View full changelog <ExternalLinkIcon />
          </OrbitLink>

          <ButtonGroup>
            <Button variant="secondary" onClick={onRequestClose}>
              Close
            </Button>
            <Tooltip
              disabled={!newVersionAvailable}
              label="A newer version of Studio is available. Click this button to reload the page."
            >
              <Button
                variant="primary"
                onClick={triggerWorkerUpdateAndReload}
                isDisabled={!newVersionAvailable}
              >
                Update available
              </Button>
            </Tooltip>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const Contraption = ({ onMount }: { onMount: () => void }) => {
  useEffect(() => {
    onMount();
    // we do only want to call this once, on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export const ChangelogModalTrigger = ({
  newVersionAvailable,
  className,
}: {
  newVersionAvailable: boolean;
  className?: string;
}) => {
  const [changesReadHeuristic, setChangesReadHeuristic] = useLocalStorage(
    'changesReadHeuristic',
  );
  const [changelogContents, setChangelogContents] = useState('');
  const [totalChangesHeuristic, setTotalChangesHeuristic] = useState(0);

  useEffect(() => {
    fetch(
      'https://raw.githubusercontent.com/apollographql/apollo-studio-community/main/CHANGELOG.md',
    )
      .then(async (res) => {
        const contents = await res.text();
        setChangelogContents(contents);
        setTotalChangesHeuristic(Array.from(contents.matchAll(/##/g)).length);
      })
      .catch(() => captureEvent(new Error('Failed to fetch changelog')));
  }, []);

  const recentChanges = changelogContents
    .split(/(?=##)/)
    .slice(0, 10)
    .join('');

  return (
    <>
      <LazyUrlModal name={Config.modals.appChangelog}>
        {({ closeModal, isOpen }) => (
          <>
            <Contraption
              onMount={() => setChangesReadHeuristic(totalChangesHeuristic)}
            />
            <ChangeLogModalLayout
              isOpen={isOpen}
              markdown={recentChanges}
              onRequestClose={closeModal}
              newVersionAvailable={newVersionAvailable}
            />
          </>
        )}
      </LazyUrlModal>
      <Tooltip label="What's new in Studio">
        <IconButton
          size="sm"
          variant="hidden"
          className={classnames('relative', className)}
          icon={
            <>
              <IconMegaphone className="size-4" />
              {totalChangesHeuristic > changesReadHeuristic && (
                <div className="absolute right-0 top-0 m-0.5 size-2 rounded bg-icon-info" />
              )}
            </>
          }
          as={Link}
          to={(location: Location) => ({
            search: mergeQueryParams(location.search, {
              [Config.queryParameters.Overlay]: Config.modals.appChangelog,
            }),
          })}
          aria-label="What's new in Studio"
        />
      </Tooltip>
    </>
  );
};
