/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This file is generated, to regenerate it, run npm run generate-feature-flags
 */
export const LDFlagDefaults = {
  'admin-app-enable-query-batching': false,
  'astro-growth-onboarding-v-1-phase-2': true,
  'astro-growth-onboarding-v1': false,
  'astro-growth-unified-onboarding-survey': false,
  'astro-growth-user-research-opt-in': true,
  'astro-new-sso-flow': true,
  'astro-self-serve-enterprise-phase-1': true,
  'astro-self-serve-enterprise-phase-2': false,
  'astro-self-serve-sso': true,
  'astro-self-serve-sso-migration-banner': true,
  'astro-show-billing-insights': true,
  'astro-show-try-demo-graph-button': true,
  'astro-summit-banner': false,
  'astro-use-new-monthly-plan-for-annual-to-monthly-conversions': false,
  'astro-user-password-studio-check': false,
  astro_billingV2QueriesEnabled: true,
  'betelgeuse-allow-amplitude-to-track-anon-users': false,
  'betelgeuse-amplitude-in-embedded': false,
  'betelgeuse-custom-domains': false,
  'betelgeuse-dedicated-ui': false,
  'betelgeuse-demo-supergraph-in-enterprise-trial': true,
  'betelgeuse-enterprise-trial-for-all': true,
  'betelgeuse-redirect-studio-signups': false,
  'betelgeuse-serverless-aws-ui': true,
  'betelgeuse-skip-onboarding-survey': true,
  'betelgeuse-subscriptions-card': false,
  'betelgeuse-unified-onboarding': false,
  'connectors-show-debug-pane': false,
  'edu-summit-banner-studio-ui': false,
  fed_next_enabled: false,
  'federation-next-version': '2.10.0-alpha.1',
  houston_schema_publisher_migration: true,
  'known-operations-ui-preview': true,
  mercury_show_hide_unreachable_types_toggle: true,
  'nebula-language-server-in-proposals': false,
  'nebula-language-server-studio-composition': false,
  'nebula-language-server-studio-connectors': false,
  'nebula-multi-part-subs': true,
  'nebula-multi-part-subs-for-serverless': true,
  'nebula-postflight-scripts': true,
  'nebula-proposals': true,
  'nebula-proposals-be-ga': true,
  'nebula-proposals-check-task': true,
  'nebula-proposals-ga': true,
  'nebula-proposals-linter': true,
  'nebula-proposals-m2': true,
  'nebula-proposals-m3': true,
  'nebula-proposals-readme-within-proposals': true,
  'nebula-proposals-revision-history': true,
  'nebula-reapproval-required-on-change': true,
  'nebula-visualization': true,
  'nebula-visualization-sandbox': false,
  nebula_custom_checks_enabled: false,
  nebula_custom_checks_ui: false,
  nebula_proposals_merge_upstream: false,
  nebula_proposals_webhooks_fe: true,
  offline_license: false,
  'operations-l1-commercial-router-entitlements': true,
  ops_Uplink_UseProxiedAuroraRDSPercent: 100,
  'orion-max-schemapublisher-attempts': 50,
  'orion-show-composition-rules': true,
  orion_fed_alpha: true,
  'pulsar-br-74-checks-ui': true,
  'pulsar-br-74-insights-ui': true,
  'pulsar-disable-stats-for-error-stats': false,
  'pulsar-disable-stats-for-field-executions': false,
  'pulsar-disable-stats-for-field-latencies': false,
  'pulsar-disable-stats-for-field-usage': false,
  'pulsar-disable-stats-for-query-stats': false,
  'pulsar-enable-fed-version-2-7': false,
  'pulsar-hide-legacy-fields-new-org': false,
  'pulsar-log-kafka-partitions': false,
  'pulsar-max-coordinate-usage-partitions-per-report': 32,
  'pulsar-min-coordinate-usage-records-per-partition': 2500,
  'pulsar-omit-realtime-data': false,
  'pulsar-partition-stats-by-graph': true,
  'pulsar-partition-stats-by-graph-spread': 4,
  'pulsar-safe-removal-of-type-from-composite': true,
  'pulsar-send-coordinate-usage': 100,
  'pulsar-send-field-usage-to-coordinate-usage': 0,
  'pulsar-send-field-usage-to-coordinate-usage-stream': 0,
  'pulsar-send-to-coordinate-usage-stream-topic': 0,
  'pulsar-show-downgrade-checks-config': true,
  'pulsar-show-operations-l1-subscriptions-filter': true,
  'pulsar-show-subscription-event-charts-on-olp': true,
  'pulsar-show-subscription-events-in-olp-client-usage-card': true,
  'pulsar-trace-rollup-enabled': true,
  pulsar_displayListAnalyticsLargeSchemas: true,
  pulsar_fed2Onboarding: true,
  pulsar_restrictClientVersions: true,
  'pulsar_send-defer-feature-usage-analytics': true,
  'pulsar_send-router-feature-usage-analytics': true,
  'pulsar_show-new-insights-operations-ui': true,
  pulsar_showFieldLandingPage: true,
  pulsar_skipDowngradeForStaticChecks: true,
  'pulsar_use-hourly-trace-cache': 100,
  'studio-amplitude-tracking-enabled': true,
  'studio-dark-mode': false,
  'studio-rebrand': true,
  support_jsm_ui: false,
};
