import { Button, ButtonProps } from '@apollo/orbit';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Config from 'src/lib/config';
import { mergeQueryParams } from 'src/lib/routing';

export const ContactSupportLink = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  const location = useLocation();
  return (
    <Link
      className={className}
      to={{
        search: mergeQueryParams(location.search, {
          overlay: Config.modals.supportRequest,
        }),
      }}
    >
      {children}
    </Link>
  );
};

export const OrbitContactSupportButton = ({
  className,
  variant,
  children,
  isDisabled,
}: {
  variant: ButtonProps['variant'];
  className?: string;
  children?: React.ReactNode;
  isDisabled?: boolean;
}) => {
  const location = useLocation();
  return (
    <Button
      variant={variant}
      className={className}
      isDisabled={isDisabled}
      as={Link}
      to={{
        search: mergeQueryParams(location.search, {
          overlay: Config.modals.supportRequest,
        }),
      }}
    >
      {children}
    </Button>
  );
};
