import IconSuccessSolid from '@apollo/icons/default/IconSuccessSolid.svg';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@apollo/orbit';
import React from 'react';
import { Link } from 'react-router-dom';

import { raw as OverlayStars } from 'src/assets/overlay-stars.svg';
import { ClickableText } from 'src/components/common/clickableText/ClickableText';
import { PricingRouter } from 'src/lib/routers';

import { enterpriseFeatureList } from './enterpriseFeatureList';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const EnterpriseUpgradeModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody className="flex flex-row">
          <div className="flex w-20 flex-1 flex-col overflow-x-hidden ">
            <h1 className="text-2xl font-semibold">Apollo Enterprise</h1>
            <div className=" flex h-full flex-col justify-between">
              <p className="mt-6 text-base font-normal ">
                Join the teams building the world’s most sophisticated
                supergraphs. The enterprise plan gives you access to all of
                Studio’s enterprise features, our Graph Champions community, and
                a dedicated support channel for your team to talk with Apollo
                supergraph experts.
              </p>
              <div className=" mr-4 w-full rounded-md bg-neutral p-4 text-base text-neutral">
                <p>
                  <b>Customize query volume and support</b>
                </p>
                <p className="w-64 text-sm">
                  We will work with you to customize a query volume limit and
                  support package that’s right.
                </p>
              </div>
            </div>
          </div>
          <div
            className="relative overflow-x-hidden rounded-md bg-black-200 pb-8 pl-4 pr-8"
            style={{
              backgroundImage: `url(${OverlayStars})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <h2 className="mb-2 mt-3 text-lg font-semibold text-white">
              Enterprise Features in Studio
            </h2>
            <ul className="flex flex-col gap-2">
              {enterpriseFeatureList.map((element) => {
                return (
                  <li className="flex items-center" key={element}>
                    <IconSuccessSolid className="mr-2 text-icon-success" />
                    <span className="text-white">{element}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <ClickableText
            as="a"
            href={PricingRouter.path('Home')}
            target="_blank"
            rel="noopener noreferrer"
            className="text-base text-link"
          >
            Learn more about our plans
          </ClickableText>

          <ButtonGroup>
            <Button variant="secondary" type="button" onClick={onClose}>
              Close
            </Button>

            <Button as={Link} to="https://apollographql.com/contact-sales">
              Contact Us
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
