import moment, { Duration, MomentInput } from 'moment';

import { Timestamp } from 'src/lib/graphqlTypes/customScalarTypes';

import { assertUnreachable } from '../assertUnreachable';
import { TimeRangeOption } from '../config/timeRangeOptions';
import { Resolution } from '../graphqlTypes/types';

import { printDateRange } from './printDateRange';

// TODO(tim): we should revisit these typings.
// Seems like we need both a "relative" time range and
// an "absolute time range", and our date APIs should describe
// which (one or both) of these are allowed as params.
export type ISOTimestamp = string;
// Sadly we have both negative numbers and stringified negative numbers
// to contend with here.
export type TimeSecondsAgo = `-${number}` | number;
export interface CustomTimeRange {
  from?: MomentInput | null;
  to?: MomentInput | null;
}

/**
 * RegExp to test a string for a full ISO 8601 Date
 * Does not do any sort of date validation, only checks if the string is according to the ISO 8601 spec.
 *  YYYY-MM-DDThh:mm:ss
 *  YYYY-MM-DDThh:mm:ssTZD
 *  YYYY-MM-DDThh:mm:ss.sTZD
 * @see: https://www.w3.org/TR/NOTE-datetime
 */
const ISO8601RegEx =
  /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

export function isISOTimestamp(
  input: Readonly<Timestamp['output']>,
): input is ISOTimestamp {
  if (typeof input !== 'string') return false;
  return new RegExp(ISO8601RegEx).test(input);
}

export function isTimeSecondsAgo(input: MomentInput): input is TimeSecondsAgo {
  return typeof input === 'string'
    ? input.startsWith('-')
    : typeof input === 'number'
    ? input <= 0
    : false;
}

/**
 * Attempts to coerce the time value into a shape allowed by our GraphQL API's
 * custom scalar "Timestamp" type.  Would be nice if we had something to
 * address https://github.com/apollographql/apollo-feature-requests/issues/2
 * so there was a standard way to do this.
 * @param time can be a Date, ISO-formatted string, or a relative time before
 * now (TimeSecondsAgo), which is a string starting with '-', e.g. '-3600'.
 */
export function coerceToScalarTimestamp(
  time: TimeSecondsAgo | ISOTimestamp | Date,
): Timestamp['output'] {
  if (time instanceof Date) {
    if (isNaN(time.valueOf())) {
      throw new Error('expected a valid Date');
    }
    return time.toISOString();
  }
  if (isTimeSecondsAgo(time) || isISOTimestamp(time)) {
    return time;
  }
  throw new Error('unsupported time value');
}

function resolutionToSeconds(resolution: Resolution): number {
  // We could programatically handle this, but this guarantees that we don't
  // miss any cases. For example, if we add new resolutions (like months), then
  // this will produce typescript errors.
  switch (resolution) {
    case Resolution.R1M:
      return moment.duration(1, 'minutes').asSeconds();
    case Resolution.R5M:
      return moment.duration(5, 'minutes').asSeconds();
    case Resolution.R15M:
      return moment.duration(15, 'minutes').asSeconds();
    case Resolution.R1H:
      return moment.duration(1, 'hours').asSeconds();
    case Resolution.R6H:
      return moment.duration(6, 'hours').asSeconds();
    case Resolution.R1D:
      return moment.duration(1, 'days').asSeconds();
    default:
      return assertUnreachable(resolution);
  }
}

function incrementByResolution(
  from: Timestamp['output'] | Date,
  resolution: Resolution,
): string {
  const secondsToIncrement = resolutionToSeconds(resolution);
  const momentTo = moment(from).add(secondsToIncrement, 'seconds');

  return momentTo.toISOString();
}

function isDateInRange(
  fromDate: MomentInput,
  toDate: MomentInput,
  dateRangeLimit: Duration,
) {
  if (
    moment().subtract(1, 'minute').subtract(dateRangeLimit).isAfter(fromDate)
  ) {
    return false;
  }
  if (moment().subtract(1, 'minute').subtract(dateRangeLimit).isAfter(toDate)) {
    return false;
  }
  return true;
}

/**
 * Returns an ENUM resolution choice for the given range selection, as per this doc:
 * /./date/heatmap-duration-breakpoints.csv
 */
export function findReasonableResolution(diff: number): Resolution {
  if (diff < moment.duration(3, 'hours').asSeconds()) {
    return Resolution.R1M;
  }
  if (diff < moment.duration(12, 'hours').asSeconds()) {
    return Resolution.R5M;
  }
  if (diff < moment.duration(2, 'days').asSeconds()) {
    return Resolution.R15M;
  }
  if (diff < moment.duration(7, 'days').asSeconds()) {
    return Resolution.R1H;
  }
  if (diff < moment.duration(1, 'month').asSeconds()) {
    return Resolution.R6H;
  }
  return Resolution.R1D;
}

/**
 * Returns an pollInterval resolution choice for the given range selection, as per this doc:
 * /./date/heatmap-duration-breakpoints.csv
 */
function findReasonablePollInterval(diff: number) {
  if (diff < moment.duration(3, 'hours').asSeconds()) {
    return moment.duration(15, 'seconds').asMilliseconds(); // showing 1M intervals
  }
  if (diff < moment.duration(12, 'hours').asSeconds()) {
    return moment.duration(30, 'seconds').asMilliseconds(); // showing 5M intervals
  }
  if (diff < moment.duration(2, 'days').asSeconds()) {
    return moment.duration(1, 'minutes').asMilliseconds(); // showing 15M intervals
  }
  if (diff < moment.duration(7, 'days').asSeconds()) {
    return moment.duration(5, 'minutes').asMilliseconds(); // showing 1H intervals
  }
  if (diff < moment.duration(1, 'month').asSeconds()) {
    return moment.duration(30, 'minutes').asMilliseconds(); // showing 6H intervals
  }
  return moment.duration(1, 'hours').asMilliseconds(); // showing 1D intervals
}

/**
 * Check absolute resolution bounds here:
 * ./date/heatmap-duration-breakpoints.csv
 */
export function getRelativeDateBounds(
  range: Exclude<TimeRangeOption, 'custom'>,
  findResolution: (diff: number) => Resolution = findReasonableResolution,
  fastModeRange?: string,
) {
  let timeTo: TimeSecondsAgo = '-0';
  let timeFrom: TimeSecondsAgo, resolution, pollInterval;
  switch (range) {
    case 'lastFiveMinutes':
      timeFrom = `-${moment.duration(5, 'minutes').asSeconds()}` as const;
      resolution = findResolution(moment.duration(5, 'minutes').asSeconds());
      pollInterval = findReasonablePollInterval(
        moment.duration(5, 'minutes').asSeconds(),
      );
      break;
    case 'lastHour':
      timeFrom = `-${moment.duration(1, 'hours').asSeconds()}` as const;
      resolution = findResolution(moment.duration(1, 'hours').asSeconds());
      pollInterval = findReasonablePollInterval(
        moment.duration(1, 'hours').asSeconds(),
      );
      break;
    case 'lastFourHours':
      timeFrom = `-${moment.duration(4, 'hours').asSeconds()}` as const;
      resolution = findResolution(moment.duration(4, 'hours').asSeconds());
      pollInterval = findReasonablePollInterval(
        moment.duration(4, 'hours').asSeconds(),
      );
      break;
    case 'lastDay':
      timeFrom = `-${moment.duration(1, 'days').asSeconds()}` as const;
      resolution = findResolution(moment.duration(1, 'days').asSeconds());
      pollInterval = findReasonablePollInterval(
        moment.duration(1, 'days').asSeconds(),
      );
      break;
    case 'lastThreeDays':
      timeFrom = `-${moment.duration(3, 'days').asSeconds()}` as const;
      resolution = findResolution(moment.duration(3, 'days').asSeconds());
      pollInterval = findReasonablePollInterval(
        moment.duration(3, 'days').asSeconds(),
      );
      break;
    case 'lastWeek':
      timeFrom = `-${moment.duration(1, 'week').asSeconds()}` as const;
      resolution = findResolution(moment.duration(1, 'week').asSeconds());
      pollInterval = findReasonablePollInterval(
        moment.duration(1, 'week').asSeconds(),
      );
      break;
    case 'lastMonth':
      timeFrom = `-${moment.duration(1, 'month').asSeconds()}` as const;
      if (fastModeRange) {
        timeTo = `-${moment.duration(1, 'days').asSeconds() + 1}` as const;
      }
      resolution = findResolution(moment.duration(1, 'month').asSeconds());
      pollInterval = findReasonablePollInterval(
        moment.duration(1, 'month').asSeconds(),
      );
      break;
    case 'lastThreeMonths':
      // Team plans have 90 days worth of data retention rather than 3 calendar
      // months, so we use (90, 'days') instead of (3, 'months')
      timeFrom = `-${moment.duration(90, 'days').asSeconds()}` as const;
      if (fastModeRange) {
        timeTo = `-${moment.duration(1, 'days').asSeconds() + 1}` as const;
      }
      resolution = findResolution(moment.duration(90, 'days').asSeconds());
      pollInterval = findReasonablePollInterval(
        moment.duration(90, 'days').asSeconds(),
      );
      break;
    default:
      assertUnreachable(range);
  }
  return {
    timeFrom,
    timeTo,
    resolution,
    pollInterval,
  };
}

export function getAbsoluteDateBounds(
  range: TimeRangeOption,
  { from, to }: CustomTimeRange = {},
) {
  let timeTo = moment().toDate();
  let timeFrom;
  switch (range) {
    case 'lastFiveMinutes':
      timeFrom = moment(timeTo).subtract(5, 'minutes').toDate();
      break;
    case 'lastHour':
      timeFrom = moment(timeTo).subtract(1, 'hours').toDate();
      break;
    case 'lastFourHours':
      timeFrom = moment(timeTo).subtract(4, 'hours').toDate();
      break;
    case 'lastDay':
      timeFrom = moment(timeTo).subtract(1, 'days').toDate();
      break;
    case 'lastThreeDays':
      timeFrom = moment(timeTo).subtract(3, 'days').toDate();
      break;
    case 'lastWeek':
      timeFrom = moment(timeTo).subtract(1, 'weeks').toDate();
      break;
    case 'lastMonth':
      timeFrom = moment(timeTo).subtract(1, 'months').toDate();
      break;
    case 'lastThreeMonths':
      timeFrom = moment(timeTo).subtract(90, 'days').toDate();
      break;
    case 'custom':
      timeTo = to ? moment(to).toDate() : moment().toDate();
      timeFrom = from ? moment(from).toDate() : moment().toDate();
      break;
    default:
      assertUnreachable(range);
  }
  return { timeFrom, timeTo };
}

export function getTimestampsInRange(
  from: Timestamp['output'] | Date,
  to: Timestamp['output'] | Date,
  resolution: Resolution,
) {
  let nextValue = date.incrementByResolution(from, resolution);
  const emptyTimestamps = [];
  while (moment(nextValue).isBefore(to)) {
    emptyTimestamps.push(nextValue);
    nextValue = date.incrementByResolution(nextValue, resolution);
  }
  return emptyTimestamps;
}

export const date = {
  getMinDifference(from: MomentInput, to: MomentInput) {
    const now = new Date();
    let momentFrom = from;
    let momentTo = to;
    if (isTimeSecondsAgo(from)) {
      momentFrom = moment(now).subtract(
        typeof from === 'number' ? from : parseInt(from, 10),
        'seconds',
      );
    }
    if (isTimeSecondsAgo(to)) {
      momentTo = moment(now).subtract(
        typeof to === 'number' ? to : parseInt(to, 10),
        'seconds',
      );
    }
    return Math.abs(moment(momentFrom).diff(moment(momentTo), 'minutes'));
  },
  validateDateSelection(
    fromDate: MomentInput,
    toDate: MomentInput,
    dateRangeLimit: Duration,
  ) {
    if (!moment(fromDate).isValid())
      throw new Error('FROM is not a valid date');
    if (!moment(toDate).isValid()) throw new Error('TO is not a valid date');
    if (moment().isBefore(fromDate))
      throw new Error('FROM cannot be in the future');
    if (moment().isBefore(toDate))
      throw new Error('TO cannot be in the future');
    if (fromDate && toDate && fromDate > toDate)
      throw new Error('FROM cannot come after TO');
    if (!isDateInRange(fromDate, toDate, dateRangeLimit)) {
      throw new Error(
        `You cannot access data earlier than ${dateRangeLimit.humanize()} ago`,
      );
    }
    return true;
  },
  printRangeDuration(duration: Duration) {
    if (duration.asMilliseconds() < 1000) {
      return 'a few ms';
    }
    const breakdown: Record<string, number> = {
      years: duration.years(),
      months: duration.months(),
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
    const usedUnits = Object.entries(breakdown)
      .filter(([, value]) => Math.abs(value) > 0)
      .map(([key, value]) => {
        const unit = Math.abs(value) === 1 ? key.slice(0, -1) : key;
        return `${Math.abs(value)} ${unit}`;
      })
      .join(', ');
    return usedUnits.length ? usedUnits : duration.humanize(); // fallback to moment
  },
  /**
   * Get the time interval, resolution, and poll interval for the given relative
   * or custom time range.
   * @param range Selected time range option
   * @param param1 Custom time range
   * @param findResolution Function yielding a resolution for the given time
   * range
   * @param fastModeRange fast mode range options are 'lastMonth' or 'lastThreeMonths'.
   * "Fast Mode" omits the last day and 1 second for time range options >= 1 month.
   * Doesn't apply to custom time ranges. We must subtract 1 second to ensure that
   * the data captured always excludes the last day when using a 1 day query granularity/resolution.
   */
  getDateBounds(
    range: TimeRangeOption,
    { from, to }: CustomTimeRange = {},
    findResolution: (diff: number) => Resolution = findReasonableResolution,
    fastModeRange?: string,
  ) {
    if (range !== 'custom')
      return getRelativeDateBounds(range, findResolution, fastModeRange);
    const timeFrom = moment(from || new Date()).toDate();
    const timeTo = moment(to || new Date()).toDate();
    const diff = Math.abs(
      moment.duration(moment(timeFrom).diff(moment(timeTo))).asSeconds(),
    );
    return {
      timeFrom: timeFrom.toISOString(),
      timeTo: timeTo.toISOString(),
      resolution: findResolution(diff),
      pollInterval: findReasonablePollInterval(diff),
    };
  },
  getAbsoluteDateBoundsWithResolution(
    range: TimeRangeOption,
    { from, to }: { from?: MomentInput; to?: MomentInput } = {},
  ) {
    const { timeFrom, timeTo } = getAbsoluteDateBounds(range, {
      from,
      to,
    });
    const diff = Math.abs(
      moment.duration(moment(timeFrom).diff(moment(timeTo))).asSeconds(),
    );
    return {
      timeFrom,
      timeTo,
      resolution: findReasonableResolution(diff),
    };
  },
  resolutionToSeconds,
  incrementByResolution,
  printDateRange,
};
