import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from '@apollo/orbit';
import * as Sentry from '@sentry/react';
import { ParsedQuery, parse } from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';
import { preflightOAuth2RedirectRoute } from 'src/app/shared/routes';
import { Loading } from 'src/components/common/loading/Loading';
import { writeToLocalStorage } from 'src/hooks/useLocalStorage';
import { Config } from 'src/lib/config/config';
import { localStorageWithMemoryFallback } from 'src/lib/localStorageWithMemoryFallback';

import {
  IncomingEmbedMessageEvent,
  PREFLIGHT_OAUTH_PROVIDER_RESPONSE,
  PREFLIGHT_OAUTH_REQUEST,
  PREFLIGHT_OAUTH_RESPONSE,
  sendPostMessageFromEmbedToParent,
} from '../helpers/postMessageHelpers';

const performAuthRequest = (
  fullAuthUrl: string,
  intervalIdRef: React.MutableRefObject<number | null>,
) => {
  const isEmbedded = isEmbeddableExplorerRoute() || isEmbeddableSandboxRoute();
  return new Promise<ParsedQuery<string> | null>((resolve, reject) => {
    function clearInterval() {
      if (intervalIdRef.current) {
        window.clearInterval(intervalIdRef.current);
        // eslint-disable-next-line no-param-reassign
        intervalIdRef.current = null;
      }
    }
    if (isEmbedded) {
      // if this is an embedded site, auth details are communicated via
      // embeddable-explorer (parent page) post message, not shared local storage.
      const handleEmbedPostMessage = (event: IncomingEmbedMessageEvent) => {
        if (event.data.name === PREFLIGHT_OAUTH_RESPONSE) {
          window.removeEventListener('message', handleEmbedPostMessage);
          resolve(parse(event.data.queryParams));
        }
      };
      window.addEventListener('message', handleEmbedPostMessage);
      sendPostMessageFromEmbedToParent({
        name: PREFLIGHT_OAUTH_REQUEST,
        oauthUrl: fullAuthUrl,
      });
    } else {
      const startTime = Date.now();
      window.open(fullAuthUrl, undefined, 'noopener');
      // eslint-disable-next-line no-param-reassign
      intervalIdRef.current = window.setInterval(() => {
        try {
          const localStorageOAuthValue = localStorageWithMemoryFallback.getItem(
            'engine:oauth2RequestQueryParams',
          );
          // we don't use a reactiveVar here via `readFromLocalStorage`, instead
          // we read directly, since we are writing from another window
          const oauth2RequestQueryParams = localStorageOAuthValue
            ? JSON.parse(localStorageOAuthValue)
            : null;
          if (typeof oauth2RequestQueryParams === 'string') {
            const params = parse(oauth2RequestQueryParams);
            localStorageWithMemoryFallback.removeItem(
              'engine:oauth2RequestQueryParams',
            );
            clearInterval();
            resolve(params);
          } else if (Date.now() - startTime > 300000) {
            clearInterval();
            reject();
          } else if (oauth2RequestQueryParams !== null) {
            Sentry.captureException(
              'Unexpected type for oauth2RequestQueryParams',
            );
          }
        } catch (e) {
          reject(e);
        }
      }, 100);
    }
  });
};

export const PreflightScriptOAuthConfirmationModal = ({
  isOpen,
  onComplete,
  authUrl,
  queryParams,
}: {
  isOpen: boolean;
  onComplete: (
    response: null | Record<string, string | string[] | null>,
  ) => void;
  authUrl: string;
  queryParams: Record<string, string>;
}) => {
  const [authorizationLoading, setAuthorizationLoading] = useState(false);
  const intervalIdRef = useRef<number | null>(null);

  const handleModalClose = useCallback(() => {
    if (intervalIdRef.current) window.clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
    setAuthorizationLoading(false);
    onComplete(null);
  }, [onComplete]);

  const handleAuthorizationClick = useCallback(async () => {
    try {
      setAuthorizationLoading(true);
      const fullAuthUrl = new URL(authUrl);
      Object.entries(queryParams).forEach(([key, value]) => {
        fullAuthUrl.searchParams.append(key, value);
      });
      // in OAuth 2.0 redirect_uri is a common query param
      // that specifies where to redirect to with the code in query params
      // after auth is complete. We redirect to a special route that will have
      // the oauth query params. On this route in PreflightOAuthQueryParamFetcher above
      // we grab the query params and write them to local storage temporarily
      // then grab them from there in this window & clear them.

      fullAuthUrl.searchParams.append(
        'redirect_uri',
        new URL(preflightOAuth2RedirectRoute.path({}), Config.absoluteUrl).href,
      );

      const response = await performAuthRequest(
        fullAuthUrl.href,
        intervalIdRef,
      );
      setAuthorizationLoading(false);
      onComplete(response);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error handling oauth request`, e);
      onComplete({ error: 'The auth url was not valid' });
    }
  }, [authUrl, onComplete, queryParams]);

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalTitle>OAuth 2.0 Authorization Required</ModalTitle>
        </ModalHeader>
        <ModalBody>
          Your admin requires OAuth 2.0 Authorization to run this operation.
        </ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            isLoading={authorizationLoading}
            onClick={handleAuthorizationClick}
            type="button"
          >
            Authorize
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

// This component is exposed at preflightOAuth2RedirectRoute
// for the express purpose of fetching its query params, which will have
// oauth 2.0 tokens, and writing them to local storage temporarily.
// Then, in performAuthRequest above, we read them & clear them & return them
export const PreflightScriptOAuthTokenFetcher = () => {
  const location = useLocation();
  const isEmbedded = isEmbeddableExplorerRoute() || isEmbeddableSandboxRoute();

  useEffect(() => {
    const paramsToWrite =
      location.search.length > 1
        ? location.search
        : location.hash.length > 1
        ? location.hash
        : undefined;
    if (paramsToWrite) {
      // In the embeds, the embeddable-explorer repo (parent page)
      // does the exchange between the provider & studio via post message
      // the window.opener here is always the embeddable-explorer repo (parent page)
      if (isEmbedded) {
        window.opener.postMessage(
          {
            name: PREFLIGHT_OAUTH_PROVIDER_RESPONSE,
            queryParams: paramsToWrite,
          },
          // NOTE: This needs to target all origins because we don't know who will be
          // embedding the page
          '*',
        );
      } else {
        writeToLocalStorage('oauth2RequestQueryParams', paramsToWrite, true);
      }
      window.close();
    }
  }, [isEmbedded, location]);

  return <Loading />;
};
