import { Spinner } from '@apollo/orbit';
import React, { ComponentProps } from 'react';
import { Link } from 'react-router-dom';

import * as orgRoutes from 'src/app/account/routes';
import { useGraphVisibility } from 'src/app/graph/hooks/useGraphVisibility';
import { ClickableText } from 'src/components/common/clickableText/ClickableText';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import Config from 'src/lib/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { DocsRouter } from 'src/lib/routers';

import { permissionWrapperFactory } from '../permissionWrapperFactory';

export const PermissionBlock = ({
  isGraphBlock,
  isProtectedVariantBlock,
  accountRole,
  graphRole,
}: {
  isGraphBlock?: boolean;
  isProtectedVariantBlock?: boolean;
  accountRole?: GraphQLTypes.UserPermission | undefined;
  graphRole?: GraphQLTypes.UserPermission | undefined;
}) => {
  const role = isGraphBlock ? graphRole : accountRole;
  const [currentAccountId] = useCurrentAccountId();

  const graphVisibilityType = useGraphVisibility();
  const isPublicRoute = graphVisibilityType === 'public';

  if (role) {
    const roleDisplay = Config.roleNameByRoleEnum[role];
    const roleArticle = roleDisplay.match(/^[aeiou]/i) ? 'an' : 'a';
    return (
      <span>
        As {roleArticle}{' '}
        <ClickableText
          className="font-semibold"
          href={DocsRouter.path('UserRoles')}
          target="_blank"
          rel="noopener noreferrer"
          as="a"
        >
          {roleDisplay}
        </ClickableText>{' '}
        on this {isGraphBlock ? 'graph' : 'organization'}, you do not have
        permission to view this information
        {isProtectedVariantBlock && ' on protected variants'}. Please contact
        one of your{' '}
        {currentAccountId ? (
          <ClickableText
            className="font-semibold"
            as={
              <Link
                to={orgRoutes.members.location({ orgId: currentAccountId })}
              />
            }
          >
            org {isGraphBlock ? 'or graph ' : ''}admins
          </ClickableText>
        ) : (
          'org admins'
        )}
        .
      </span>
    );
  }
  return (
    <span>
      You do not have permission to view this information. Please contact{' '}
      {isPublicRoute ? (
        <>the sender of the graph link.</>
      ) : (
        <>one of your org admins.</>
      )}
    </span>
  );
};

const PermissionGuardWrapper: (
  props:
    | {
        fallback?:
          | React.ReactElement
          | ((props: {
              accountId?: string | undefined;
              isGraphBlock?: boolean | undefined;
              isProtectedVariantBlock?: boolean | undefined;
              accountRole?: GraphQLTypes.UserPermission | undefined;
              graphRole?: GraphQLTypes.UserPermission | undefined;
            }) => React.ReactElement)
          | null
          | undefined;
        loading?: boolean;
      } & ComponentProps<typeof PermissionBlock>,
) => React.ReactElement = (props) => {
  if (props.fallback === null) {
    return <></>;
  }
  if (props.loading) {
    return (
      <div className="flex size-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }
  if (props.fallback === undefined) {
    return (
      <div className="text-base text-secondary">
        <PermissionBlock {...props} />
      </div>
    );
  }
  if (typeof props.fallback === 'function') {
    return <props.fallback {...props} />;
  }

  return props.fallback;
};

export const PermissionGuard = permissionWrapperFactory(PermissionGuardWrapper);
