import classnames from 'classnames';
import React from 'react';

const termsOfServiceUrl =
  'https://www.apollographql.com/Apollo-Terms-of-Service.pdf';

export const TermsOfServiceLink = ({
  className,
  href = termsOfServiceUrl,
}: {
  className?: string;
  href?: string;
}) => (
  <a
    className={classnames('inline text-link underline', className)}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    Terms of Service
  </a>
);
