import { gql, useQuery } from '@apollo/client';
import isChromatic from 'chromatic/isChromatic';
import useLDClient from 'launchdarkly-react-client-sdk/lib/useLDClient';
import withLDProvider from 'launchdarkly-react-client-sdk/lib/withLDProvider';
import React, { useEffect } from 'react';

import { graphRefToString, useGraphRef } from 'src/app/graph/hooks/useGraphRef';
import { login as loginRoute } from 'src/app/onboarding/routes/routes';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { shouldOptOutOfAmplitude } from 'src/lib/analytics/amplitude/helpers';
import Config from 'src/lib/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { isCypressUserEmail } from 'src/lib/isCypressUser';

import { ampli } from '../analytics/amplitude';
import { LD_ME_QUERY_me } from '../graphqlTypes/types';

import { useLDFlag } from './hooks';

export const LD_ME_QUERY = gql<
  GraphQLTypes.LD_ME_QUERY,
  GraphQLTypes.LD_ME_QUERYVariables
>`
  query LD_ME_QUERY {
    me {
      id
      ... on User {
        fullName
        email
      }
    }
  }
`;

const LD_ACCOUNT_QUERY = gql<
  GraphQLTypes.LD_ACCOUNT_QUERY,
  GraphQLTypes.LD_ACCOUNT_QUERYVariables
>`
  query LD_ACCOUNT_QUERY($accountId: ID!) {
    account(id: $accountId) {
      internalID
      id
      createdAt
    }
  }
`;

/**
 * Allows a way to set a custom key to target LD users
 *
 * @returns string | null
 */
const getLDKeyFromQueryParams = (): string | null => {
  const params = new URL(document.location.href).searchParams;
  return params.get('ldKey');
};

export const keyForUser = (me: LD_ME_QUERY_me | null | undefined): string => {
  // allowing custom targeting in prod via query param
  const ldFlagKey = getLDKeyFromQueryParams();
  if (ldFlagKey) {
    return `${ldFlagKey}-${Config.settings.launchDarkly.defaultUserID}`;
  }
  // Should return the cypress key if the user is in a cypress test,
  // or has a cypress email, or in a chromatic test.  🤖's only.
  if (
    isCypressUserEmail(me?.__typename === 'User' ? me?.email : null) ||
    isChromatic()
  )
    return `robot-${Config.settings.launchDarkly.defaultUserID}`;

  // Anonymous human user. Not logged in.
  if (!me?.id) return Config.settings.launchDarkly.defaultUserID;

  // Otherwise we're logged in and not a robot.
  return me.id;
};

// This user has not authed yet, but also has not explicitly opted out of
// cookies/tracking.
const isAnonymousUser = (userStr: string) =>
  userStr.includes(Config.settings.launchDarkly.defaultUserID);

export const LaunchDarklyProvider = withLDProvider<
  JSX.IntrinsicAttributes & { children: React.ReactElement }
>({
  ...Config.settings.launchDarkly,
  user: {
    key: Config.settings.launchDarkly.defaultUserID,
  },
})(({ children }) => {
  const { data: { me } = { me: null } } = useQuery(LD_ME_QUERY);
  const key = keyForUser(me);
  const email = me?.__typename === 'User' ? me.email : null;
  const fullName = me?.__typename === 'User' ? me.fullName : null;
  const LDClient = useLDClient();
  const [orgId] = useCurrentAccountId();
  const graphRef = useGraphRef();
  const { data: { account } = { account: null }, refetch } = useQuery(
    LD_ACCOUNT_QUERY,
    {
      variables: {
        accountId: orgId || '',
      },
      skip: !orgId,
    },
  );

  useEffect(() => {
    if (orgId) {
      refetch({ accountId: orgId });
    }
  }, [orgId, refetch]);

  useEffect(() => {
    if ((orgId && account?.internalID) || !orgId) {
      LDClient?.identify({
        key,
        email: email ?? undefined,
        custom: {
          buildTime: Config.buildTimestamp,
          // Spread values because explicit `undefined` isn't allowed by custom type
          ...(fullName ? { fullName } : {}),
          ...(orgId ? { orgId } : {}),
          ...(graphRef ? { serviceId: graphRef.graphId } : {}),
          ...(graphRef ? { graphRef: graphRefToString(graphRef) } : {}),
          ...(account?.internalID ? { internalOrgId: account.internalID } : {}),
          ...(account?.createdAt
            ? { orgCreatedAt: new Date(account.createdAt).getTime() }
            : {}),
        },
      });
    }
  }, [
    LDClient,
    key,
    email,
    fullName,
    orgId,
    graphRef,
    account?.internalID,
    account?.createdAt,
  ]);

  const isLoginScreen = document.location.pathname.includes(
    loginRoute.definition,
  );

  const ampliTrackingEnabled = useLDFlag('studio-amplitude-tracking-enabled');

  const trackAnonymousUsers = useLDFlag(
    'betelgeuse-allow-amplitude-to-track-anon-users',
  );

  useEffect(() => {
    const shouldOptOut = shouldOptOutOfAmplitude({
      ampliTrackingEnabled,
      userIsAnonymous: isAnonymousUser(key),
      ampliAnonTrackingEnabled: trackAnonymousUsers,
      isLoginScreen,
    });

    ampli.client.setOptOut(shouldOptOut);
  }, [key, ampliTrackingEnabled, trackAnonymousUsers, isLoginScreen]);

  return children;
});
