import React from 'react';

import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';
import { useShowToast } from 'src/components/toast/Toast';
import { useTrackCustomEvent } from 'src/hooks/useTrackCustomEvent';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import { graphRefToString, useGraphRef } from '../../hooks/useGraphRef';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn';

import {
  CollectionEntryIdState,
  SharedCollectionEntryState,
} from './useExplorerState/useEditorTabState/collectionEntryState';
import {
  SavedOperationAction,
  convertRemoteStateToLocalState,
} from './useExplorerState/useEditorTabState/useSavedOperationLocalState';
import {
  headersArrayFromString,
  headersRemoteEntryFromArray,
  useHeadersManagerContext,
} from './useExplorerState/useHeadersManagerContext/useHeadersManagerContext';
import { useLoadSavedOperation } from './useLoadSavedOperation';

export function useHandleOpenSavedOperation(
  dispatchSavedOperationLocalStateAction: (
    action: SavedOperationAction,
  ) => void,
) {
  const { showToasts } = useShowToast();

  const loadSavedOperation = useLoadSavedOperation();
  const { sharedHeaderDefinitions } = useHeadersManagerContext();

  const graphRef = useGraphRef();
  const trackCustomEvent = useTrackCustomEvent();

  const { isLoggedIn } = useIsLoggedIn();
  const handleOpenSavedOperation = React.useCallback(
    async ({
      collectionId,
      collectionEntryId,
      sharedCollectionOriginVariantRef,
    }: CollectionEntryIdState &
      Pick<
        SharedCollectionEntryState,
        'sharedCollectionOriginVariantRef'
      >): Promise<{
      success: boolean;
    }> => {
      const result = await loadSavedOperation({
        collectionId,
        collectionEntryId,
        sharedCollectionOriginVariantRef,
      });
      const operationCollection = result.data?.operationCollection;
      if (
        (isEmbeddableExplorerRoute() || isEmbeddableSandboxRoute()) &&
        !(
          operationCollection?.__typename === 'OperationCollection' &&
          operationCollection.operation?.__typename ===
            'OperationCollectionEntry'
        )
      ) {
        showToasts({
          level: 'warn',
          heading: isLoggedIn
            ? 'Failed to open saved operation'
            : 'Log in to access saved operations',
          message: isLoggedIn
            ? ''
            : `This ${
                isEmbeddableExplorerRoute() ? 'Explorer' : 'Sandbox'
              } has been configured to embed an operation${
                isLoggedIn
                  ? ', but you need to log in to see it.'
                  : " which you don't have access to, please contact your graph admin."
              }`,
        });
        return { success: false };
      }
      if (operationCollection?.__typename !== 'OperationCollection') {
        showToasts({
          heading: 'Failed to open saved operation',
          message: `Could not find operation collection${
            operationCollection ? `: ${operationCollection.message}` : ''
          }`,
          level: 'error',
        });
        return { success: false };
      }
      const operationEntry = operationCollection.operation;

      if (operationEntry?.__typename !== 'OperationCollectionEntry') {
        showToasts({
          heading: 'Failed to open saved operation',
          message: `Could not find operation${
            operationEntry ? `: ${operationEntry.message}` : ''
          }`,
          level: 'error',
        });
        return { success: false };
      }

      // If we are opening an operation from a collection
      // on a different source variant,
      // inject the source variant's shared headers in the tab.
      const additionalSharedHeadersToInject = operationCollection.isShared
        ? headersArrayFromString(
            // if we passed in an origin variant, this will be populated
            result.data?.variant?.__typename === 'GraphVariant'
              ? result.data.variant.sharedHeaders ?? ''
              : // if we didn't, it might be because we didn't have one.
              // we can verify that the shared collection is on a different origin variant
              result.data?.operationCollection.__typename ===
                'OperationCollection'
              ? !result.data.operationCollection.variants.some(
                  (variant) => variant.id === graphRefToString(graphRef),
                )
                ? result.data.operationCollection.variants[0]?.sharedHeaders ??
                  ''
                : ''
              : '',
          ).filter(
            (originSharedHeader) =>
              !sharedHeaderDefinitions.some(
                (currentVariantSharedHeader) =>
                  currentVariantSharedHeader.headerName ===
                    originSharedHeader.headerName &&
                  currentVariantSharedHeader.value === originSharedHeader.value,
              ),
          )
        : [];

      const currentOperationRevision: GraphQLTypes.LoadSavedOperationOperationFragment_operation_OperationCollectionEntry_currentOperationRevision =
        {
          ...operationEntry.currentOperationRevision,
          headers: [
            ...(operationEntry.currentOperationRevision.headers ?? []),
            ...headersRemoteEntryFromArray(additionalSharedHeadersToInject),
          ],
        };

      dispatchSavedOperationLocalStateAction({
        type: 'OpenSavedOperation',
        collectionId,
        collectionEntryId,
        sharedCollectionOriginVariantRef,
        sharedCollectionOriginVariantHeaders: additionalSharedHeadersToInject,
        savedOperationState: convertRemoteStateToLocalState(
          currentOperationRevision,
        ),
        isShared: operationCollection.isShared,
      });

      trackCustomEvent({
        action: 'open_saved_operation',
        category: 'Operation Collections',
      });
      return { success: true };
    },
    [
      dispatchSavedOperationLocalStateAction,
      graphRef,
      isLoggedIn,
      loadSavedOperation,
      sharedHeaderDefinitions,
      showToasts,
      trackCustomEvent,
    ],
  );

  return { handleOpenSavedOperation };
}
