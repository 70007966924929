import IconGitHubSolid from '@apollo/icons/default/IconGitHubSolid.svg';
import { Button } from '@apollo/orbit';
import React from 'react';

import { OnboardingUserSegmentType } from 'src/app/onboarding/routes/routes';
import { useHandleProviderSubmit } from 'src/hooks/useHandleProviderSubmit';
import { ampli } from 'src/lib/analytics/amplitude';
import { trackExternalLink } from 'src/lib/analytics/trackExternalLink';

interface Props {
  className?: string;
  disabled?: boolean;
  loggingIn: boolean;
  setLoggingIn: React.Dispatch<React.SetStateAction<boolean>>;
  callbackUrl: string;
  type: OnboardingUserSegmentType;
}

export const GithubSignupCTA = ({
  className,
  disabled,
  loggingIn,
  setLoggingIn,
  callbackUrl,
  type,
}: Props) => {
  const handleProviderSubmitGithub = useHandleProviderSubmit({
    provider: 'github',
    // GitHub sign-ins should always redirect to the welcome page, so we don't
    // lose the referrer `type` in the query param.
    callbackUrl,
    setLoggingIn,
    type,
  });

  return (
    <Button
      className={className}
      type="button"
      size="lg"
      isDisabled={disabled}
      isLoading={loggingIn}
      onClick={() => {
        trackExternalLink(() => {
          ampli.registrationAuthMethodSelected({
            RegistrationVariant: 'studio-direct',
            AuthorizationMethod: 'github',
          });
        });
        handleProviderSubmitGithub();
      }}
      loadingText="Signing up"
      leftIcon={<IconGitHubSolid />}
      variant="primary"
    >
      Sign up with GitHub
    </Button>
  );
};
