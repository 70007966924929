import IconCopy from '@apollo/icons/default/IconCopy.svg';
import { Button, Code, ExternalLinkIcon, Link } from '@apollo/orbit';
import copy from 'copy-to-clipboard';
import React from 'react';

import Telescope from 'src/assets/telescope.svg';
import { Config } from 'src/lib/config/config';
import {
  BuildGraphQLSchemaError,
  BuildGraphQLSchemaErrorPrompt,
} from 'src/lib/errors/buildSchemaError/BuildSchemaError';
import { useNewWorkerVersionAvailable } from 'src/serviceWorkerSetup/serviceWorkerReactiveVariables';
import { triggerWorkerUpdateAndReload } from 'src/serviceWorkerSetup/serviceWorkerRegistration';

import { ClickableText } from '../common/clickableText/ClickableText';
import { Tooltip } from '../common/tooltip/Tooltip';
import { FixedScrollingContentLayout } from '../fixedScrollingContentLayout/FixedScrollingContentLayout';
import { ContactSupportLink } from '../support-request/contactSupportLink/ContactSupportLink';

import { useIncidentStatus } from './useIncidentStatus';

/**
 * Error component that takes up the whole viewports, presumably because we
 * couldn't fetch anything or we had a top-level error.
 *
 * Renders `children` as the content
 */
export const ErrorPage = ({
  children,
  error,
  errorId,
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  error?: Error;
  errorId?: string | null;
}) => {
  const incidentStatus = useIncidentStatus();
  const newWorkerVersionAvailable = useNewWorkerVersionAvailable();
  return (
    <div className="size-full bg-primary" data-testid="error-page">
      <FixedScrollingContentLayout>
        <div className="text-center">
          <Telescope className="mb-6" />
        </div>
        {error instanceof BuildGraphQLSchemaError ? (
          <BuildGraphQLSchemaErrorPrompt error={error} />
        ) : (
          <div className="flex flex-col items-center gap-3 text-center text-primary">
            <h2 className="font-medium text-heading">
              We've run into an unexpected error
            </h2>
            {children || (
              <p className="font-body">
                Please refresh the page and{' '}
                <ClickableText
                  className="font-semibold underline"
                  as={<ContactSupportLink />}
                >
                  contact support
                </ClickableText>{' '}
                if the error persists.
                {errorId && Config.sentryEnabled && (
                  <>
                    {' '}
                    You can include this error ID to help us diagnose the issue
                    more quickly:
                    <Tooltip label="Copy" clickedLabel="Copied">
                      <Button
                        className="mt-3 size-fit p-0 font-mono"
                        onClick={() => copy(errorId)}
                        variant="hidden"
                      >
                        <Code className="bg-secondary">
                          {errorId}
                          <IconCopy className="ml-1 size-3 text-icon-secondary" />
                        </Code>
                      </Button>
                    </Tooltip>
                  </>
                )}
              </p>
            )}
            <>
              {incidentStatus ? (
                <Link
                  className="mt-4 font-semibold underline"
                  href="https://status.apollographql.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Current status: {incidentStatus} <ExternalLinkIcon />
                </Link>
              ) : newWorkerVersionAvailable ? (
                <Button className="mt-4" onClick={triggerWorkerUpdateAndReload}>
                  Refresh all tabs
                </Button>
              ) : (
                <Button
                  className="mx-auto mt-4 flex"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh
                </Button>
              )}
            </>
          </div>
        )}
      </FixedScrollingContentLayout>
    </div>
  );
};
