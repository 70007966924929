import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { customColors } from './customColors';
import { themeOverrides } from './themeOverrides';

export const theme = extendTheme(themeOverrides);

export const ThemeProvider = ({
  useNewTheme = false,
  children,
}: {
  useNewTheme?: boolean;
  children: React.ReactElement;
}) => {
  const conditionalTheme = useMemo(
    () => ({
      ...theme,
      fontWeights: {
        ...theme.fontWeights,
        medium: useNewTheme ? 500 : 600,
      },
      fonts: useNewTheme
        ? {
            heading: 'Aeonik, sans-serif',
            body: 'Inter, sans-serif',
            mono: '"Fira Code", monospace',
          }
        : {
            heading:
              '"Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif',
            body: '"Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif',
            mono: '"Source Code Pro", "Andale Mono WT", "Andale Mono", Menlo, "Lucida Console", "Lucida Sans Typewriter", Monaco, monospace',
          },
      components: {
        ...theme.components,
        Button: {
          ...theme.components.Button,
          variants: {
            ...theme.components.Button.variants,
            buttonLink: useNewTheme
              ? {
                  ...theme.components.Button.variants.buttonLink,
                  textUnderlineOffset: 4,
                }
              : theme.components.Button.variants.buttonLink,
          },
        },
        Link: {
          ...theme.components.Link,
          baseStyle: useNewTheme
            ? {
                ...theme.components.Link.baseStyle,
                fontWeight: 'semibold',
                textDecoration: 'underline',
                textUnderlineOffset: 4,
              }
            : {
                ...theme.components.Link.baseStyle,
                color: customColors.text.link,
              },
        },
        Badge: {
          ...theme.components.Badge,
          baseStyle: {
            ...theme.components.Badge.baseStyle,
            fontWeight: useNewTheme ? 'bold' : 'semibold',
          },
        },
      },
    }),
    [useNewTheme],
  );
  return (
    <ChakraProvider resetCSS={false} theme={conditionalTheme}>
      {children}
    </ChakraProvider>
  );
};
