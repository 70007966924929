import { useCallback } from 'react';

import {
  PerTab,
  perTabInitialValues,
  useLocalStorage,
} from 'src/hooks/useLocalStorage';

import {
  GraphIdentifier,
  usePerGraphIdentifierLocalStorage,
} from './usePerGraphIdentifierLocalStorage';

type UpdateFunction<Value> = (
  newValueOrUpdate: Value | ((currentValue: Value) => Value),
) => void;

export const usePerTabLocalStorage = <
  Key extends keyof typeof perTabInitialValues,
  GraphIdentifierOrNull extends GraphIdentifier | null,
>({
  key,
  graphIdentifier,
  stableInitialValue,
}: {
  key: Key;
  graphIdentifier: GraphIdentifierOrNull;
  stableInitialValue: typeof perTabInitialValues[Key] extends PerTab<infer V>
    ? V
    : never;
}): [
  typeof stableInitialValue,
  (
    | UpdateFunction<typeof stableInitialValue>
    | (GraphIdentifierOrNull extends null ? null : never)
  ),
  (
    | ((
        tabId: string,
        arg: Parameters<UpdateFunction<typeof stableInitialValue>>[0],
      ) => void)
    | (GraphIdentifierOrNull extends null ? null : never)
  ),
] => {
  type Value = typeof stableInitialValue;
  const [values, setValues] = useLocalStorage(key);

  const [selectedTab] = usePerGraphIdentifierLocalStorage({
    key: 'selectedTab',
    graphIdentifier,
    stableInitialValue: undefined,
  });

  const setValueForTabId = useCallback(
    (tabId: string | undefined, nextValue: React.SetStateAction<Value>) => {
      if (!graphIdentifier || !tabId) {
        return;
      }
      setValues((current) => {
        return {
          ...current,
          [graphIdentifier.domain]: {
            ...current[graphIdentifier.domain],
            [graphIdentifier.id]: {
              ...current[graphIdentifier.domain]?.[graphIdentifier.id],
              [tabId]:
                nextValue instanceof Function
                  ? nextValue(
                      (current[graphIdentifier.domain]?.[graphIdentifier.id]?.[
                        tabId
                      ] ?? stableInitialValue) as Value,
                    )
                  : nextValue,
            },
          },
        };
      });
    },
    [graphIdentifier, stableInitialValue, setValues],
  );

  const setValue = useCallback(
    (nextValue: React.SetStateAction<Value>) =>
      setValueForTabId(selectedTab, nextValue),
    [setValueForTabId, selectedTab],
  );

  if (!graphIdentifier || !selectedTab) {
    return [stableInitialValue, setValue, setValueForTabId];
  }

  return [
    (values[graphIdentifier.domain]?.[graphIdentifier.id]?.[selectedTab] ??
      stableInitialValue) as Value,
    setValue,
    setValueForTabId,
  ];
};
