import { gql, useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@apollo/orbit';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ClickableText } from 'src/components/common/clickableText/ClickableText';
import { Form } from 'src/components/common/form/Form';
import { appLinkContext } from 'src/lib/apollo/link';
import { VALID_EMAIL } from 'src/lib/email';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { UserDoesNotExistRegex } from 'src/lib/graphqlTypes/serverErrors';

import * as routes from '../../routes/routes';

const resetPasswordMutation = gql`
  mutation UI__ResetPasswordMutation($email: String!) {
    resetPassword(email: $email)
  }
`;

type EmailError =
  | 'Error sending reset email.'
  | 'Please provide an email address.'
  | 'Please provide a valid email address.'
  | "We don't know that email address. Want to create an account";

interface ValidationErrors {
  email?: EmailError;
}

interface State {
  email: string;
  sentEmail: boolean;
  errors: ValidationErrors;
}

interface Props {
  initialState?: Partial<State>;
}

export const ForgotPasswordPage = ({ initialState }: Props) => {
  const state = {
    email: '',
    sentEmail: false,
    errors: {},
    ...(initialState || {}),
  };

  const [email, setEmail] = useState<string>(state.email);
  const [sentEmail, setSentEmail] = useState<boolean>(state.sentEmail);
  const [errors, setErrors] = useState<ValidationErrors>(state.errors);

  const [resetPassword, { loading: sendingEmail }] = useMutation<
    GraphQLTypes.UI__ResetPasswordMutation,
    GraphQLTypes.UI__ResetPasswordMutationVariables
  >(resetPasswordMutation, {
    variables: { email },
    onError: (error) => {
      const errorMessages: readonly Error[] = error.graphQLErrors
        ? error.graphQLErrors
        : [error];
      const userDoesNotExist = errorMessages.some((errorMessage) =>
        UserDoesNotExistRegex.test(errorMessage.message),
      );

      if (userDoesNotExist) {
        setErrors({
          email: "We don't know that email address. Want to create an account",
        });
      } else {
        setErrors({ email: 'Error sending reset email.' });
      }
    },
    context: appLinkContext({
      ignoreSentryReporting: [
        (graphqlError) => UserDoesNotExistRegex.test(graphqlError.message),
      ],
    }),
  });

  const handleSubmitReset = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setErrors({ email: undefined });

    if (sendingEmail) {
      return;
    }

    if (!email) {
      setErrors({
        email: 'Please provide an email address.',
      });
    } else if (!VALID_EMAIL.test(email)) {
      setErrors({
        email: 'Please provide a valid email address.',
      });
    } else {
      const result = await resetPassword();
      if (result.data) {
        setSentEmail(true);
      }
    }
  };

  if (sentEmail) {
    return (
      <>
        <div className="mb-4 text-3xl font-semibold">Forgot Password</div>
        <p className="mb-2 text-base">
          We’ve sent an email to <b>{email}</b>, so please check your inbox for
          password reset instructions.
        </p>
        <p className="text-base">
          P.S., you might need to check your spam folder. Accidentally enter the
          wrong email address? No sweat,{' '}
          <button
            type="button"
            className="text-indigo-dark hover:underline"
            onClick={() => setSentEmail(false)}
          >
            try again
          </button>
          .
        </p>
      </>
    );
  }

  return (
    <>
      <div className="mb-4 text-3xl font-semibold">Forgot Password</div>
      <p className="mb-8 text-base">
        Enter your email address below, then check your email for more
        instructions on how to reset your password.
      </p>
      <Form onSubmit={handleSubmitReset} noValidate={true}>
        <div className="mb-6">
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Email Address</FormLabel>
            <Input
              name="email"
              type="email"
              size="lg"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setErrors({});
              }}
            />
            <FormErrorMessage>
              {
                // We want to render one of our error messages with a link
                errors.email ===
                "We don't know that email address. Want to create an account" ? (
                  <>
                    We don't know that email address. Want to{' '}
                    <ClickableText
                      className="text-indigo-dark"
                      as={<Link to="/signup" />}
                    >
                      create an account
                    </ClickableText>
                    ?
                  </>
                ) : (
                  errors.email
                )
              }
            </FormErrorMessage>
          </FormControl>
        </div>
        <Button
          type="submit"
          size="lg"
          isLoading={sendingEmail}
          className="w-full"
          variant="primary"
        >
          Send the instructions
        </Button>
        <div className="mt-12">
          <div className="text-base font-semibold text-primary">
            Did you just remember your password?
          </div>
          <div className="text-sm">
            Or maybe you ended up here by accident. No worries, go{' '}
            <ClickableText
              className="text-link underline"
              as={<Link to={routes.login.location({})} />}
            >
              sign in
            </ClickableText>
            .
          </div>
        </div>
      </Form>
    </>
  );
};
