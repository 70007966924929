import { gql, useLazyQuery } from '@apollo/client';
import React from 'react';

import { GraphQLTypes } from 'src/lib/graphqlTypes';

import { graphRefToString } from '../../hooks/useGraphRef';

import {
  CollectionEntryIdState,
  SharedCollectionEntryState,
} from './useExplorerState/useEditorTabState/collectionEntryState';

export const LoadSavedOperationOperationFragment = gql`
  fragment LoadSavedOperationOperationFragment on OperationCollection {
    id
    operation(id: $collectionEntryId) {
      ... on OperationCollectionEntry {
        id
        currentOperationRevision {
          body
          variables
          headers {
            name
            value
          }
          script
          postflightOperationScript
        }
      }
      ... on Error {
        message
      }
    }
  }
`;
export const useLoadSavedOperation = () => {
  const [loadSavedOperation] = useLazyQuery<
    GraphQLTypes.LoadSavedOperationQuery,
    GraphQLTypes.LoadSavedOperationQueryVariables
  >(
    gql`
      query LoadSavedOperationQuery(
        $collectionId: ID!
        $collectionEntryId: ID!
        $variantRef: ID!
      ) {
        variant(ref: $variantRef) {
          ... on GraphVariant {
            id
            sharedHeaders
          }
        }
        operationCollection(id: $collectionId) {
          ... on OperationCollection {
            id
            isShared
            # we fetch both the sharedHeaders on a passed variant
            # as well as all sharedHeaders, because sometimes in
            # the app flow we know which variant this collection
            # came from, and sometimes we don't
            variants {
              id
              sharedHeaders
            }
            ...LoadSavedOperationOperationFragment
          }
          ... on Error {
            message
          }
        }
      }
      ${LoadSavedOperationOperationFragment}
    `,
  );

  return React.useCallback(
    async ({
      collectionId,
      collectionEntryId,
      sharedCollectionOriginVariantRef,
    }: CollectionEntryIdState &
      Pick<SharedCollectionEntryState, 'sharedCollectionOriginVariantRef'>) => {
      const result = await loadSavedOperation({
        variables: {
          collectionId,
          collectionEntryId,
          variantRef: sharedCollectionOriginVariantRef
            ? graphRefToString(sharedCollectionOriginVariantRef)
            : '', // its okay to send an empty string here, we don't care if it fails, we only want the shared headers if the variant is passed
        },
      });

      return result;
    },
    [loadSavedOperation],
  );
};
