import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import {
  BillingPlanKind,
  BillingPlanTier,
  UseCurrentPlanQuery,
  UseCurrentPlanQueryVariables,
} from 'src/lib/graphqlTypes/types';

interface Args extends UseCurrentPlanQueryVariables {
  skip?: boolean;
}

export const isAccountTypeCloudy = (
  billingPlanKind: BillingPlanKind | undefined,
) => {
  if (billingPlanKind) {
    return [
      'DEDICATED',
      'SERVERLESS',
      'SERVERLESS_FREE',
      'SERVERLESS_PAID',
    ].includes(billingPlanKind.toString());
  } else {
    return false;
  }
};

export function useCurrentPlan({ skip = false, ...variables }: Args) {
  // TODO: I don't think we are using many of these booleans now due to
  // the switch to allCapabilities so we could probably clean them up later
  const currentPlanResult = useQuery<
    UseCurrentPlanQuery,
    UseCurrentPlanQueryVariables
  >(
    gql<UseCurrentPlanQuery, UseCurrentPlanQueryVariables>`
      query UseCurrentPlanQuery($accountId: ID!) {
        account(id: $accountId) {
          id
          name
          isOnExpiredTrial
          isOnTrial
          isLocked
          currentPlan {
            id
            billingModel
            billingPeriod
            clients
            clientVersions
            kind
            contracts
            datadog
            isTrial
            launches
            maxAuditInDays
            maxRangeInDays
            maxRequestsPerMonth
            maxRangeInDaysForChecks
            metrics
            name
            notifications
            persistedQueries
            ranges
            tier
            traces
            userRoles
            webhooks
            allCapabilities {
              label
              value
            }
          }
        }
      }
    `,
    {
      variables,
      skip,
      context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    },
  );

  return useMemo(() => {
    const currentPlan = currentPlanResult.data?.account?.currentPlan;
    const tier = currentPlan?.tier;

    return {
      currentPlan,
      isOnFreeTier: tier === BillingPlanTier.COMMUNITY,
      isOnExpiredTrial:
        currentPlanResult.data?.account?.isOnExpiredTrial ?? false,
      isOnTrial: currentPlanResult.data?.account?.isOnTrial ?? false,
      isCloudyAccountType: isAccountTypeCloudy(currentPlan?.kind),
      ...currentPlanResult,
    };
  }, [currentPlanResult]);
}
