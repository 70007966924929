import { ApolloError } from '@apollo/client';
import { Badge } from '@apollo/orbit';
import { utmGrabber } from '@apollo/utm-grabber';
import { uniqueId } from 'lodash';
import { stringify } from 'query-string';
import React, { useEffect } from 'react';
import {
  Link,
  Redirect,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Navigate } from 'react-router-dom-v5-compat';

import { OrgSwitcher } from 'src/components/appNav/orgSwitcher/OrgSwitcher';
import { ErrorBoundary } from 'src/components/common/errorBoundary/ErrorBoundary';
import { Loading } from 'src/components/common/loading/Loading';
import { NotFound } from 'src/components/common/notFound/NotFound';
import { NoBillingEmailBanner } from 'src/components/noBillingEmailBanner/NoBillingEmailBanner';
import { NoVerifiedEmailBanner } from 'src/components/noVerifiedEmailBanner/NoVerifiedEmailBanner';
import { PermissionGuard } from 'src/components/permissionGuards/permissionGuard/PermissionGuard';
import { PublicVariantPreviewBanner } from 'src/components/publicVariantPreviewBanner/PublicVariantPreviewBanner';
import { SsoMigrationBanner } from 'src/components/ssoMigrationBanner/SsoMigrationBanner';
import { Toasts, useShowToast } from 'src/components/toast/Toast';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { useCanAccessProposals } from 'src/hooks/useCanAccessProposals';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { useDefaultVariant } from 'src/hooks/useDefaultVariant';
import {
  chooseDefaultAccountId,
  isUserIdentity,
  useIdentity,
} from 'src/hooks/useIdentity';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { SentryRoute as Route } from 'src/hooks/useTelemetryInitializer';
import Config from 'src/lib/config';
import { useLDFlag } from 'src/lib/launchDarkly';
import { catchAllRouteConfig } from 'src/lib/routeConfig/catchAllRoute';
import { DocsRouter } from 'src/lib/routers';
import { mergeQueryParams } from 'src/lib/routing';

import { AccountInvitationHandler } from '../account/accountInvitationHandler/AccountInvitationHandler';
import { graphOnboardingRoutes } from '../account/graphOnboarding/routes';
import { userSettingsRouteConfig } from '../account/routes';
import { AppModals } from '../appModals/AppModals';
import { useIsLoggedIn } from '../graph/hooks/useIsLoggedIn';
import { RedirectLegacyGraphUpgrade } from '../graph/redirectLegacyGraphUpgrade/RedirectLegacyGraphUpgrade';
import {
  atlasExplorerRouteConfig,
  graphProposalListRouteConfig,
  graphRouteConfig,
  legacyGraphRouteConfig,
  persistedQueriesRouteConfig,
  proposalRouteConfig,
  sandboxExplorerRouteConfig,
  sandboxGraphRouteConfig,
  upgradeRouteConfig,
  variantRouteConfig,
} from '../graph/routes';
import { upgradeRoute } from '../onboarding/components';
import * as routes from '../onboarding/routes';
import { UnifiedOnboardingSurveyModal } from '../onboarding/views/unifiedOnboardingSurvey/UnifiedOnboardingSurvey';

import { GlobalHeader } from './components/globalHeader/GlobalHeader';
import { OrgProvisioner } from './components/orgProvisioner/OrgProvisioner';
import { ScheduledMaintenances } from './components/scheduledMaintenances/ScheduledMaintenances';
import { TrialAlertBanner } from './components/trialAlertBanner/TrialAlertBanner';
import { UsageLimitAlerts } from './components/usageLimitAlerts/UsageLimitAlerts';

const AccountLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "AccountLayout" */ '../account/accountLayout/AccountLayout'
    )
  ).AccountLayout,
}));
const SettingsPageLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "SettingsPageLayout" */ '../user/settingsPage/SettingsPageLayout'
    )
  ).SettingsPageLayout,
}));
const AtlasLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "AtlasLayout" */ '../atlas/AtlasLayout'
    )
  ).AtlasLayout,
}));
const VariantLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "VariantLayout" */ '../graph/variantLayout/VariantLayout'
    )
  ).VariantLayout,
}));
const ProposalLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "ProposalLayout" */ '../graph/proposalLayout/ProposalLayout'
    )
  ).ProposalLayout,
}));
const GraphLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "GraphLayout" */ '../graph/graphLayout/GraphLayout'
    )
  ).GraphLayout,
}));
/**
 *
 * Returns a Redirect from any graph route with ?variant or without a variant in the
 * query param to a variant route config with that variant or a default
 */
const RedirectFromGraphPathToVariantPath = () => {
  const { defaultVariant, loadingDefaultVariant } = useDefaultVariant();
  const {
    graphVisibilityType,
    graphId,
    variant: legacyQueryParamVariant,
  } = useRouteParams(legacyGraphRouteConfig, catchAllRouteConfig);
  const location = useLocation();
  const { variant: _, ...other } = useQueryParams();

  const { showToasts, hideToasts } = useShowToast();
  const stableToastId = React.useMemo(() => uniqueId(), []);

  useEffect(() => {
    if (
      !legacyQueryParamVariant &&
      defaultVariant &&
      defaultVariant !== Config.service.defaultVariant
    ) {
      showToasts({
        heading: `You have been redirected to the '${defaultVariant}' variant of this graph.`,
        level: 'info',
        message: `No default '${Config.service.defaultVariant}' variant has been set up for this graph.`,
      });
    }

    // cleanup toast on unmount
    return () => hideToasts(stableToastId);
  }, [
    legacyQueryParamVariant,
    defaultVariant,
    showToasts,
    hideToasts,
    stableToastId,
  ]);

  if (loadingDefaultVariant) return <Loading />;

  const redirectVariant = encodeURIComponent(
    legacyQueryParamVariant ?? defaultVariant ?? Config.service.defaultVariant,
  );

  /**
   * This is brittle, but we use it for now because I can't think of a way to do
   * a dynamic swap like this in the route while leveraging React Router. We
   * want to preserve the entire path EXCEPT for switching how the variant param
   * is placed.
   */
  const newPathname = location.pathname.replace(
    // we use a regex here to match the case of `graph/:graphId/variant` that
    // someone might write manually, in addition to `graph/:graphId` base case
    new RegExp(`^/${graphVisibilityType}/${graphId}(/variant)?`),
    `/${graphVisibilityType}/${graphId}/variant/${redirectVariant}`,
  );

  return (
    <Redirect
      push={false}
      to={{
        ...location,
        pathname: newPathname,
        search: stringify(other),
      }}
    />
  );
};

export const AppLayout = () => {
  const location = useLocation();
  const { me, meLoading, error: useIdentityError } = useIdentity();
  const [currentAccountId] = useCurrentAccountId();
  const currentPlanResult = useCurrentPlan({
    skip: !currentAccountId,
    accountId: currentAccountId || '',
  });

  useEffect(() => {
    // https://github.com/apollographql/dxe/tree/main/packages/utm-grabber
    utmGrabber();
  }, []);

  const isInviteRoute = !!useRouteMatch(routes.inviteLanding.definition);
  const isWelcomeRoute = !!useRouteMatch(routes.welcome.definition);

  const unifiedOnboardingFlagEnabled = useLDFlag(
    'astro-growth-unified-onboarding-survey',
  );

  const canAccessProposals = useCanAccessProposals();

  if (useIdentityError) {
    if (
      // try to figure out if this is a network error
      !(
        useIdentityError instanceof ApolloError &&
        useIdentityError.networkError?.message &&
        // different browsers have different messages for network errors
        [
          'Failed to fetch',
          'NetworkError when attempting to fetch resource.',
        ].includes(useIdentityError.networkError.message)
      )
    ) {
      // was not a network error / an unforeseen error has occurred
      throw useIdentityError;
    }
    // the user is offline, carry on and don't make a fuss
  }

  const { isLoggedIn } = useIsLoggedIn();

  if (meLoading) return <Loading />;

  if (
    !isInviteRoute &&
    !isWelcomeRoute &&
    me &&
    isUserIdentity(me) &&
    ((!me.email && me.canUpdateEmail) ||
      (!me.fullName && me.canUpdateFullName) ||
      !me.acceptedPrivacyPolicyAt)
  ) {
    return <Redirect to="/welcome" />;
  }

  // Redirect from '/' to the last org the user looked at (if they're still in
  // the org!) or to an org that they are in.
  const defaultAccount =
    me?.__typename !== 'User' ||
    me?.memberships.some(({ account }) => account.id === currentAccountId)
      ? currentAccountId
      : chooseDefaultAccountId(me);
  const rootRedirect = {
    ...location,
    pathname: defaultAccount ? `/org/${defaultAccount}` : '/user-settings',
  };

  return (
    <div className="flex h-full flex-col">
      <div className="flex h-full flex-col">
        <AppModals />
        {currentAccountId && !currentPlanResult?.data?.account?.isLocked && (
          <PermissionGuard
            accountId={currentAccountId}
            accountPermissions="canQueryBillingInfo"
            fallback={null}
          >
            <NoBillingEmailBanner accountId={currentAccountId} />
            {currentPlanResult?.currentPlan?.isTrial && (
              <TrialAlertBanner
                accountId={currentAccountId}
                learnMoreHref={DocsRouter.path('EnterpriseTrialOrgPlan')}
              />
            )}
          </PermissionGuard>
        )}
        {me && <NoVerifiedEmailBanner />}
        {currentAccountId && !currentPlanResult?.data?.account?.isLocked && (
          <SsoMigrationBanner accountId={currentAccountId} />
        )}
        <PublicVariantPreviewBanner />
        <ScheduledMaintenances />
        <UsageLimitAlerts />
        <Switch>
          {/* Redirects */}
          <Route
            path="/account"
            render={() => (
              <Navigate
                replace={true}
                to={{
                  ...location,
                  pathname: location.pathname.replace(/\/account\//, '/org/'),
                }}
              />
            )}
          />
          <Route
            path="/service"
            render={() => (
              <Navigate
                replace={true}
                to={{
                  ...location,
                  pathname: location.pathname.replace(/\/service\//, '/graph/'),
                }}
              />
            )}
          />
          <Route
            path={upgradeRouteConfig.definition}
            render={({ match }) => {
              const graphId = match.params.graphId;
              if (typeof graphId !== 'string') {
                throw new Error("required param 'graphId' was not a string");
              }
              return <RedirectLegacyGraphUpgrade graphId={graphId} />;
            }}
          />
          <Route
            path={sandboxGraphRouteConfig.definition}
            exact
            render={({ location: currentLocation }) => {
              return (
                <Redirect
                  to={{
                    ...currentLocation,
                    pathname: currentLocation.pathname.replace(
                      sandboxGraphRouteConfig.definition,
                      sandboxExplorerRouteConfig.definition,
                    ),
                  }}
                />
              );
            }}
          />
          <Route
            // TODO: this is brittle and should probably be incorporated into route config
            path={'/graph/:graphId@:variant' as `/graph/:graphId/:variant`}
            render={({ match, location: matchedLocation }) => {
              const { graphId, variant } = match.params;
              return (
                <Redirect
                  to={{
                    ...location,
                    pathname: `${matchedLocation.pathname.replace(
                      `${graphId}@${variant}`,
                      `${graphId}/variant/${variant}`,
                    )}`,
                  }}
                />
              );
            }}
          />
          <Route
            path="/support"
            render={() => (
              <Navigate
                replace={true}
                to={{
                  pathname: '/',
                  search: mergeQueryParams(location.search, {
                    [Config.queryParameters.Overlay]:
                      Config.modals.supportRequest,
                  }),
                }}
              />
            )}
          />
          <Route
            path="/upgrade"
            render={() => (
              <Navigate
                replace={true}
                to={upgradeRoute({ ...location, pathname: '/' })}
              />
            )}
          />
          <Route
            path="/"
            exact={true}
            render={() => {
              // We used to not make orgs for folks who signed up
              // through sandbox. The OrgProvisioner will automatically backfill
              // orgs for folks without them, so that they can use features that
              // require you to have an org
              if (
                isLoggedIn &&
                me?.__typename === 'User' &&
                !me?.memberships.length
              ) {
                return unifiedOnboardingFlagEnabled ? (
                  <UnifiedOnboardingSurveyModal />
                ) : (
                  <OrgProvisioner />
                );
              }
              return <Redirect to={rootRedirect} />;
            }}
          />
          {/* Application Code */}
          <Route
            path={routes.inviteLanding.definition}
            component={AccountInvitationHandler}
          />
          <Route
            path={atlasExplorerRouteConfig.definition}
            render={() => (
              <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
                <GlobalHeader leftNavContext={null}>
                  <Link to={atlasExplorerRouteConfig.definition}>
                    <Badge variant="neutralOutlined">Directory</Badge>
                  </Link>
                </GlobalHeader>

                <div className="flex h-full flex-1 overflow-y-auto">
                  <Toasts />
                  <ErrorBoundary>
                    <Switch>
                      <Route component={AtlasLayout} />
                    </Switch>
                  </ErrorBoundary>
                </div>
              </div>
            )}
          />
          <Route
            path={[
              variantRouteConfig.definition,
              sandboxGraphRouteConfig.definition,
            ]}
            render={() => (
              <ErrorBoundary>
                <React.Suspense fallback={<Loading />}>
                  <VariantLayout />
                </React.Suspense>
              </ErrorBoundary>
            )}
          />
          <Route
            path={proposalRouteConfig.definition}
            render={() => {
              if (!canAccessProposals) {
                return (
                  <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
                    <GlobalHeader leftNavContext="collapsed" />
                    <div className="flex h-full flex-1 overflow-y-auto">
                      <NotFound />
                    </div>
                  </div>
                );
              }
              return (
                <ErrorBoundary>
                  <React.Suspense fallback={<Loading />}>
                    <ProposalLayout />
                  </React.Suspense>
                </ErrorBoundary>
              );
            }}
          />
          <Route
            path={[
              persistedQueriesRouteConfig.definition,
              graphProposalListRouteConfig.definition,
            ]}
            render={() => {
              if (!canAccessProposals) {
                <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
                  <GlobalHeader leftNavContext="collapsed" />
                  <div className="flex h-full flex-1 overflow-y-auto">
                    <NotFound />
                  </div>
                </div>;
              }

              return (
                <ErrorBoundary>
                  <React.Suspense fallback={<Loading />}>
                    {/* Placeholder for new Graph IA layout */}
                    <GraphLayout />
                  </React.Suspense>
                </ErrorBoundary>
              );
            }}
          />
          {/*
            We put graphRouteConfig below variantRouteConfig here so it takes
            less precedence. We only want to move to the redirect if the variant
            route doesn't already match the current route.
          */}
          <Route
            path={graphRouteConfig.definition}
            // this is redirecting to the variantRouteConfig right now, but it's basically
            // a placeholder for our new "Graph Level IA" space
            render={() => <RedirectFromGraphPathToVariantPath />}
          />
          <Route
            path={userSettingsRouteConfig.definition}
            render={() => (
              <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
                <GlobalHeader leftNavContext="collapsed">
                  <OrgSwitcher collapseOrgName={false} />
                </GlobalHeader>
                <div className="flex h-full flex-1 overflow-y-auto">
                  <Toasts />
                  <ErrorBoundary>
                    <React.Suspense fallback={<Loading />}>
                      <Switch>
                        <Route component={SettingsPageLayout} />
                      </Switch>
                    </React.Suspense>
                  </ErrorBoundary>
                </div>
              </div>
            )}
          />
          <Route
            path="/org/:accountId"
            render={(props) => (
              <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
                <Switch>
                  <Route
                    path={[
                      '/org/:accountId/invite/:joinToken',
                      ...graphOnboardingRoutes, // TODO: Re-enable this once header is fixed
                    ]}
                  />

                  {/* Routes listed above are those for which the nav should be hidden */}
                  <Route
                    render={() => (
                      <GlobalHeader leftNavContext="collapsed">
                        <OrgSwitcher collapseOrgName={false} />
                      </GlobalHeader>
                    )}
                  />
                </Switch>
                <div className="flex h-full flex-1 overflow-y-auto">
                  <Toasts />
                  <ErrorBoundary>
                    <React.Suspense fallback={<Loading />}>
                      <AccountLayout {...props} />
                    </React.Suspense>
                  </ErrorBoundary>
                </div>
              </div>
            )}
          />
          <Route>
            <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
              <GlobalHeader leftNavContext="collapsed" />
              <div className="flex h-full flex-1 overflow-y-auto">
                <NotFound />
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
};
