import IconOutlink from '@apollo/icons/default/IconOutlink.svg';
import { Icon, IconProps, forwardRef } from '@chakra-ui/react';
import React from 'react';

import { OmitSvgChakraProps } from '../../utils/typeUtils';

export interface ExternalLinkIconProps
  extends OmitSvgChakraProps<IconProps, 'as'> {
  as?: React.ComponentType<{ className?: string }>;
}

/**
 * Icon meant to be used with external links
 * Defaults to the brand `IconOutlink`svg when `as` is not specified
 */
export const ExternalLinkIcon = forwardRef<ExternalLinkIconProps, 'svg'>(
  ({ as = IconOutlink, ...rest }, ref) => <Icon {...rest} as={as} ref={ref} />,
);
