import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { cssVar } from '@chakra-ui/theme-tools';

import { customColors } from '../../theme/customColors';
import { $iconColor } from '../Button/Button.style';
import { $groupJustify, $groupSpacingX } from '../Group/Group.style';

const { border, bg, text } = customColors;
const cellHeight = cssVar('table-cell-height');
const cellPadding = cssVar('table-cell-padding');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([...tableAnatomy.keys, 'sortButton']);

const numericStyles = defineStyle({
  '&[data-is-numeric=true]': {
    [$groupJustify.variable]: 'end',
    textAlign: 'end',
  },
});

const formElementStyles = defineStyle({
  '&[data-is-form-element=true]': {
    [$groupJustify.variable]: 'center',
    textAlign: 'center',
  },
});

const actionsMenuStyles = defineStyle({
  '&[data-is-actions-menu=true]': {
    [$groupJustify.variable]: 'end',
    textAlign: 'end',
  },
});

const baseStyle = definePartsStyle({
  sortButton: {
    [$iconColor.variable]: customColors.icon.secondary,
    transitionProperty: 'color, opacity',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
    opacity: 0,
    '&:hover': {
      [$iconColor.variable]: customColors.icon.primary,
    },
    '&:focus': {
      opacity: 1,
    },
    '&[data-is-current="true"]': {
      [$iconColor.variable]: customColors.icon.primary,
      opacity: 1,
    },
  },
  table: {
    bg: customColors.bg.primary,
    rounded: 'lg',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '&[data-interactive=true]': {
      tbody: {
        tr: {
          _focusVisible: {
            bg: customColors.bg['table-hover'],
            outline: 'none',
          },
          _hover: {
            td: {
              bg: customColors.bg['table-hover'],
            },
          },
        },
      },
    },
  },
  th: {
    [$groupSpacingX.variable]: 'space.2',
    color: text.heading,
    fontWeight: 'medium',
    fontFamily: 'var(--font-heading)',
    borderBottom: '1px solid',
    borderBottomColor: border.primary,
    px: 6,
    h: '40px',
    textTransform: false,
    ...numericStyles,
    ...formElementStyles,
    ...actionsMenuStyles,
    '&:hover': {
      '.orbit-table__sort-button': {
        opacity: 1,
      },
    },
  },
  td: {
    color: text.primary,
    borderBottom: '1px solid',
    borderBottomColor: border.primary,
    px: 6,
    py: cellPadding.reference,
    h: cellHeight.reference,
    ...numericStyles,
    ...formElementStyles,
    ...actionsMenuStyles,
    _empty: {
      _before: {
        content: `"–"`,
      },
    },
    '&:has(>.chakra-button:only-child,.chakra-checkbox:only-child):not([data-is-actions-menu=true])':
      {
        textAlign: 'center',
      },
  },
  thead: {
    position: 'sticky',
    top: 0,
    bg: bg.primary,
  },
  tfoot: {
    p: '4',
  },
});

const sizes = {
  condensed: definePartsStyle({
    table: {
      [cellHeight.variable]: '40px',
      [cellPadding.variable]: 'space.2',
    },
  }),
  default: definePartsStyle({
    table: {
      [cellHeight.variable]: '56px',
      [cellPadding.variable]: 'space.2',
    },
  }),
};

const variants = {
  plain: {},
  striped: definePartsStyle({
    td: {
      borderColor: border.primary,
    },
    th: {
      color: text.heading,
      fontWeight: 'medium',
      fontFamily: 'var(--font-heading)',
      borderColor: border.primary,
    },
    tbody: {
      // Use > selector to make this style more specific than Chakra's built-in
      // style. Unfortunately overriding the borderColor here doesn't seem to
      // work without this.
      '> tr': {
        '&:nth-of-type(odd)': {
          _focusVisible: {
            bg: customColors.bg['table-hover'],
          },
          td: {
            background: bg.secondary,
            borderBottomColor: border.primary,
          },
        },
      },
    },
  }),
};

export const TableStyles = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: { variant: 'plain', size: 'default' },
});
