import classnames from 'classnames';
import React from 'react';

import { Tooltip } from 'src/components/common/tooltip/Tooltip';

interface Section {
  label?: string;
  value: number;
  color: 'blue' | 'lightBlue' | 'red' | 'default';
}

interface Props {
  max?: number;
  sections: Section[];
  showLegend?: boolean;
  className?: string;
}

export const MultiColorProgressBar = ({
  max = 1,
  sections: sectionsProp,
  showLegend = true,
  className,
}: Props) => {
  const sections = [...sectionsProp];
  const sectionSum = sections.reduce((sum: number, { value }) => {
    return sum + value;
  }, 0);
  const maxDenominator = max >= sectionSum ? max : sectionSum;

  // Add offset if section sum is less than max
  if (sectionSum < max) {
    sections.push({
      value: max - sectionSum,
      color: 'default',
    });
  }

  const sectionsWithValues = sections.filter(({ value }) => value > 0);

  return (
    <div className={className && classnames(className)}>
      {showLegend && (
        <div className="mt-2 flex gap-4">
          {sections
            .filter(({ label }) => Boolean(label))
            .map(({ label, color }) => (
              <div className="flex gap-1" key={label}>
                <span
                  className={classnames(
                    'm-auto flex size-3 rounded-full border',
                    {
                      'border-blue-darkest bg-blue': color === 'blue',
                      'border-blue-light bg-blue-lighter':
                        color === 'lightBlue',
                      'border-red-dark bg-red': color === 'red',
                      'border-silver-darker bg-silver-light':
                        color === 'default',
                    },
                  )}
                />{' '}
                <span className="text-sm font-normal text-secondary">
                  {label}
                </span>
              </div>
            ))}
        </div>
      )}
      <div className="my-2 flex h-2 w-full overflow-hidden rounded-full">
        {sectionsWithValues.map(({ value, color, label }, idx) => (
          <Tooltip
            label={`${value.toLocaleString()} operations`}
            key={`${label}-${value}-${color}`}
          >
            <span
              style={{
                width: `${(value / maxDenominator) * 100}%`,
              }}
              className={classnames(
                'float-left h-full min-w-4',
                idx === sections.length - 1
                  ? 'border-y border-l-0 border-r'
                  : 'border',
                {
                  'border-blue-darkest bg-blue': color === 'blue',
                  'border-blue-light bg-blue-lighter': color === 'lightBlue',
                  'border-red-dark bg-red': color === 'red',
                  'border-silver-darker bg-silver-light': color === 'default',
                },
                {
                  'rounded-l-full': idx === 0,
                  'rounded-r-full': idx === sectionsWithValues.length - 1,
                },
              )}
            />
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
