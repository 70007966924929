import { FormErrors } from 'src/lib/forms/FormErrors';
import { OrgIDValidator } from 'src/lib/validators';

export interface FormData {
  accountName: string;
  accountId: string;
  companyUrl: string;
}

export const validateAccountInfo = (
  values: FormData,
): FormErrors<FormData> => ({
  ...(!values.accountName ? { accountName: 'is required' } : {}),
  ...validateAccountId(values.accountId),
});

export const validateAccountId = (slug: string) => {
  const reason = OrgIDValidator.validate(slug);
  return reason ? { accountId: reason } : {};
};
