import { captureException } from '@sentry/react';
import classnames from 'classnames';
import EventEmitter from 'eventemitter3';
import { MarkdownToJSX } from 'markdown-to-jsx'; // eslint-disable-line no-restricted-imports
import React, { useEffect, useState } from 'react';

import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';

import { EventCategory } from '../analytics';
import { isMinLengthArray } from '../isMinLengthArray';

import { populateEmoji } from './populateEmoji';
import { safeCompileMarkdown } from './safeCompileMarkdown';

const emojiDataEmitter = new EventEmitter<'loaded'>();
let emojiDataLoading: boolean;
/**
 * Until emoji data is loaded, use a proxy in it's place. This allows us to lazy
 * load the emoji data only once we try to render a readme with emojis in it.
 */
let loadedEmojiData: Record<string, string> = new Proxy(
  {},
  {
    get() {
      if (!emojiDataLoading) {
        emojiDataLoading = true;
        import(
          /* webpackChunkName: "EmojiData" */ 'markdown-it-emoji/lib/data/full.json'
        )
          .then((data) => {
            loadedEmojiData = data.default;
            emojiDataEmitter.emit('loaded');
          })
          .catch((err) => {
            captureException(err);
            emojiDataLoading = false;
          });
      }
    },
  },
);

export interface LinkableHeading {
  name: string;
  id: string;
  headingLevel: number;
}

const getTextContent = (el: React.ReactNode): string =>
  typeof el === 'string'
    ? el
    : !el || typeof el !== 'object' || !('props' in el)
    ? ''
    : Array.isArray(el.props.children)
    ? el.props.children.map(getTextContent).join('')
    : getTextContent(el.props.children);

const extractHeaders = (el: React.ReactElement): LinkableHeading[] => {
  const headingLevelMatch =
    typeof el.type === 'string' ? el.type.match(/h([0-9])/) : null;
  const contents = getTextContent(el);

  const heading =
    isMinLengthArray(2, headingLevelMatch) && el.props.id && contents
      ? {
          name: contents,
          id: el.props.id,
          headingLevel: parseInt(headingLevelMatch[1], 10),
        }
      : null;

  const childHeadings = Array.isArray(el.props.children)
    ? (el.props.children as React.ReactNode[]).flatMap((child) =>
        React.isValidElement(child) ? extractHeaders(child) : [],
      )
    : [];

  return [...(heading ? [heading] : []), ...childHeadings];
};

export const SafeMarkdown = React.forwardRef<
  HTMLDivElement,
  {
    markdown: string;
    className?: string;
    shouldPopulateEmojis?: boolean;
    includeDefaultOverrides?: boolean;
    options?: Omit<MarkdownToJSX.Options, 'disableParsingRawHTML'>;
    onHeadingsChange?: React.Dispatch<React.SetStateAction<LinkableHeading[]>>;
    analyticsLabelPrefix?: string;
    analyticsCategory?: EventCategory;
  }
>(
  (
    {
      markdown,
      className,
      shouldPopulateEmojis,
      includeDefaultOverrides,
      options,
      onHeadingsChange,
      analyticsLabelPrefix,
      analyticsCategory,
    },
    ref,
  ) => {
    const [emojiData, setEmojiData] = useState(
      shouldPopulateEmojis ? loadedEmojiData : {},
    );
    useEffect(() => {
      if (shouldPopulateEmojis) {
        const onLoad = () => {
          // on load the emoji proxy is replaced with the emoji data, we went to update the state with the new object
          setEmojiData(loadedEmojiData);
        };
        emojiDataEmitter.on('loaded', onLoad);
        return () => {
          emojiDataEmitter.off('loaded', onLoad);
        };
      }
    }, [shouldPopulateEmojis]);
    const compiledMarkdown = React.useMemo(() => {
      try {
        return safeCompileMarkdown(populateEmoji(markdown, emojiData), {
          ...options,
          includeDefaultOverrides,
          analyticsLabelPrefix,
          analyticsCategory,
        });
      } catch (err) {
        captureException(err);
        return <ErrorMessage />;
      }
    }, [
      analyticsCategory,
      analyticsLabelPrefix,
      emojiData,
      includeDefaultOverrides,
      markdown,
      options,
    ]);
    React.useEffect(() => {
      if (onHeadingsChange) {
        onHeadingsChange(extractHeaders(compiledMarkdown));
      }
    }, [compiledMarkdown, onHeadingsChange]);
    return (
      <div
        className={classnames('w-full bg-primary text-primary', className)}
        ref={ref}
      >
        {compiledMarkdown}
      </div>
    );
  },
);
