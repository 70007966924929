import { useCallback } from 'react';

import {
  PerKey,
  perKeyInitialValues,
  useLocalStorage,
} from 'src/hooks/useLocalStorage';

export const usePerKeyLocalStorage = <
  Key extends keyof typeof perKeyInitialValues,
>({
  key,
  localStorageKey,
  initialValue,
}: {
  key: string;
  localStorageKey: Key;
  initialValue: typeof perKeyInitialValues[Key] extends PerKey<infer V>
    ? V
    : never;
}) => {
  type Value = typeof perKeyInitialValues[Key] extends PerKey<infer V>
    ? V
    : never;
  const [values, setValues] = useLocalStorage(localStorageKey);

  const setValueForKey = useCallback(
    (nextValue: React.SetStateAction<Value>, setForKey: string) => {
      setValues((current) => {
        return {
          ...current,
          [setForKey]:
            nextValue instanceof Function
              ? nextValue((current[setForKey] ?? initialValue) as Value)
              : nextValue,
        };
      });
    },
    [initialValue, setValues],
  );

  const setValue = useCallback(
    (nextValue: React.SetStateAction<Value>) => setValueForKey(nextValue, key),
    [key, setValueForKey],
  );

  return [
    (values?.[key] ?? initialValue) as Value,
    setValue,
    setValueForKey,
  ] as const;
};
