import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import {
  LoggedInRoute,
  LoggedOutRoute,
  PublicRoute,
} from 'src/components/common/authenticatedRoutes/AuthenticatedRoutes';
import { Loading } from 'src/components/common/loading/Loading';
import { LazyRedirect } from 'src/components/lazyRedirect/LazyRedirect';

import { ChangePasswordPage } from '../account/changePasswordPage/ChangePasswordPage';

import { OnboardingLayout } from './components';
import * as routes from './routes/routes';
import { ForgotPasswordPage } from './views/forgotPasswordPage/ForgotPasswordPage';
import { LoginPage } from './views/loginPage/LoginPage';
import { SSOConfigurationPage } from './views/ssoPage/SSOConfigurationPage';
import { SSOPage } from './views/ssoPage/SSOPage';

const EmailVerificationPage = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "EmailVerificationPage" */ './views/emailVerificationPage/EmailVerificationPage'
    )
  ).EmailVerificationPage,
}));

const CombinedSignup = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "CombinedSignup" */ './views/signup/CombinedSignup'
    )
  ).CombinedSignup,
}));

const Welcome = React.lazy(async () => ({
  default: (
    await import(/* webpackChunkName: "Welcome" */ './views/welcome/Welcome')
  ).Welcome,
}));

/**
 * Enumeration of all the routes this portion of the application is responsible
 * for. We're using react-router-dom `Switch`, which does not support nesting
 * `Route`s inside of components
 */
export const ownedRoutes: string[] = [
  routes.login.definition,
  routes.loginWithApolloSSO.definition,
  routes.ssoConfiguration.definition,
  routes.inviteLanding.definition,
  routes.prodGraphLanding.definition,
  routes.signup.definition,
  routes.welcome.definition,
  routes.forgotPassword.definition,
  routes.verifyEmail.definition,
  routes.resetPassword.definition,
];

/**
 * # Docs
 *
 * [`/dev`](https://mermaid-js.github.io/mermaid-live-editor/#/edit/eyJjb2RlIjoiZ3JhcGggVERcbiAgICBBUlJJVkUoYXJyaXZlIGF0IC9kZXYpIC0tPiBMT0dHRURJTnthbHJlYWR5IGxvZ2dlZCBpbj99XG4gICAgTE9HR0VESU4gLS0-IHxZZXN8IE9SR1NcbiAgICBMT0dHRURJTiAtLT4gfE5vfCBTSUdOVVB7L3NpZ251cDogU2lnbiB1cCBvciBTaWduIElufVxuICAgIFNJR05VUCAtLT4gfFNpZ24gSW58IExPR0lOKExvZ2luIHdpdGggZW1haWwgb3IgR2l0SHViKVxuICAgIFNJR05VUCAtLT4gfFNpZ24gVXB8IFdFTENPTUVcbiAgICBMT0dJTiAtLT4gT1JHU3tIb3cgbWFueSBvcmdzP31cbiAgICBPUkdTIC0tPiB8MHwgV0VMQ09NRShcIi93ZWxjb21lXCIpXG4gICAgT1JHUyAtLT4gfDF8IE1PREFMXG4gICAgT1JHUyAtLT4gfD4xfCBQSUNLT05FKFBpY2sgT25lKVxuICAgIFBJQ0tPTkUgLS0-IE1PREFMXG4gICAgV0VMQ09NRSAtLT4gTU9EQUwoXCJkZXYgZ3JhcGggbW9kYWxcIikiLCJtZXJtYWlkIjp7InRoZW1lIjoiZGVmYXVsdCJ9LCJ1cGRhdGVFZGl0b3IiOmZhbHNlfQ)
 *
 */

export const Onboarding = () => {
  const location = useLocation();

  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        <LazyRedirect
          from={routes.prodGraphLanding.definition}
          exact
          to={() =>
            routes.signup.locationFrom({
              location,
              fromRouteConfig: routes.prodGraphLanding,
              patch: {
                type: 'prod',
              },
            })
          }
        />
        <LazyRedirect
          from={routes.inviteLanding.definition}
          exact
          to={() =>
            routes.signup.locationFrom({
              location,
              fromRouteConfig: routes.inviteLanding,
              patch: { type: 'invite' },
            })
          }
        />
        <Route
          exact
          path={routes.signup.definition}
          render={() => <CombinedSignup />}
        />
        <LoggedOutRoute
          exact
          path={routes.loginWithApolloSSO.definition}
          render={() => <SSOPage returnToUrl={window.location.origin} />}
        />
        <PublicRoute
          path={routes.ssoConfiguration.definition}
          render={() => <SSOConfigurationPage />}
        />
        <OnboardingLayout>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <LoggedOutRoute
                exact
                path={routes.login.definition}
                component={LoginPage}
              />
              <LoggedOutRoute
                exact
                path={routes.forgotPassword.definition}
                component={ForgotPasswordPage}
              />
              <LoggedInRoute
                exact
                path={routes.verifyEmail.definition}
                component={EmailVerificationPage}
              />
              <LoggedOutRoute
                exact
                path={routes.resetPassword.definition}
                component={ChangePasswordPage}
              />
              <Route
                path={routes.welcome.definition}
                render={() => <Welcome />}
              />
            </Switch>
          </React.Suspense>
        </OnboardingLayout>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </React.Suspense>
  );
};
