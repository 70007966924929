import _ from 'lodash';
import React, { useCallback } from 'react';

import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';
import {
  PerGraphIdentifier,
  perGraphIdentifierInitialValues,
  readFromLocalStorage,
  useLocalStorage,
  writeToLocalStorage,
} from 'src/hooks/useLocalStorage';
import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';

import {
  GraphRef,
  graphRefToString,
  useGraphRef,
} from '../../hooks/useGraphRef';

// If these change, update `getRunTelemetryLocalStorage.ts`
const SANDBOX_STORAGE_DOMAIN = 'unauthenticated-explorer';
const SANDBOX_STORAGE_ID = 'none';
const EMBEDDED_STORAGE_DOMAIN = 'embeddable-explorer';
const EMBEDDED_SANDBOX_STORAGE_DOMAIN = 'embeddable-sandbox';
const TEMPORARY_EMBEDDED_STORAGE_DOMAIN = 'temporary-embeddable-explorer';

export type UpdateFunction<Value> = (
  newValueOrUpdate: Value | ((currentValue: Value) => Value),
) => void;

export const INITIAL_CONFIRMED_PUBLIC_URL = {};

// This is exported only for use in the GraphSetupModal. This is a case where
// the graph ref can't be found w/ `useGraphRef` and needs to be passed in
export const getGraphIdentifierFromGraphRef = (
  graphRef: GraphRef,
): GraphIdentifier => ({
  domain: graphRef.graphId,
  id: graphRef.graphVariant,
});

/**
 * The GraphIdentifier is used only for local storage purposes via
 * usePerGraphIdentifierLocalStorage.
 *
 * @param shouldNamespaceSandboxByEndpoint
 * If a piece of local storage should be stored by Sandbox endpoint, this should be true.
 * Some pieces of Sandbox local storage are constant across endpoints
 *
 * @param shouldOnlyStoreForRegisteredGraphs
 * If a piece of local storage should only be stored for registered graphs,
 * this should be true. This hook will error if the graphRef doesn't exist
 */

type UseLocalStorageGraphIdentifierReturnType<
  ShouldOnlyStoreForRegisteredGraphs,
> = ShouldOnlyStoreForRegisteredGraphs extends true
  ? GraphIdentifier | null
  : GraphIdentifier;

export function useLocalStorageGraphIdentifier<
  ShouldOnlyStoreForRegisteredGraphs extends boolean,
>({
  shouldNamespaceSandboxByEndpoint,
  shouldOnlyStoreForRegisteredGraphs,
}: {
  shouldNamespaceSandboxByEndpoint: boolean;
  shouldOnlyStoreForRegisteredGraphs: ShouldOnlyStoreForRegisteredGraphs;
}): UseLocalStorageGraphIdentifierReturnType<ShouldOnlyStoreForRegisteredGraphs> {
  const graphRef = useGraphRef();
  // per key so if you have multiple graphs on a page they can each have their own sessions
  const [temporaryEmbedLocalStorageIdForGraphRef] = usePerKeyLocalStorage({
    initialValue: undefined,
    key: graphRef ? graphRefToString(graphRef) : 'manualSchema',
    localStorageKey: 'temporaryEmbedLocalStorageId',
  });
  const [sandboxUrl] = useLocalStorage('sandboxUrl');

  return React.useMemo(() => {
    if (shouldOnlyStoreForRegisteredGraphs) {
      // This is safe b/c we will return null only if shouldOnlyStoreForRegisteredGraphs is true
      if (!graphRef)
        return null as UseLocalStorageGraphIdentifierReturnType<ShouldOnlyStoreForRegisteredGraphs>;

      return getGraphIdentifierFromGraphRef(graphRef);
    }

    const graphRefAsString = graphRefToString(graphRef);

    let embeddedPrefix: string;
    try {
      const documentReferrerObj = new URL(document.referrer);
      embeddedPrefix = `${documentReferrerObj.origin}${documentReferrerObj.pathname}`;
    } catch {
      embeddedPrefix = 'noreferrer';
    }

    return isEmbeddableExplorerRoute()
      ? // We allow folks to pass whether or not they want their sessions to persist
        // for embedded explorer. If they are ephemeral, we push an id to the url.
        temporaryEmbedLocalStorageIdForGraphRef
        ? {
            domain: TEMPORARY_EMBEDDED_STORAGE_DOMAIN,
            id: temporaryEmbedLocalStorageIdForGraphRef,
          }
        : // on the embeddable route, we locally store all settings by parent hostname
          // We can't access the parent hostname directly
          // from an iframe, so we use document.referrer
          {
            domain: EMBEDDED_STORAGE_DOMAIN,
            id: `${embeddedPrefix}:${graphRefAsString ?? ''}`,
          }
      : graphRef
      ? getGraphIdentifierFromGraphRef(graphRef)
      : {
          domain: isEmbeddableSandboxRoute()
            ? `${EMBEDDED_SANDBOX_STORAGE_DOMAIN}:${embeddedPrefix}`
            : SANDBOX_STORAGE_DOMAIN,
          id: shouldNamespaceSandboxByEndpoint
            ? sandboxUrl
            : SANDBOX_STORAGE_ID,
        };
  }, [
    graphRef,
    sandboxUrl,
    shouldNamespaceSandboxByEndpoint,
    shouldOnlyStoreForRegisteredGraphs,
    temporaryEmbedLocalStorageIdForGraphRef,
  ]);
}

export function clearTemporaryEmbeddablePerGraphIdentifierLocalStorage() {
  (
    Object.keys({
      ...perGraphIdentifierInitialValues,
    }) as Array<keyof typeof perGraphIdentifierInitialValues>
  ).forEach((key) => {
    const localStorageItems = readFromLocalStorage(key);

    if (
      localStorageItems &&
      TEMPORARY_EMBEDDED_STORAGE_DOMAIN in localStorageItems
    ) {
      const toWrite = _.omit(
        localStorageItems,
        TEMPORARY_EMBEDDED_STORAGE_DOMAIN,
      );

      writeToLocalStorage(key, toWrite);
    }
  });
}

export interface GraphIdentifier {
  domain: string;
  id: string;
}

export const usePerGraphIdentifierLocalStorage = <
  Key extends keyof typeof perGraphIdentifierInitialValues,
  Identifier extends GraphIdentifier | null,
>({
  key,
  graphIdentifier,
  stableInitialValue,
}: {
  key: Key;
  graphIdentifier: Identifier;
  stableInitialValue: typeof perGraphIdentifierInitialValues[Key] extends PerGraphIdentifier<
    infer V
  >
    ? V
    : never;
}): [
  typeof stableInitialValue,
  (
    | UpdateFunction<typeof stableInitialValue>
    | (null extends Identifier ? null : never)
  ),
] => {
  type Value = typeof stableInitialValue;
  const [values, setValues] = useLocalStorage(key);

  const { domain: graphIdentifierDomain, id: graphIdentifierId } =
    graphIdentifier ?? {};
  const setValue = useCallback(
    (nextValue: React.SetStateAction<Value>) => {
      if (
        typeof graphIdentifierDomain !== 'string' ||
        typeof graphIdentifierId !== 'string'
      ) {
        return;
      }
      setValues((current) => {
        return {
          ...current,
          [graphIdentifierDomain]: {
            ...current[graphIdentifierDomain],
            [graphIdentifierId]:
              nextValue instanceof Function
                ? nextValue(
                    (current[graphIdentifierDomain]?.[graphIdentifierId] ??
                      stableInitialValue) as Value,
                  )
                : nextValue,
          },
        };
      });
    },
    [graphIdentifierDomain, graphIdentifierId, stableInitialValue, setValues],
  );

  if (!graphIdentifier) {
    // !graphIdentifier implies GraphIdentifier | null includes null, so the null return
    // here is safe.
    return [stableInitialValue, null as null extends Identifier ? null : never];
  }

  const { [graphIdentifier.domain]: graphValues } = values;
  return [
    (graphValues?.[graphIdentifier.id] ?? stableInitialValue) as Value,
    setValue,
  ];
};

/**
 * Wrapper around writeToLocalStorage that reads a value scoped to graph
 * identifier. Use this if usePerGraphIdentifierLocalStorage will cause unneeded
 * hook calls/rerenders.
 */
export function useReadFromPerGraphIdentifierLocalStorage<
  Key extends keyof typeof perGraphIdentifierInitialValues,
  GraphIdentifierOrNull extends GraphIdentifier | null,
>({
  key,
  graphIdentifier,
  stableInitialValue,
}: {
  key: Key;
  graphIdentifier: GraphIdentifierOrNull;
  stableInitialValue: typeof perGraphIdentifierInitialValues[Key] extends PerGraphIdentifier<
    infer V
  >
    ? V
    : never;
}): () => typeof stableInitialValue {
  type Value = typeof stableInitialValue;
  return React.useCallback(() => {
    const values = readFromLocalStorage(key);

    if (!graphIdentifier) {
      return stableInitialValue;
    }

    return (values[graphIdentifier.domain]?.[graphIdentifier.id] ??
      stableInitialValue) as Value;
  }, [key, graphIdentifier, stableInitialValue]);
}

/**
 * Wrapper around writeToLocalStorage that writes a value scoped to graph
 * identifier. Use this if usePerGraphIdentifierLocalStorage will cause unneeded
 * hook calls/rerenders.
 */
export function useWriteToPerGraphIdentifierLocalStorage<
  Key extends keyof typeof perGraphIdentifierInitialValues,
  GraphIdentifierOrNull extends GraphIdentifier | null,
>({
  key,
  graphIdentifier,
  stableInitialValue,
}: {
  key: Key;
  graphIdentifier: GraphIdentifierOrNull;
  stableInitialValue: typeof perGraphIdentifierInitialValues[Key] extends PerGraphIdentifier<
    infer V
  >
    ? V
    : never;
}): UpdateFunction<typeof stableInitialValue> {
  type Value = typeof stableInitialValue;

  return React.useCallback(
    (nextValue) => {
      if (!graphIdentifier) {
        return;
      }
      writeToLocalStorage(key, (current) => {
        return {
          ...current,
          [graphIdentifier.domain]: {
            ...current[graphIdentifier.domain],
            [graphIdentifier.id]:
              nextValue instanceof Function
                ? nextValue(
                    (current[graphIdentifier.domain]?.[graphIdentifier.id] ??
                      stableInitialValue) as Value,
                  )
                : nextValue,
          },
        };
      });
    },
    [key, graphIdentifier, stableInitialValue],
  );
}

export const migrateSandboxLocalStorageToPerReferrer = () => {
  let newPrefix: string;
  try {
    const documentReferrerObj = new URL(document.referrer);
    newPrefix = `${documentReferrerObj.origin}${documentReferrerObj.pathname}`;
  } catch {
    newPrefix = 'noreferrer';
  }

  if (isEmbeddableSandboxRoute()) {
    (
      Object.keys(
        perGraphIdentifierInitialValues,
      ) as (keyof typeof perGraphIdentifierInitialValues)[]
    ).forEach((key) => {
      const currentValue = readFromLocalStorage(key);
      const embeddableSandboxValues =
        currentValue[EMBEDDED_SANDBOX_STORAGE_DOMAIN];

      if (
        embeddableSandboxValues &&
        // if the user has local storage for this url, we migrated
        // previously, don't write over their progress
        !currentValue[`${EMBEDDED_SANDBOX_STORAGE_DOMAIN}:${newPrefix}`]
      ) {
        const modifiedCurrentValue = { ...currentValue };
        modifiedCurrentValue[
          `${EMBEDDED_SANDBOX_STORAGE_DOMAIN}:${newPrefix}`
        ] = embeddableSandboxValues;

        writeToLocalStorage(key, {
          ...modifiedCurrentValue,
        });
      }
    });
  }
};

// migrating from document.referrer which might be the whole href to
// document.referrer.origin+document.referrer.path which doesn't include query params
export const migrateEmbeddedExplorerLocalStorageToPerOriginAndPath = () => {
  let newPrefix: string;
  try {
    const documentReferrerObj = new URL(document.referrer);
    newPrefix = `${documentReferrerObj.origin}${documentReferrerObj.pathname}:`;
  } catch {
    newPrefix = 'noreferrer:';
  }

  (
    Object.keys(
      perGraphIdentifierInitialValues,
    ) as (keyof typeof perGraphIdentifierInitialValues)[]
  ).forEach((key) => {
    const currentValue = readFromLocalStorage(key);
    const embeddableExplorerValues = currentValue[EMBEDDED_STORAGE_DOMAIN];
    if (embeddableExplorerValues) {
      const newLocalStorageValues = Object.fromEntries(
        Object.entries(embeddableExplorerValues).map(([id, value]) => {
          const indexOfSeperatorColon = id.lastIndexOf(':');
          const newId = `${newPrefix}${id.slice(indexOfSeperatorColon + 1)}`;
          return [id.startsWith(`${document.referrer}:`) ? newId : id, value];
        }),
      );
      if (!_.isEqual(newLocalStorageValues, embeddableExplorerValues)) {
        writeToLocalStorage(key, {
          ...currentValue,
          [EMBEDDED_STORAGE_DOMAIN]: newLocalStorageValues,
        });
      }
    }
  });
};
