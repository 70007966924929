import { find } from 'lodash';
import { parse } from 'query-string';
import { matchPath } from 'react-router';

import { sandboxGraphRouteConfig } from 'src/app/graph/routes';
import { isCypressUserEmail } from 'src/lib/isCypressUser';

import Config from '../config';

const wwwFunctionsRootPath =
  'https://apollographql-dot-com.netlify.app/.netlify/functions';

export const PROGRAM_ID_STUDIO_HIGHER_LIMIT_REQUEST = 1527;
export const PROGRAM_ID_STUDIO_ENTERPRISE_TRIAL_SIGN_UP = 1942;
export const LEAD_SOURCE_DETAIL = 'Ongoing_Product_Sign Up Enterprise Trial';

// if we ever add a significantly new route type, we'll need to add it here for proper analytics
const pageCategories = {
  account: /^\/org/,
  service: /^\/service/,
  graph: /^\/graph/,
  root: /^\//, // this isn't great, but ok for now - it's hard to detect 404s
};

let lastPathname = '';
const getPageCategory = (page: string) => {
  let category = null;
  find(pageCategories, (regex, key) => {
    if (regex.test(page)) {
      category = key;
      return true;
    }
    return false;
  });
  return category || '';
};

const getPageName = (pathname: string) => {
  // TODO: find a corresponding route config by pathname, have route configs define page names
  if (matchPath(pathname, sandboxGraphRouteConfig.definition)) {
    return 'Sandbox';
  }
  return 'Unnamed';
};

/*
 * A helper for all the events we send to Segment.
 *
 * The event types:
 *   identify
 *   group
 *   track
 *   page
 */
export const Analytics = {
  isAvailable() {
    return typeof analytics !== 'undefined';
  },

  // Initialize Segment analytics on page load.
  initialize(key: string) {
    if (typeof analytics === 'undefined') return;
    analytics.load(key);
  },

  // Identify users with Segment when they start a session.
  // This data percolates to Intercom, etc.
  identify(user: {
    id: string;
    fullName: string;
    email: string | null;
    avatarUrl: string | null;
    createdAt: string | number;
  }) {
    if (typeof analytics === 'undefined') return;
    const { id, fullName, email, avatarUrl, createdAt } = user;
    analytics.identify(id, {
      ...Config.analyticsPayload,
      email,
      name: fullName,
      createdAt,
      avatar: {
        type: 'avatar',
        image_url: avatarUrl, // eslint-disable-line @typescript-eslint/naming-convention
      },
    });
  },

  // Associate users with the group (organizations) they're
  // visiting. This data percolates to Intercom, etc.
  group(currentAccount: {
    id: string;
    name: string;
    avatarUrl: string | null;
    createdAt: string | number | null;
    currentPlan?: { id: string } | null;
  }) {
    if (typeof analytics === 'undefined') return;
    const currentPlan = currentAccount.currentPlan;
    if (!currentAccount.id || !currentPlan) return;
    const { id, name, avatarUrl, createdAt } = currentAccount;
    analytics.group(id, {
      ...Config.analyticsPayload,
      name,
      website: `https://www.github.com/${name}`,
      plan: currentPlan.id,
      avatar: avatarUrl,
      createdAt,
    });
  },

  // Track specific events triggered by users; eg: email updated, trial started.
  track(userId: string, event: string, properties: object) {
    if (typeof analytics === 'undefined') return;
    analytics.track(event, {
      ...Config.analyticsPayload,
      userId,
      ...properties,
    });
  },

  // Track each URL that's visited and the URL category
  page(pathname: string, title: string) {
    if (lastPathname === pathname || typeof analytics === 'undefined') return;
    lastPathname = pathname;
    // https://segment.com/docs/connections/spec/page/
    analytics.page(getPageCategory(pathname), getPageName(pathname), {
      ...Config.analyticsPayload,
      title,
      pathname,
    });
  },
};

export type EventCategory =
  // Pages
  | 'Explorer'
  | 'Sandbox Check'
  | 'Sandbox Diff'
  | 'Sandbox'
  | 'Sandbox - Local'
  | 'Sandbox - Remote'
  | 'Variant Home'
  | 'README Shortcodes'
  | 'Embeddable Explorer'
  | 'Embeddable Sandbox'
  | 'Subgraphs Page'
  | 'Cloud Router Settings'
  | 'Persisted Queries'
  // User Flows
  | 'Login Flow'
  | 'Feature Intros'
  // Features
  | 'Operation Collections'
  | 'Contracts'
  // Org Creation Flow
  | 'Org Creation Flow'
  // SSO Flow
  | 'SSO Wizard'
  // Upgrade Account
  | 'Upgrade Account'
  // Cancel Paid Subscription Flow
  | 'Cancel Paid Subscription'
  // Supergraph
  | 'Supergraph Onboarding'
  // Misc
  | 'Temporary'
  | 'Unspecified'
  | 'Service Workers'
  | 'Keyboard Shortcuts'
  | 'Schema Reference'
  | 'Fields'
  | 'Field Insights'
  | 'Insights L3 Fields List'
  | 'Insights L3 Object Fields List'
  | 'Insights L3 Input Fields List'
  | 'Insights L3 Enum Values List'
  | 'Operation List'
  | 'Operation Insights'
  | 'Insights L3 Operations List'
  | 'Insights L3 List'
  | 'Graph Settings Variants'
  | 'Trace Rollup'
  | 'GraphViz'
  // 6M2S
  | '6M2S: Onboarding'
  // Linting
  | 'Lint Checks'
  // Enterprise Trial
  | 'Enterprise Trial Sign Up'
  | 'Language Server';

// This is not an event. This sets a property on the user
export const trackUserIsLoggedIn = (isLoggedIn: boolean) => {
  window.gtag?.('set', 'user_properties', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    logged_in: isLoggedIn,
  });
};

// This is not an event. This sets a property on the user & the config
// https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites
export const trackUserId = (userId: string | undefined) => {
  // the G- is the google measurement id - you can find it here
  // https://analytics.google.com/analytics/web/#/a74643563p263258241/admin/streams/table/2347074944
  window.gtag?.('config', 'G-0BGG5V2W2K', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_id: userId,
  });
  window.gtag?.('set', 'user_properties', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    apollo_user_id: userId,
  });
};

export const getTrackingCookie = (
  name: typeof Config.cookies[keyof typeof Config.cookies],
) => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]?.trimStart();
    if (cookie?.indexOf(nameEQ) === 0)
      return cookie.substring(nameEQ.length, cookie.length);
  }
  return undefined;
};

export const setTrackingCookie = (
  param: typeof Config.cookies[keyof typeof Config.cookies],
  pValue: string,
) => {
  if (param && pValue) {
    document.cookie = `${param}=${pValue}; samesite=lax; path=/; max-age${
      60 * 60 * 24
    }`;
  }
};

// Google's docs for window.gtag get:
// https://developers.google.com/gtagjs/reference/api#set_examples
export function getGaClientId() {
  return new Promise<string | null>((resolve) => {
    if (window.gtag) {
      window.gtag('get', Config.gaTrackingID, 'client_id', (clientId) => {
        resolve(clientId);
        clearTimeout(timeout);
      });
      const timeout = setTimeout(() => {
        resolve(null);
      }, 2000);
    } else {
      resolve(null);
    }
  });
}

async function getMarketoProgramNameById(programId: number) {
  // netlify headless func living in the www repo:
  // https://github.com/apollographql/apollographql.com/blob/main/functions/program.js
  // this func hits the marketo api to return data for programs
  try {
    const programResp = await fetch(`${wwwFunctionsRootPath}/program`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: programId,
      }),
    });
    if (!programResp.ok) {
      throw new Error('response unsuccessful');
    }
    return programResp.json();
  } catch (error) {
    return null;
  }
}

async function pushToMarketoApi(data: object) {
  // netlify headless func living in the www repo:
  // https://github.com/apollographql/apollographql.com/blob/main/functions/marketo.js
  // this func pushes user data to marketo via the leads push api
  try {
    const response = await fetch(`${wwwFunctionsRootPath}/marketo`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.ok;
  } catch (error) {
    return null;
  }
}

/**
 pushMarketingAnalytics is currently being used to push studio signup user data 
 to Marketo & track higher usage limit requests
*/

interface UTM {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  referrer?: string;
}

export interface Lead {
  programId: number;
  source: 'Product' | 'Upgrade Inquiry';
  sourceDetail: string;
}
interface PushMarketingAnalyticsArgs {
  userId?: string;
  fullName: string;
  email: string;
  utm?: null | UTM;
  companyUrl?: string;
  orgId?: string;
  lead: Lead;
}

export async function pushMarketingAnalytics({
  userId,
  fullName,
  email,
  utm,
  companyUrl,
  orgId,
  lead,
}: PushMarketingAnalyticsArgs) {
  // don't push to marketo if Cypress is running the script
  if (!isCypressUserEmail(email)) {
    const programName = await getMarketoProgramNameById(lead.programId);
    if (programName) {
      const [firstName, ...otherNames] = fullName.split(' ');
      // last name must be passed in order for salesforce sync to work (marketo)
      // if last name doesn't exist, we'll use the first name as the last
      // and pass an empty string for the first name
      // (this also applies to github signups)
      const lastName = otherNames.join(' ');
      const inputObj: { [key: string]: string | Object | undefined } = {
        firstName: !lastName ? '' : firstName,
        lastName: !lastName ? firstName : lastName,
        email,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Lead_Source_Most_Recent__c: lead.source,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Lead_Source_Most_Recent_Detail__c: lead.sourceDetail,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        UTM_Medium__c: utm?.utmMedium,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        UTM_Source__c: utm?.utmSource,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        UTM_Campaign__c: utm?.utmCampaign,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Referrer__c: utm?.referrer,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Company_Domain__c: companyUrl,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Studio_Organization_ID__c: orgId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Studio_User_Id__c: userId,
      };
      const marketoObj = {
        programName,
        source: 'Product',
        lookupField: 'email',
        input: [inputObj],
      };
      const response = await pushToMarketoApi(marketoObj);
      return response;
    }
  }
}

export function getTrackingCookies() {
  const {
    [Config.queryParameters.Referrer]: referrer,
    [Config.queryParameters.Campaign]: utmCampaign,
    [Config.queryParameters.Medium]: utmMedium,
    [Config.queryParameters.Source]: utmSource,
  } = parse(window.location.search);

  // handle cookie values
  // if query param exists, use it's value and set a cookie based on it
  // else, try to grab the cookie - if it doesn't exist, the func returns
  // undefined
  let sourceCookie, mediumCookie, campaignCookie, referrerCookie;
  if (typeof utmSource === 'string' && utmSource) {
    setTrackingCookie(Config.cookies.Source, utmSource);
    sourceCookie = utmSource;
  } else {
    sourceCookie = getTrackingCookie(Config.cookies.Source);
  }

  if (typeof utmMedium === 'string' && utmMedium) {
    setTrackingCookie(Config.cookies.Medium, utmMedium);
    mediumCookie = utmMedium;
  } else {
    mediumCookie = getTrackingCookie(Config.cookies.Medium);
  }

  if (typeof utmCampaign === 'string' && utmCampaign) {
    setTrackingCookie(Config.cookies.Campaign, utmCampaign);
    campaignCookie = utmCampaign;
  } else {
    campaignCookie = getTrackingCookie(Config.cookies.Campaign);
  }

  if (typeof referrer === 'string' && referrer) {
    setTrackingCookie(Config.cookies.Referrer, referrer);
    referrerCookie = referrer;
  } else {
    referrerCookie = getTrackingCookie(Config.cookies.Referrer);
  }

  return {
    sourceCookie,
    mediumCookie,
    campaignCookie,
    referrerCookie,
  };
}

declare module 'react' {
  interface DOMAttributes<T> {
    readonly ['data-analytics-category']?: EventCategory;
    readonly ['data-analytics-label']?: string;
    readonly ['data-analytics-orgId']?: string;
  }

  interface RefAttributes<T> {
    readonly ['data-analytics-category']?: EventCategory;
    readonly ['data-analytics-label']?: string;
    readonly ['data-analytics-orgId']?: string;
  }
}

declare module '@react-types/switch' {
  interface SwitchProps {
    readonly ['data-analytics-category']?: EventCategory;
    readonly ['data-analytics-label']?: string;
    readonly ['data-analytics-orgId']?: string;
  }
}

declare module 'react-router-dom' {
  interface LinkProps<S> {
    readonly ['data-analytics-category']?: EventCategory;
    readonly ['data-analytics-label']?: string;
    readonly ['data-analytics-orgId']?: string;
  }
}
