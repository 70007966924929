// This is the preferred wrapper for `markdown-to-jsx`
import { MarkdownToJSX, compiler as compileMarkdown } from 'markdown-to-jsx'; // eslint-disable-line no-restricted-imports

import { EventCategory } from '../analytics';

import { A } from './A';
import { Code } from './Code';
import { Li } from './Li';
import { Pre } from './Pre';

export function safeCompileMarkdown(
  markdown: string,
  {
    includeDefaultOverrides,
    analyticsLabelPrefix,
    analyticsCategory,
    ...options
  }: Omit<MarkdownToJSX.Options, 'disableParsingRawHTML'> & {
    includeDefaultOverrides?: boolean;
    analyticsLabelPrefix?: string;
    analyticsCategory?: EventCategory;
  } = {},
) {
  return compileMarkdown(markdown, {
    ...options,
    overrides: !includeDefaultOverrides
      ? options?.overrides
      : {
          a: {
            component: A,
            props: {
              className: 'text-link hover:underline text-base',
              analyticsLabelPrefix,
              analyticsCategory,
            },
          },
          blockquote: {
            props: {
              className:
                'text-midnight border-solid border-l-2 border-blilet-lighter pl-4 my-6 py-1 text-base',
            },
          },
          code: {
            component: Code,
            props: { analyticsLabelPrefix, analyticsCategory },
          },
          em: {
            props: {
              className: 'text-base italic font-normal',
            },
          },
          h1: {
            props: {
              className: 'text-heading-3xl border-b border-primary mb-4 py-2',
            },
          },
          h2: {
            props: {
              className: 'text-heading-2xl border-b border-primary mb-2 py-2',
            },
          },
          h3: {
            props: {
              className: 'text-heading-xl border-b border-primary mb-1 py-2',
            },
          },
          h4: {
            props: {
              className: 'text-heading-lg py-2',
            },
          },
          h5: {
            props: {
              className: 'text-title py-2',
            },
          },
          h6: {
            props: {
              className: 'text-subtitle py-2',
            },
          },
          input: {
            props: {
              className: 'h-auto align-middle mr-1 mb-0.5',
            },
          },
          li: {
            component: Li,
          },
          ol: {
            props: {
              className: 'list-decimal list-outside pl-0 ml-8 mb-6',
            },
          },
          pre: {
            component: Pre,
          },
          ul: {
            props: {
              className: 'list-disc list-outside pl-0 ml-8 mb-6',
            },
          },
          hr: {
            props: {
              className: 'border-primary my-6',
            },
          },
          table: {
            props: {
              className: 'border-primary border my-6',
            },
          },
          th: {
            props: {
              className:
                'py-1.5 px-3.5 border-r border-primary font-semibold text-base',
            },
          },
          td: {
            props: {
              className:
                'py-1.5 px-3.5 border-t border-r border-primary text-base',
            },
          },
          ...options?.overrides,
        },
    disableParsingRawHTML: true,
  });
}
