import { gql, useQuery } from '@apollo/client';

import { GraphQLTypes } from 'src/lib/graphqlTypes';

export const useIsLoggedIn = () => {
  const { data } = useQuery(
    gql<
      GraphQLTypes.UseIsLoggedInQuery,
      GraphQLTypes.UseIsLoggedInQueryVariables
    >`
      query UseIsLoggedInQuery {
        isLoggedIn @client
      }
    `,
  );

  return {
    isLoggedIn: !!data?.isLoggedIn,
    loading: typeof data?.isLoggedIn !== 'boolean',
  };
};
