import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors, customColorsRaw } from '../../theme/customColors';

const { bg, border, text } = customColors;

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const sizes = {
  xs: definePartsStyle({ dialog: { w: '320px' } }),
  sm: definePartsStyle({ dialog: { w: '336px' } }),
  md: definePartsStyle({ dialog: { w: '400px' } }),
  lg: definePartsStyle({ dialog: { w: '464px' } }),
  xl: definePartsStyle({ dialog: { w: '528px' } }),
  full: definePartsStyle({
    dialog: { w: '100%', minH: false, my: '16', mx: '6', rounded: 'lg' },
  }),
};

const baseStyle = definePartsStyle({
  dialog: {
    maxH: 'calc(100% - 10rem)',
    bg: bg.primary,
    rounded: 'lg',
    boxShadow: 'modals',
  },
  dialogContainer: {
    overflowY: 'auto',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    bg: bg.primary,
    py: '2',
    px: '6',
    color: text.primary,
    minH: '80px',
    '&:first-child': {
      pt: 6,
      roundedTop: 'lg',
    },
    '&:last-child': {
      pb: 6,
      roundedBottom: 'lg',
    },
    '> .chakra-alert': {
      flexShrink: 0,
    },
    '&:has(> .chakra-table__container, > .chakra-table)': {
      p: 0,
      // Allow the table to take up its natural height to ensure the scroll
      // border is properly displayed
      '& > .chakra-table__container, & > .chakra-table': {
        flexShrink: 0,
      },
    },
    '&:last-child .chakra-table:last-child tr:last-child td': {
      borderBottom: 'none',
      '&:first-child': {
        borderBottomLeftRadius: 'lg',
      },
      '&:last-child': {
        borderBottomRightRadius: 'lg',
      },
    },
  },
  footer: {
    bg: bg.primary,
    alignItems: 'center',
    justifyContent: 'end',
    roundedBottom: 'lg',
    pt: '4',
    px: '6',
    pb: '6',
    zIndex: 1,
    borderTop: '1px solid transparent',
    '&:has(> :nth-child(2))': {
      justifyContent: 'space-between',
    },
    '.chakra-modal__body:is([data-scroll-location="middle"], [data-scroll-location="top"]) + &':
      {
        borderColor: border.primary,
      },
  },
  header: {
    bg: bg.primary,
    display: 'flex',
    flexDirection: 'column',
    gap: '2',
    px: '6',
    pt: '6',
    pb: '4',
    roundedTop: 'lg',
    fontSize: false,
    fontWeight: false,
    zIndex: 1,
    borderBottom: '1px solid transparent',
    '&:has(+ [data-scroll-location="middle"], + [data-scroll-location="bottom"])':
      {
        borderColor: border.primary,
      },
  },
  overlay: {
    bg: `rgb(${customColorsRaw.bg.overlay} / 0.5)`,
  },
});

export const ModalStyles = defineMultiStyleConfig({ baseStyle, sizes });
