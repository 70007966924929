import * as myzod from 'myzod';

import { RouteConfig } from 'src/lib/routeConfig/RouteConfig';

export const ProposeSchemaChangesModalStateFragment = new RouteConfig({
  parseSearchParams: (params) =>
    myzod
      .object({
        shouldPromptForNewSubgraphName: myzod
          .literals('true', 'false')
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const preflightOAuth2RedirectRoute = new RouteConfig({
  definition: '/explorer-oauth2',
  parseSearchParams: (params) =>
    myzod.object({}).allowUnknownKeys().parse(params),
});
