import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { upgradeRoute } from 'src/app/onboarding/components';
import { PermissionGuard } from 'src/components/permissionGuards/permissionGuard/PermissionGuard';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

const redirectLegacyGraphUpgradeQuery = gql`
  query RedirectLegacyGraphUpgradeQuery($graphId: ID!) {
    service(id: $graphId) {
      id
      account {
        id
      }
    }
  }
`;

interface Props {
  graphId: string;
}

export const RedirectLegacyGraphUpgrade = ({ graphId }: Props) => {
  const location = useLocation();
  const { data, error, loading } = useQuery<
    GraphQLTypes.RedirectLegacyGraphUpgradeQuery,
    GraphQLTypes.RedirectLegacyGraphUpgradeQueryVariables
  >(redirectLegacyGraphUpgradeQuery, {
    variables: {
      graphId,
    },
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
  });
  if (loading || !data?.service?.account?.id) {
    return (
      <PermissionGuard
        graphId={data?.service?.id}
        graphPermissions="canQueryPrivateInfo"
      />
    );
  }
  if (error) {
    throw new Error(error.message);
  }
  return (
    <Redirect
      to={upgradeRoute({
        ...location,
        pathname: '/',
      })}
    />
  );
};
