import { ApolloError, gql, useMutation } from '@apollo/client';
import {
  Alert,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@apollo/orbit';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import * as routes from 'src/app/onboarding/routes';
import { ClickableText } from 'src/components/common/clickableText/ClickableText';
import { Form } from 'src/components/common/form/Form';
import { useIdentity } from 'src/hooks/useIdentity';
import {
  checkServerErrorForPasswordFailure,
  usePasswordChecker,
} from 'src/hooks/usePasswordChecker';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { InvalidPasswordResetTokenRegex } from 'src/lib/graphqlTypes/serverErrors';

export const finalizeResetPasswordMutation = gql<
  GraphQLTypes.UI__FinalizeResetPasswordMutation,
  GraphQLTypes.UI__FinalizeResetPasswordMutationVariables
>`
  mutation UI__FinalizeResetPasswordMutation(
    $token: String!
    $password: String!
  ) {
    finalizePasswordReset(resetToken: $token, newPassword: $password)
  }
`;

const validationSchema = Yup.object().shape({
  password: Yup.string().min(8).required(),
  confirmPassword: Yup.string().min(8).required(),
  email: Yup.string().email(),
  global: Yup.string(),
});

interface Values {
  password: string;
  confirmPassword: string;
  email: string;
  global?: string;
}

export interface Props {
  initialState?: Partial<Values>;
  initialTouched?: FormikTouched<Values>;
  initialErrors?: FormikErrors<Values>;
  validateOnMount?: boolean;
}

interface PasswordError {
  password?: string;
  global?: string;
}

// returns a global error in the case that we don't recognize the type of error
// coming from the server. for weak password failures, it gets displayed below
// the password input box instead.
function getPasswordError(serverError?: string): PasswordError {
  if (serverError === undefined) {
    return {
      global:
        'Failed to update your password. Please check your password and try again.',
    };
  }

  const passwordStatus = checkServerErrorForPasswordFailure(serverError);

  if (!passwordStatus.isSuccess) {
    return { password: passwordStatus.errorMessage };
  } else if (InvalidPasswordResetTokenRegex.test(serverError)) {
    return {
      global:
        'Invalid password reset token. Try resending your forgot password request.',
    };
  } else {
    return { global: serverError };
  }
}

export const ChangePasswordPage = ({
  initialState = {},
  initialTouched = {},
  initialErrors = {},
  validateOnMount = false,
}: Props) => {
  const { me } = useIdentity();
  // Get the reset token
  const { token } = useQueryParams();

  const {
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    touched,
    setErrors,
    validateForm,
    isValid,
  } = useFormik<Yup.InferType<typeof validationSchema>>({
    initialValues: {
      password: '',
      confirmPassword: '',
      global: '',
      email: me?.email ?? '',
      ...initialState,
    },
    initialTouched,
    initialErrors: {
      password: '',
      confirmPassword: '',
      global: !token ? 'No reset token provided.' : '',
      ...initialErrors,
    },
    validate: (formValues) => {
      const newErrors: FormikErrors<Values> = {};

      if (!token) {
        newErrors.global = 'No reset token provided.';
      }

      if (!formValues.password) {
        newErrors.password = 'Please provide a password.';
      } else {
        const passwordStatus = passwordChecker(formValues.password);
        if (!passwordStatus.isSuccess) {
          newErrors.password = passwordStatus.errorMessage;
        }
      }

      if (!formValues.confirmPassword) {
        newErrors.confirmPassword = 'Please confirm your new password.';
      } else if (formValues.password !== formValues.confirmPassword) {
        newErrors.confirmPassword = "Your passwords don't match.";
      }
      return newErrors;
    },
    validationSchema,
    validateOnChange: true,
    validateOnMount,
    enableReinitialize: true,
    async onSubmit(formValues) {
      if (!token) {
        return;
      }

      let error: PasswordError | undefined;

      try {
        const result = await finalizeResetPassword({
          variables: { token, password: formValues.password },
        });
        if (result && result.errors) {
          // The mutation should only ever return one error. In the case of
          // multiple, show the first one.
          const errorMessages = result.errors
            .map((errMsg) => errMsg.message)
            .join(', ');

          error = getPasswordError(errorMessages);
        } else if (!result) {
          error = getPasswordError();
        }
      } catch (err) {
        error = getPasswordError(
          err instanceof ApolloError ? err.message : undefined,
        );
      }

      if (error !== undefined) {
        setErrors(error);
      }
    },
  });

  const [finalizeResetPassword, { loading: submitting, error, data }] =
    useMutation(finalizeResetPasswordMutation);
  const passwordChecker = usePasswordChecker();
  // when the passwordChecker is updated, trigger a re-validation
  useEffect(() => {
    validateForm();
  }, [passwordChecker, validateForm]);

  const submitted = !!(data && !error);

  if (submitted) {
    return (
      <div className="text-center">
        <div className="mb-4 text-3xl font-semibold">Password Updated!</div>
        You're all set, now continue to{' '}
        <ClickableText
          className="text-indigo-dark"
          as={<Link to={routes.login.location({})} />}
        >
          Sign in
        </ClickableText>
        .
      </div>
    );
  }

  return (
    <>
      {errors.global && (
        <Alert status="error" className="mb-6">
          {errors.global}
        </Alert>
      )}
      <div className="mb-2 text-3xl font-semibold">Change Password</div>
      <p className="mb-8">Create your new password.</p>
      <Form onSubmit={handleSubmit} noValidate>
        {/* https://www.chromium.org/developers/design-documents/create-amazing-password-forms/ recommends a hidden email/username field to help password managers */}
        <FormControl id="email" isReadOnly>
          <FormLabel className="sr-only">Email</FormLabel>
          <Input
            value={values.email}
            type="email"
            name="email"
            autoComplete="email"
            readOnly
            className="hidden"
          />
        </FormControl>
        <FormControl
          id="password"
          isRequired
          isInvalid={touched.password && !!errors.password}
          className="mb-4"
        >
          <FormLabel>Password</FormLabel>
          <Input
            isDisabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            type="password"
            name="password"
            autoComplete="new-password"
          />
          <FormErrorMessage>{errors.password}</FormErrorMessage>
        </FormControl>
        <FormControl
          className="mb-4"
          id="confirmPassword"
          isRequired
          isInvalid={touched.confirmPassword && !!errors.confirmPassword}
        >
          <FormLabel>Confirm Password</FormLabel>
          <Input
            isDisabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmPassword}
            type="password"
            name="confirmPassword"
            autoComplete="new-password"
          />
          <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          size="lg"
          isLoading={submitting}
          loadingText="Submitting"
          className="w-full"
          variant="primary"
          isDisabled={!isValid || isSubmitting}
        >
          Change password
        </Button>
      </Form>
    </>
  );
};
