import { ExternalRouter } from 'src/lib/external-router';

import Config from '../config';

export const BackendRouter = new ExternalRouter(
  Config.settings.engineBackendUrl || Config.absoluteUrl,
  {
    LoginFirstParty: 'login/apollo',
    LoginWithProvider: 'login/:provider',
    LoginWithProviderAndCallback: 'login/:provider\\?cb=:callbackUrl',
    Logout: 'logout?cb=:callbackUrl',
    SSOLogin: 'auth/sso/login?email=:email&returnToUrl=:returnToUrl',
    SsoOidcCallback: 'auth/sso/oidc/:connectionId/callback',
    SsoSamlAcsUrl: 'auth/sso/saml/:connectionId/acs',
    SsoSamlMetadata: 'auth/sso/saml/:connectionId/metadata',
    SsoSamlSigningCert: 'auth/sso/saml/:connectionId/signing_cert',
    SsoSPInitiatedLogin: 'auth/sso/:connectionId/login',
    InvoicePdf: 'billing/invoice/v2/:internalAccountId/:invoiceId',
    AuditExport: 'api/org/:accountId/audit/:auditJobId/:fileIndex',
    GraphQL: 'api/graphql',
  },
);
