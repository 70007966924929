import { listAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import { $groupSpacingX, $groupSpacingY } from '../Group/Group.style';

const { bg, border, btn, icon, text } = customColors;
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([
    ...listAnatomy.keys,
    'description',
    'divider',
  ]);

const baseStyle = definePartsStyle({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    '&[data-interactive="true"]': {
      gap: 3,
    },
  },
  description: {
    color: text.secondary,
  },
  icon: {
    w: 4,
    h: 4,
    lineHeight: false,
    display: 'block',
    marginEnd: false,
    verticalAlign: false,
    color: icon.secondary,
    '[aria-disabled="true"] &': {
      color: icon.disabled,
    },
  },
  item: {
    [$groupSpacingX.variable]: 'space.3',
    [$groupSpacingY.variable]: 'space.1',
    display: 'flex',
    alignItems: 'center',
    py: 2,
    px: 4,
    gap: 3,
    color: text.primary,
    fontSize: 'base',
    fontFamily: 'body',
    fontWeight: 'normal',
    lineHeight: 'base',
    '&:has(.list-item-description)': {
      alignItems: 'start',
      '& .chakra-icon': {
        mt: 1,
      },
    },
    '[data-interactive="true"] &': {
      cursor: 'pointer',
      rounded: 'md',
      transition: 'background 0.2s ease-out, font-weight 0.1s ease-out',
      '&:focus-visible:not([aria-disabled="true"])': {
        outlineColor: border.focused,
      },
      '&:hover:not([aria-disabled="true"])': {
        bg: btn['secondary-hover'],
        fontWeight: 'semibold',
      },
      _disabled: {
        cursor: 'not-allowed',
        color: text.disabled,
        bg: bg.disabled,
      },
      _selected: {
        bg: bg.neutral,
        fontWeight: 'semibold',
      },
    },
  },
  divider: {
    p: 0,
  },
});

export const ListStyles = defineMultiStyleConfig({ baseStyle });
