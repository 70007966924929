import { Progress, Text } from '@apollo/orbit';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

import { format } from 'src/lib/format';
import { getDaysRemainingOnTrial } from 'src/lib/getDaysRemainingOnTrial';
import { Timestamp } from 'src/lib/graphqlTypes/customScalarTypes';

interface Props {
  trialStartDate: Timestamp['output'];
  trialEndDate: Timestamp['output'];
  className?: string;
}
export const TrialDurationTracker = ({
  trialStartDate,
  trialEndDate,
  className,
}: Props) => {
  const startDate = moment(trialStartDate).startOf('date');
  const endDate = moment(trialEndDate).endOf('date');
  const trialLength = endDate.diff(startDate, 'days');
  const daysRemaining = getDaysRemainingOnTrial(trialEndDate);
  const daysUsed = trialLength - daysRemaining;

  return daysRemaining >= 0 ? (
    <div className={classnames('rounded', className)}>
      <Text className="mb-1" variant="label">
        {daysRemaining} {format.pluralize(daysRemaining, 'day')} left in your
        free trial
      </Text>
      <Progress value={daysUsed} max={trialLength} />
    </div>
  ) : null;
};
