import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Button } from '@apollo/orbit';
import { GraphQLError } from 'graphql';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import * as routes from 'src/app/onboarding/routes';
import { Loading } from 'src/components/common/loading/Loading';
import { NotFound } from 'src/components/common/notFound/NotFound';
import { useIdentity } from 'src/hooks/useIdentity';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

const acceptInvitationMutation = gql<
  GraphQLTypes.UI__AcceptInvitationMutation,
  GraphQLTypes.UI__AcceptInvitationMutationVariables
>`
  mutation UI__AcceptInvitationMutation($orgId: ID!, $joinToken: String!) {
    joinAccount(accountId: $orgId, joinToken: $joinToken) {
      id
    }
  }
`;

function isSSOJoinError(error: GraphQLError) {
  return error.extensions?.code === 'SSO_USERS_CANNOT_JOIN_ORGANIZATIONS';
}

function isInvalidTokenError(error: GraphQLError) {
  return error.message.includes('Invalid token');
}

export const AccountInvitationHandler = () => {
  const { orgId, joinToken } = useRouteParams(routes.inviteLanding);

  // Require the identity, but don't fail if this doesn't go right. That'll be
  // handled elsewhere.
  const identity = useIdentity();
  const client = useApolloClient();
  const [acceptInvitation, { data, error }] = useMutation(
    acceptInvitationMutation,
    {
      onCompleted() {
        client.cache.evict({
          id: client.cache.identify({
            __typename: identity.me?.__typename,
            id: identity.me?.id,
          }),
        });

        client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'me',
        });

        client.cache.gc();
      },
      context: appLinkContext({
        ignoreSentryReporting: [isSSOJoinError, isInvalidTokenError],
      }),
    },
  );

  // when first mounted, fire a mutation that accepts the invitation.
  // passing [] as the 2nd arg to useEffect means it should only ever
  // run once (like componentDidMount).
  useEffect(() => {
    acceptInvitation({
      variables: {
        orgId,
        joinToken,
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // if anything goes wrong, fall back to a NotFound page.
  if (error) {
    console.warn(error); // eslint-disable-line no-console
    return (
      <NotFound
        image="astronaut"
        title="Ugh, sorry about this."
        subtitle={
          <div>
            {error.graphQLErrors.some(isSSOJoinError) ? (
              <div>
                Enterprise SSO users cannot join additional organizations.
              </div>
            ) : (
              <div>
                Looks like the invite link you’re using has expired. Reach out
                to the sender and ask for a new invite.
              </div>
            )}
            <Button as={Link} to="/" className="mt-4">
              Go to Studio
            </Button>
          </div>
        }
      />
    );
  }
  // once our mutation has succeeded, redirect to the org
  // that was just joined
  if (data && data.joinAccount)
    return <Redirect to={`/org/${data.joinAccount.id}`} />;

  // while the mutation is running, just show the loading spinner.
  return <Loading />;
};
