import { gql, useMutation } from '@apollo/client';
import { AlertBanner, Button, Spinner } from '@apollo/orbit';
import React, { useEffect, useState } from 'react';

import { useIdentity } from 'src/hooks/useIdentity';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

interface Props {
  __test?: {
    forceSending?: boolean;
    forceShowSent?: boolean;
  };
}

export const NoVerifiedEmailBanner = ({ __test }: Props) => {
  const { me, meLoading, isUser } = useIdentity();
  const [showSent, setShowSent] = useState<boolean>(false);
  const [resendVerificationEmail, { loading: sending }] = useMutation<
    GraphQLTypes.UI__userResendVerificationEmail,
    GraphQLTypes.UI__userResendVerificationEmailVariables
  >(userResendVerificationEmailMutation, {
    onCompleted: () => setShowSent(true),
  });

  useEffect(() => {
    if (showSent) {
      // We want to show the 'email sent' banner for four seconds
      const timeout = setTimeout(() => setShowSent(false), 4000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [showSent]);

  // Don't show if query is loading, we are not identified as a user,
  // email is null, or email is verified
  if (
    !me ||
    meLoading ||
    !isUser ||
    me.email === null ||
    me.emailVerified ||
    me.type === GraphQLTypes.UserType.SSO
  ) {
    return null;
  }

  if (showSent || __test?.forceShowSent) {
    return (
      <AlertBanner status="info">
        Email verification resent. Please click the link in the email to verify
        your email address.
      </AlertBanner>
    );
  }

  const { id: userId } = me;
  const handleResend = () =>
    resendVerificationEmail({
      variables: { userId },
    });

  return (
    <AlertBanner status="warning">
      To continue using Studio, please check your email and follow the link to
      verify your email address.{' '}
      {sending || __test?.forceSending ? (
        <Spinner
          data-testid="no-verified-email-banner-loading"
          size="tiny"
          className="mr-2"
          variant="neutral"
        />
      ) : (
        <Button
          variant="buttonLink"
          className="text-inherit"
          onClick={handleResend}
        >
          Resend verification email.
        </Button>
      )}
    </AlertBanner>
  );
};

const userResendVerificationEmailMutation = gql`
  mutation UI__userResendVerificationEmail($userId: ID!) {
    user(id: $userId) {
      resendVerificationEmail
    }
  }
`;
