import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import IconInfo from '@apollo/icons/default/IconInfo.svg';
import IconSuccessSolid from '@apollo/icons/default/IconSuccessSolid.svg';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from '@apollo/orbit';
import React from 'react';

import { getInternalOrgIdQuery } from 'src/app/account/generalOrgQueries/GeneralOrgQueries';
import { ClickableText } from 'src/components/common/clickableText/ClickableText';
import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { useLDFlag } from 'src/lib/launchDarkly';
import { DocsRouter } from 'src/lib/routers';

import { ConvertAccountModalFooter } from './ConvertAccountModalFooter';
import { convertAccountMutation } from './convertAccountMutation';
import { NEW_PLAN_FEATURES } from './planFeatures';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  accountId: string | undefined;
}

export const ConvertAccountModal = ({
  isOpen,
  onClose,
  accountId = '',
}: Props) => {
  const client = useApolloClient();
  const showSelfManagedForServerless = !useLDFlag(
    'astro-self-serve-enterprise-phase-1',
  );
  const { data } = useQuery(getInternalOrgIdQuery, {
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    variables: { accountId },
  });
  const internalAccountId = data?.organization?.internalID || '';
  const [convertAccount, { error, loading }] = useMutation(
    convertAccountMutation,
    {
      variables: {
        internalAccountId,
      },
      onCompleted: () => {
        client.cache.evict({
          id: client.cache.identify({
            __typename: data?.organization?.__typename,
            id: data?.organization?.id,
          }),
          fieldName: 'currentSubscription',
        });
        client.cache.evict({
          id: client.cache.identify({
            __typename: data?.organization?.__typename,
            id: data?.organization?.id,
          }),
          fieldName: 'currentPlan',
        });
        client.cache.evict({
          id: client.cache.identify({
            __typename: data?.organization?.__typename,
            id: data?.organization?.id,
          }),
          fieldName: 'currentSubscription',
        });
        client.cache.evict({
          id: client.cache.identify({
            __typename: data?.organization?.__typename,
            id: data?.organization?.id,
          }),
          fieldName: 'currentPlan',
        });

        client.cache.gc();
        onClose();
      },
    },
  );

  const handleButtonClick = () => {
    convertAccount();
  };

  // TODO(taylor): make these numbers dynamic
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        {error && <ErrorMessage className="m-6 mb-0" error={error} />}
        <ModalHeader>
          <ModalTitle>
            Convert your account to the Serverless (Free) plan
          </ModalTitle>
          <ModalDescription>
            The Serverless (Free) plan includes additional new features that are
            not available on your current plan:
          </ModalDescription>
        </ModalHeader>
        <ModalBody>
          <ul className="flex w-full flex-col gap-3">
            <li className="flex items-center gap-2 align-middle">
              <>
                <IconSuccessSolid className="my-auto text-icon-success" />
                <span>
                  Create{' '}
                  <ClickableText
                    as="a"
                    href={DocsRouter.path('CloudRoutingOverview')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-link"
                  >
                    serverless supergraphs
                  </ClickableText>
                </span>
              </>
            </li>
            {NEW_PLAN_FEATURES.map((feature) => (
              <li
                className="flex items-center gap-2 align-middle"
                key={feature}
              >
                <IconSuccessSolid className="my-auto text-icon-success" />
                {feature}
              </li>
            ))}
          </ul>
          {showSelfManagedForServerless && (
            <span className="flex flex-col pr-4">
              <span className="flex items-center text-lg font-semibold">
                What will be different?
                <Tooltip
                  label="You can easily track your operation limit and we will notify you ahead of time if it is predicted to hit the limit based on usage, instead of instantly stopping all traffic."
                  className="flex"
                >
                  <IconInfo className="ml-1" />
                </Tooltip>
              </span>
              <span>
                The Serverless (Free) plan is limited to 5 million
                operations/month reported from self-managed graphs, as opposed
                to the 25 million limit on your current plan. Converting to this
                new plan is a one way action for your account, but nothing about
                your existing graphs will change.
              </span>
            </span>
          )}
        </ModalBody>
        <ConvertAccountModalFooter
          planName="Serverless (Free)"
          onClose={onClose}
          loading={loading}
          handleButtonClick={handleButtonClick}
        />
      </ModalContent>
    </Modal>
  );
};
