import { Link as OrbitLink } from '@apollo/orbit';
import React from 'react';
import { Link } from 'react-router-dom';

import { EventCategory } from '../analytics';
import { Config } from '../config/config';
import { relativeURL } from '../relativeURL';

export const A = ({
  title,
  href,
  className,
  children,
  analyticsCategory,
  analyticsLabelPrefix,
}: {
  title: string;
  href: string | null;
  className: string | undefined;
  children: React.ReactNode;
  analyticsCategory?: EventCategory;
  analyticsLabelPrefix?: string;
}) => {
  if (!href) {
    return <span className={className}>{children}</span>;
  }
  const isInternalLink = href.indexOf(Config.absoluteUrl) === 0;
  const isInternalAnchor = href.startsWith('#');

  return isInternalLink ? (
    <Link
      className={className}
      to={relativeURL(href)}
      title={title}
      data-analytics-label={
        analyticsLabelPrefix
          ? `${analyticsLabelPrefix}: Internal link`
          : undefined
      }
      data-analytics-category={analyticsCategory}
    >
      {children}
    </Link>
  ) : isInternalAnchor ? (
    <OrbitLink
      className={className}
      href={href}
      title={title}
      rel="noreferrer"
      data-analytics-label={
        analyticsLabelPrefix
          ? `${analyticsLabelPrefix}: Internal anchor`
          : undefined
      }
      data-analytics-category={analyticsCategory}
    >
      {children}
    </OrbitLink>
  ) : (
    <OrbitLink
      className={className}
      href={href}
      title={title}
      target="_blank"
      rel="noreferrer"
      data-analytics-label={
        analyticsLabelPrefix
          ? `${analyticsLabelPrefix}: External link`
          : undefined
      }
      data-analytics-category={analyticsCategory}
    >
      {children}
    </OrbitLink>
  );
};
