import { anatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import { WizardStepStyles } from '../WizardStep/WizardStep.style';

const { text } = customColors;
const wizardAnatomy = anatomy('wizard').parts('step', 'stepper', 'body');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(wizardAnatomy.keys);

const step = WizardStepStyles;
const baseStyle = definePartsStyle({
  step,
  stepper: {
    stepper: {
      height: '100%',
    },
    title: {
      color: text.secondary,
    },
  },
  container: { display: 'flex', flex: 1, height: '100%', gap: 12 },
  stepContainer: {
    display: 'flex',
    flex: 1,
    background: customColors.bg.secondary,
    borderRadius: 7,
    height: '100%',
    padding: 6,
  },
});

export const WizardStyle = defineMultiStyleConfig({
  baseStyle,
});
