import {
  Progress as ChakraProgress,
  ProgressProps as ChakraProgressProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type ProgressProps = OmitChakraProps<
  ChakraProgressProps,
  'styleConfig' | 'colorScheme' | 'orientation' | 'size'
>;

export const Progress = forwardRef<ProgressProps, 'div'>((props, ref) => {
  return (
    <ChakraProgress
      {...props}
      data-overlimit={
        typeof props.value === 'number' &&
        typeof props.max === 'number' &&
        props.value > props.max
          ? true
          : undefined
      }
      ref={ref}
    />
  );
});
