import * as Sentry from '@sentry/react';

import { ampli } from './amplitude';

/* This solution relies on the beacon transport mechanism to send messages to Amplitude API even when the user has unloaded the page.
 * This solution is outlined here: https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-1
 * A browser reference to the underlying feature: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
 */

export const trackExternalLink = (fn: Function) => {
  const amplitude = ampli.client;
  amplitude.setTransport('beacon');
  amplitude.flush();
  try {
    fn();
  } catch (e) {
    const msg = (e as Error).message
      ? (e as Error).message
      : 'Unexpected tracking error';
    Sentry.captureMessage(msg, {
      level: 'warning',
    });
  }
  amplitude.setTransport('xhr');
  amplitude.flush();
};
