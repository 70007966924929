import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { cssVar, defineStyle } from '@chakra-ui/styled-system';

import { customColors } from '../../theme/customColors';
import { $groupSpacingX, $groupSpacingY } from '../Group/Group.style';

const { bg, border, text } = customColors;
const customParts = ['title', 'headerControl'] as const;
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([...cardAnatomy.keys, ...customParts]);

const $bg = cssVar('card-bg');
const $radius = cssVar('card-radius');
const $border = cssVar('card-border-width');
const $borderColor = cssVar('card-border-color');
const $outerPaddingY = cssVar('card-outer-padding-y');
const $outerPaddingX = cssVar('card-outer-padding-x');

const baseStyle = definePartsStyle({
  header: {
    [$groupSpacingY.variable]: 'space.2',
    [$groupSpacingX.variable]: 'space.2',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    padding: false,
    pt: $outerPaddingY.reference,
    pb: 4,
    px: $outerPaddingX.reference,
    '&:last-child': {
      pb: $outerPaddingY.reference,
    },
    '[data-is-divided=true] > &:not(:last-child)': {
      borderBottom: '1px solid',
      borderBottomColor: border.primary,
    },
    '&:has(> .chakra-button__group, > .chakra-button, > .chakra-form-control, > .orbit-card__header-control)':
      {
        display: 'grid',
        gridColumnGap: 6,
        gridTemplateColumns: '1fr auto',
        '& > .chakra-button__group, & > .chakra-button, & > .chakra-form-control, & > .orbit-card__header-control':
          {
            gridRow: '1 / span calc(var(--child-count) - 1)',
            gridColumnStart: '2',
            alignSelf: 'start',
          },
        '& > :not(.chakra-button__group, .chakra-button, .chakra-form-control, .orbit-card__header-control)':
          {
            gridColumnStart: '1',
          },
        '& > .chakra-form-control': {
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'start',
        },
        '& .chakra-form__label': {
          fontSize: 'sm',
          lineHeight: 'sm',
          fontWeight: 'semibold',
        },
      },
  },
  headerControl: {
    display: 'flex',
    gap: 3,
    alignItems: 'center',
  },
  body: {
    color: text.primary,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    padding: false,
    pt: 4,
    pb: 4,
    px: $outerPaddingX.reference,
    '&:last-child': {
      pb: $outerPaddingY.reference,
    },
    '&:has(> .chakra-table__container, > .chakra-table)': {
      p: 0,
      overflowY: 'auto',
    },
    '&:first-of-type': {
      '&:has(.chakra-table__container)': {
        pt: 0,
      },
      pt: $outerPaddingY.reference,
    },
  },
  title: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  footer: {
    '[data-is-divided=true] > &:not(:first-child)': {
      borderTop: '1px solid',
      borderTopColor: border.primary,
    },
    '&:has(.orbit-pagination__container)': {
      p: 0,
    },
    '.chakra-card__body:has(.chakra-table__container:only-child, .chakra-table:only-child) + &:has(.orbit-pagination__container)':
      {
        borderTop: '1px solid',
        borderColor: border.primary,
      },
  },
});

const tableOverflowContainer = defineStyle({
  '&:has(.chakra-card__body > .chakra-table__container, .chakra-card__body > .chakra-table)':
    {
      overflow: 'hidden',
    },
});

const tableBorderOverrides = defineStyle({
  '[data-is-divided=true] &:not(last-child) .chakra-table:last-child tr:last-child td':
    {
      borderBottom: 'none',
    },
  '&:last-child .chakra-table:last-child tr:last-child td': {
    borderBottom: 'none',
  },
  '&:has(+ .chakra-card__footer > .orbit-pagination__container) .chakra-table:last-child tr:last-child td':
    {
      borderBottom: 'none',
    },
});

const variantOutline = definePartsStyle({
  container: {
    [$bg.variable]: bg.primary,
    [$borderColor.variable]: border.primary,
    [$radius.variable]: '0.5rem',
    [$border.variable]: '1px',
    [$outerPaddingY.variable]: 'space.6',
    [$outerPaddingX.variable]: 'space.6',
    color: text.primary,
    boxShadow: 'cards',
    ...tableOverflowContainer,
  },
  body: {
    ...tableBorderOverrides,
  },
});

const variantFilled = definePartsStyle({
  container: {
    [$bg.variable]: bg.secondary,
    [$borderColor.variable]: border.primary,
    [$radius.variable]: '0.5rem',
    [$border.variable]: '1px',
    [$outerPaddingY.variable]: 'space.6',
    [$outerPaddingX.variable]: 'space.6',
    color: text.primary,
    ...tableOverflowContainer,
  },
  body: {
    ...tableBorderOverrides,
  },
});

const variantUnstyled = definePartsStyle({
  container: {
    [$bg.variable]: false,
    [$outerPaddingY.variable]: 'space.0',
    [$outerPaddingX.variable]: 'space.0',
    backgroundColor: false,
    boxShadow: false,
  },
});

export const CardStyles = defineMultiStyleConfig({
  baseStyle,
  variants: {
    outline: variantOutline,
    unstyled: variantUnstyled,
    filled: variantFilled,
  },
  defaultProps: {
    variant: 'outline',
  },
});
