import { gql, useQuery } from '@apollo/client';
import IconCheck from '@apollo/icons/default/IconCheck.svg';
import IconChevronDown from '@apollo/icons/default/IconChevronDown.svg';
import IconHome from '@apollo/icons/default/IconHome.svg';
import IconLock from '@apollo/icons/default/IconLock.svg';
import IconLogout from '@apollo/icons/default/IconLogout.svg';
import IconPlus from '@apollo/icons/default/IconPlus.svg';
import IconWarning from '@apollo/icons/default/IconWarning.svg';
import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuContent,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@apollo/orbit';
import { IconMarioFlower } from '@apollo/space-kit/icons/IconMarioFlower';
import { LocationDescriptor } from 'history';
import { partition } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import * as orgRoutes from 'src/app/account/routes';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { useOrgCreationLocation } from 'src/hooks/useOrgCreationLocation/useOrgCreationLocation';
import {
  Maybe,
  OrgSwitcherQuery,
  OrgSwitcherQueryVariables,
  OrgSwitcherQuery_me_User,
  OrgSwitcherQuery_me_User_memberships,
  OrgSwitcherQuery_me_User_memberships_account,
} from 'src/lib/graphqlTypes/types';

export const orgSwitcherQuery = gql<
  OrgSwitcherQuery,
  OrgSwitcherQueryVariables
>`
  query OrgSwitcherQuery {
    me {
      id
      ... on User {
        id
        isSsoV2
        email
        fullName
        avatarUrl
        internalAdminRole
        type
        memberships {
          role: permission
          user {
            id
          }
          account {
            id
            name
            avatarUrl(size: 40)
            currentPlan {
              id
              tier
              name
            }
            isLocked
          }
        }
      }
    }
  }
`;

export const OrgSwitcher = ({
  collapseOrgName,
}: {
  collapseOrgName: boolean;
}) => {
  const [visitedLockedOrgs, setVisistedLockedOrgs] = useState<
    Record<string, boolean>
  >({});
  const [currentAccountId] = useCurrentAccountId();

  const { data, loading } = useQuery<
    OrgSwitcherQuery,
    OrgSwitcherQueryVariables
  >(orgSwitcherQuery);

  // Route used for creating an org for the first time
  const createNewOrgLocation = useOrgCreationLocation();
  // Default route for creating a new org after initial org creation.
  const isSSO: boolean =
    data?.me?.__typename === 'User' && data.me.type === 'SSO';

  const isSsoV2 = data?.me?.__typename === 'User' && data.me.isSsoV2;

  const { orgList } = useMemo(() => {
    const [unlockedOrgsList = [], lockedOrgsList = []] =
      data?.me?.__typename === 'User'
        ? partition(data.me.memberships, ['account.isLocked', false])
        : [[], []];
    return {
      orgList: [
        ...unlockedOrgsList,
        ...lockedOrgsList,
      ] as OrgSwitcherQuery_me_User_memberships[],
    };
  }, [data]);

  useEffect(() => {
    if (!currentAccountId) return;

    const currentAccount = orgList.find(
      (org) => org.account.id === currentAccountId,
    );

    if (
      currentAccount?.account?.isLocked &&
      !visitedLockedOrgs[currentAccountId]
    ) {
      setVisistedLockedOrgs((prev) => ({
        ...prev,
        [currentAccountId]: true,
      }));
    }
  }, [currentAccountId, orgList, visitedLockedOrgs]);

  if (loading || !data?.me || data?.me.__typename !== 'User') return null;
  const org = [...data.me.memberships].find(
    (m) => m.account.id === currentAccountId,
  )?.account;
  const internalAdminRole: OrgSwitcherQuery_me_User['internalAdminRole'] =
    data.me.internalAdminRole;
  const isSuperUser: boolean = !org && !!internalAdminRole;

  return (
    <>
      {
        // Someone with no orgs should just see a create an org button
      }
      {data.me.memberships.length === 0 && !isSuperUser ? (
        <CreateOrganizationButton />
      ) : (
        <OrgSwitcherPopover
          orgList={orgList}
          visitedLockedOrgs={visitedLockedOrgs}
          currentAccountId={currentAccountId}
          isSuperUser={isSuperUser}
          isSSO={isSSO}
          createNewOrgLocation={createNewOrgLocation}
          collapseOrgName={collapseOrgName}
          org={org}
          internalAdminRole={internalAdminRole}
          isSsoV2={isSsoV2}
        />
      )}
    </>
  );
};

const CreateOrganizationButton = () => {
  // Route used for creating an org for the first time
  const createNewOrgLocation = useOrgCreationLocation();

  return (
    <Button
      variant="hidden"
      leftIcon={<IconPlus />}
      as={Link}
      to={createNewOrgLocation}
    >
      Create an organization
    </Button>
  );
};

const OrgSwitcherPopover = ({
  orgList,
  visitedLockedOrgs,
  currentAccountId,
  isSuperUser,
  isSSO,
  createNewOrgLocation,
  collapseOrgName,
  org,
  internalAdminRole,
  isSsoV2,
}: {
  orgList: OrgSwitcherQuery_me_User_memberships[];
  visitedLockedOrgs: Record<string, boolean>;
  currentAccountId: Maybe<string>;
  isSuperUser: boolean;
  isSSO: boolean;
  createNewOrgLocation: {
    pathname: string;
    search: string;
    state: unknown;
    hash: string;
    key?: string | undefined;
  };
  collapseOrgName: boolean;
  org: Maybe<OrgSwitcherQuery_me_User_memberships_account> | undefined;
  internalAdminRole: OrgSwitcherQuery_me_User['internalAdminRole'];
  isSsoV2: boolean;
}) => {
  const menuContent = getMenuLabelContent({
    collapseOrgName,
    org,
    isSuperUser,
    internalAdminRole,
  });
  return (
    <>
      <Menu isLazy placement="bottom-start">
        <MenuButton
          as={Button}
          variant="hidden"
          leftIcon={menuContent?.icon}
          rightIcon={<IconChevronDown />}
          size="sm"
        >
          {menuContent?.label}
        </MenuButton>
        <MenuContent>
          <MenuList>
            {orgList.map((m) => (
              <AccountLink
                key={m.account.id}
                isVisitedLockedOrg={!!visitedLockedOrgs[m.account.id]}
                account={m.account}
                currentAccountId={currentAccountId}
              />
            ))}
            <OrgSwitcherAction
              isSuperUser={isSuperUser}
              isSSO={isSSO}
              isSSOv2={isSsoV2}
              createNewOrgLocation={createNewOrgLocation}
            />
          </MenuList>
        </MenuContent>
      </Menu>
    </>
  );
};

const AccountLink = ({
  isVisitedLockedOrg,
  account,
  currentAccountId,
}: {
  isVisitedLockedOrg: boolean;
  account: OrgSwitcherQuery_me_User_memberships_account;
  currentAccountId: Maybe<string>;
}) => {
  return (
    <MenuItem
      as={Link}
      key={account.id}
      to={orgRoutes.graphs.location({ orgId: account.id })}
      icon={
        <Avatar
          name={account.name}
          src={account.avatarUrl || undefined}
          size="xs"
          variant="org"
        />
      }
      rightIcon={
        currentAccountId === account.id ? (
          <IconCheck />
        ) : account.isLocked ? (
          isVisitedLockedOrg ? (
            <IconLock />
          ) : (
            <IconWarning className="text-icon-warning" />
          )
        ) : null
      }
    >
      {account.name}
    </MenuItem>
  );
};

/**
 * Super users are still logged in as themselves, so they can't
 * create orgs for folks
 */
const SuperUserAction = () => (
  <MenuItem as={Link} to="/" icon={<IconHome />}>
    Return to your account
  </MenuItem>
);

/**
 * SSO users are not allowed to create organizations, because
 * they can only be in their SSO-synced organization.
 *
 * If the user is using SSOv2 (internal apollo SSO, they should be directed to login to other orgs via their IdP. See `SsoV2UserAction` instead)
 */
const SSOUserAction = () => (
  <MenuItem as={Link} to="/logout" icon={<IconLogout />}>
    Logout to switch organizations.
  </MenuItem>
);

const CreateOrgAction = ({
  createNewOrgLocation,
}: {
  createNewOrgLocation: LocationDescriptor<unknown>;
}) => (
  <MenuItem as={Link} to={createNewOrgLocation} icon={<IconPlus />}>
    Create a new organization
  </MenuItem>
);

/**
 * Wrapping in a forward ref to allow this to be passed as a reference to the popover
 * https://stackoverflow.com/a/62648323
 */
const getMenuLabelContent = ({
  collapseOrgName,
  org,
  isSuperUser,
  internalAdminRole,
}: {
  collapseOrgName: boolean;
  org: Maybe<OrgSwitcherQuery_me_User_memberships_account> | undefined;
  isSuperUser: boolean;
  internalAdminRole: OrgSwitcherQuery_me_User['internalAdminRole'];
}) => {
  return org
    ? {
        icon: (
          <Avatar
            name={org.name}
            src={org.avatarUrl || undefined}
            size="xs"
            variant="org"
          />
        ),
        label: !collapseOrgName && org.name,
      }
    : isSuperUser
    ? {
        icon: <IconMarioFlower />,
        label: !collapseOrgName && internalAdminRole && internalAdminRole,
      }
    : null;
};

type OrgSwitcherActionProps = {
  isSuperUser: boolean;
  isSSO: boolean;
  isSSOv2: boolean;
  createNewOrgLocation: LocationDescriptor<unknown>;
};
const OrgSwitcherAction = ({
  isSuperUser,
  isSSO,
  isSSOv2,
  createNewOrgLocation,
}: OrgSwitcherActionProps) => {
  // If they are SSOv2 users, they do not have a special action.
  if (isSSOv2) return null;

  // Super users are still logged in as themselves, so they can't
  // create orgs for folks
  if (isSuperUser)
    return (
      <>
        <MenuDivider />
        <SuperUserAction />
      </>
    );
  // SSO users are not allowed to create organizations, because
  // they can only be in their SSO-synced organization.
  if (isSSO)
    return (
      <>
        <MenuDivider />
        <SSOUserAction />
      </>
    );
  return (
    <>
      <MenuDivider />
      <CreateOrgAction createNewOrgLocation={createNewOrgLocation} />
    </>
  );
};
