import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

export const linkBaseStyle = defineStyle({
  gap: 2,
  fontFamily: 'body',
  fontWeight: 'semibold',
  alignItems: 'center',
  display: 'inline-flex',
  width: 'fit-content',
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
  _hover: {
    _disabled: {
      color: customColors.text.disabled,
    },
  },
  _active: {
    boxShadow: 'none',
  },
  _disabled: {
    color: customColors.text.disabled,
  },
  _focus: {
    boxShadow: 'unset',
  },
  _focusVisible: {
    outlineColor: customColors.border.focused,
    borderRadius: '4px',
    boxShadow: 'unset',
  },
});

export const LinkStyles = defineStyleConfig({
  baseStyle: linkBaseStyle,
  sizes: {},
});
