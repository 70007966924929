import { gql, useMutation, useQuery } from '@apollo/client';
import { ExecutionResult } from 'graphql';
import { useCallback } from 'react';

import { Exact, InputMaybe } from 'src/lib/graphqlTypes/types';

import { ignorePermissionsErrors } from '../lib/apollo/catchErrors';
import { appLinkContext } from '../lib/apollo/link';
import { GraphQLTypes } from '../lib/graphqlTypes';

const mutation = gql<
  GraphQLTypes.SetCurrentAccountIdMutation,
  GraphQLTypes.SetCurrentAccountIdMutationVariables
>`
  mutation SetCurrentAccountIdMutation($currentAccountId: ID) {
    setCurrentAccountId(currentAccountId: $currentAccountId) @client
  }
`;

const currentAccountIdQuery = gql<
  GraphQLTypes.CurrentAccountIdQuery,
  GraphQLTypes.CurrentAccountIdQueryVariables
>`
  query CurrentAccountIdQuery {
    currentAccountId @client
  }
`;

export function useCurrentAccountId() {
  const { data: currentAccountData } = useQuery<
    GraphQLTypes.CurrentAccountIdQuery,
    GraphQLTypes.CurrentAccountIdQueryVariables
  >(currentAccountIdQuery, {
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
  });
  // This hook is called to sync the apollo cache with a known value. While we
  // can compare the apollo cache to the value we expect and call this mutation
  // when they are out of sync, the mutations may be async, causing the mutation
  // to be called many times before the cache gets updated. This can (and does)
  // cause React to throw errors about too many re-renders. In order to mitigate
  // this, we use an optimistic update to update the Apollo cache immediately so
  // future checks against the cache will show it to be correct and, therefore,
  // will not attempt to call this mutation repeatedly.
  const [setCurrentAccountId] = useMutation<
    GraphQLTypes.SetCurrentAccountIdMutation,
    GraphQLTypes.SetCurrentAccountIdMutationVariables
  >(mutation, {
    optimisticResponse: ({
      currentAccountId,
    }: Exact<{ currentAccountId?: InputMaybe<string> | undefined }>) => ({
      setCurrentAccountId: currentAccountId ?? null,
    }),
    update(
      store,
      mutationResult: ExecutionResult<GraphQLTypes.SetCurrentAccountIdMutation>,
    ) {
      store.writeQuery({
        query: currentAccountIdQuery,
        data: {
          currentAccountId: mutationResult.data?.setCurrentAccountId ?? null,
        },
      });
    },
  });

  const memoizedSetCurrentAccountId = useCallback(
    (id: string | null) =>
      setCurrentAccountId({ variables: { currentAccountId: id } }),
    [setCurrentAccountId],
  );

  return [
    currentAccountData ? currentAccountData.currentAccountId : null,
    memoizedSetCurrentAccountId,
  ] as const;
}
