import { useLDFlag } from './launchDarkly';

export const useShouldEnableFed2Onboarding = () =>
  useLDFlag('pulsar_fed2Onboarding');

export const usePulsarShowFieldsL1Metrics = () =>
  useLDFlag('pulsar_displayListAnalyticsLargeSchemas');

export const usePulsarCommercialEntitlementsBanners = () =>
  useLDFlag('operations-l1-commercial-router-entitlements');

export const useOrionFedAlpha = () => useLDFlag('orion_fed_alpha');

export const useShowCompositionRules = () =>
  useLDFlag('orion-show-composition-rules');

export const useAstroNewSSOFlow = () => useLDFlag('astro-new-sso-flow');

export const useUnifiedGraphOnboarding = () =>
  useLDFlag('betelgeuse-unified-onboarding');
