import React, { useEffect } from 'react';

import { useRouteParams } from 'src/hooks/useRouteParams';

import {
  useLocalStorageGraphIdentifier,
  usePerGraphIdentifierLocalStorage,
} from '../graph/explorerPage/hooks/usePerGraphIdentifierLocalStorage';
import { embeddableExplorerRouteConfig } from '../graph/routes';

import {
  EmbeddableExplorerConfig,
  STABLE_DEFAULT_EMBEDDABLE_EXPLORER_CONFIG,
} from './initialConfigValues';

/**
 * @returns The locally stored config options from the query params passed to
 * studio-ui from the EmbeddedExplorer class
 *
 * Why can't we just use the query params directly? In case the url is modified
 * or cleared, by adding a modal or pushing to it etc, we don't want the config
 * to be washed away. On the first render, we store the config from query params
 * in local storage, and then we use this in our logic from then on.
 */
export const useEmbeddableExplorerConfig = (): EmbeddableExplorerConfig => {
  // We only care about the query params on the first render,
  // after the first render they are unreliable. They could have been wiped,
  // a query param of the same name might have been modified by another page etc.
  // We only want to update the local storage config values on the first render
  const [hasCompletedFirstRender, setFirstRenderComplete] =
    React.useState(false);

  const graphIdentifier = useLocalStorageGraphIdentifier({
    shouldNamespaceSandboxByEndpoint: false,
    shouldOnlyStoreForRegisteredGraphs: false,
  });

  const [embeddableExplorerConfigOptions, setEmbeddableExplorerConfigOptions] =
    usePerGraphIdentifierLocalStorage({
      graphIdentifier,
      key: 'embeddableExplorerConfigOptions',
      stableInitialValue: STABLE_DEFAULT_EMBEDDABLE_EXPLORER_CONFIG,
    });
  const {
    docsPanelState,
    showHeadersAndEnvVars,
    theme,
    shouldShowGlobalHeader,
    parentSupportsSubscriptions,
    runTelemetry,
    version,
  } = useRouteParams(embeddableExplorerRouteConfig);

  useEffect(() => {
    if (!hasCompletedFirstRender) {
      const configWithDefaultsFromQueryParams = {
        showHeadersAndEnvVars:
          typeof showHeadersAndEnvVars === 'string'
            ? showHeadersAndEnvVars === 'true'
            : showHeadersAndEnvVars === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_EXPLORER_CONFIG.showHeadersAndEnvVars,
        shouldShowGlobalHeader:
          typeof shouldShowGlobalHeader === 'string'
            ? shouldShowGlobalHeader === 'true'
            : shouldShowGlobalHeader === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_EXPLORER_CONFIG.shouldShowGlobalHeader,
        parentSupportsSubscriptions:
          typeof parentSupportsSubscriptions === 'string'
            ? parentSupportsSubscriptions === 'true'
            : parentSupportsSubscriptions === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_EXPLORER_CONFIG.parentSupportsSubscriptions,
        runTelemetry:
          typeof runTelemetry === 'string'
            ? runTelemetry === 'true'
            : runTelemetry === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_EXPLORER_CONFIG.runTelemetry,
        docsPanelState,
        version,
        theme,
      };

      setEmbeddableExplorerConfigOptions(configWithDefaultsFromQueryParams);
    }
    setFirstRenderComplete(true);
  }, [
    docsPanelState,
    hasCompletedFirstRender,
    parentSupportsSubscriptions,
    runTelemetry,
    setEmbeddableExplorerConfigOptions,
    shouldShowGlobalHeader,
    showHeadersAndEnvVars,
    theme,
    version,
  ]);

  return embeddableExplorerConfigOptions;
};
