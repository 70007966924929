import getIsChromatic from 'chromatic/isChromatic';
import type { registerApolloGraphQLLanguage } from 'register-apollo-graphql-language';

import packageJson from '../../../package.json';
import { GraphQLTypes } from '../graphqlTypes';

import { StudioConfigEnv, runtimeConfig } from './runtime';

const isChromatic = getIsChromatic();

const roleOrder = [
  GraphQLTypes.UserPermission.BILLING_MANAGER,
  GraphQLTypes.UserPermission.CONSUMER,
  GraphQLTypes.UserPermission.OBSERVER,
  GraphQLTypes.UserPermission.DOCUMENTER,
  GraphQLTypes.UserPermission.CONTRIBUTOR,
  GraphQLTypes.UserPermission.GRAPH_ADMIN,
  GraphQLTypes.UserPermission.ORG_ADMIN,
] as const;

const graphApiKeyOnlyRoles = [
  GraphQLTypes.UserPermission.LEGACY_GRAPH_KEY,
  GraphQLTypes.UserPermission.PERSISTED_QUERY_PUBLISHER,
] as const;

const orderedGraphRoles = [
  GraphQLTypes.UserPermission.CONSUMER,
  GraphQLTypes.UserPermission.OBSERVER,
  GraphQLTypes.UserPermission.DOCUMENTER,
  GraphQLTypes.UserPermission.CONTRIBUTOR,
  GraphQLTypes.UserPermission.GRAPH_ADMIN,
  // excludes GraphQLTypes.UserPermission.BILLING_MANAGER,
  // excludes GraphQLTypes.UserPermission.ORG_ADMIN,
] as const;

const orderedGraphApiKeyRoles = [
  GraphQLTypes.UserPermission.PERSISTED_QUERY_PUBLISHER,
  GraphQLTypes.UserPermission.CONSUMER,
  GraphQLTypes.UserPermission.OBSERVER,
  GraphQLTypes.UserPermission.DOCUMENTER,
  GraphQLTypes.UserPermission.CONTRIBUTOR,
  GraphQLTypes.UserPermission.GRAPH_ADMIN,
  // excludes GraphQLTypes.UserPermission.BILLING_MANAGER,
  // excludes GraphQLTypes.UserPermission.ORG_ADMIN,
  // excludes GraphQLTypes.LEGACY_GRAPH_KEY for the long-term reason that we do
  // not want to allow new API keys to be LEGACY_GRAPH_KEY.
] as const;

export type GraphApiKeyOnlyRole = typeof graphApiKeyOnlyRoles[number];
export type GraphApiKeyRoleOption = typeof orderedGraphApiKeyRoles[number];
export type UserRoleOption = typeof roleOrder[number];

export const Config = {
  apolloLanguageServerVersion:
    packageJson.apolloLanguageServerVersion as Parameters<
      typeof registerApolloGraphQLLanguage
    >[0]['version'],
  sentryEnabled:
    // Capture errors in cypress to make debugging CI easier, we may want to update this to use `isCypressUserEmail` in the future
    !!window.Cypress ||
    [StudioConfigEnv.Prod, StudioConfigEnv.Staging].includes(
      runtimeConfig.env as StudioConfigEnv,
    ),
  settings: runtimeConfig,
  appHost: isChromatic ? 'studio.apollographql.com' : window.location.host,
  gitCommitHash: process.env.GIT_HASH,
  buildTimestamp: Number(process.env.BUILD_TIMESTAMP),
  gaTrackingID: 'G-0BGG5V2W2K',
  analyticsPayload: {
    app: 'engine',
  },
  fallbackPlanOptions: {
    ranges: ['lastHour'],
    maxRangeInDays: 3,
  },
  service: {
    defaultVariant: 'current',
  },
  absoluteUrl: isChromatic
    ? `https://studio.apollographql.com`
    : `${window.location.protocol}//${window.location.host}/`,
  docsUrl: 'https://www.apollographql.com/docs',
  federationQuickstartDocsUrl:
    'https://www.apollographql.com/docs/federation/quickstart/',
  pricingUrl: 'https://www.apollographql.com/pricing',
  odysseyUrl: 'https://www.apollographql.com/tutorials/',
  apolloGitHubUrl: 'https://www.github.com/apollographql',
  cloudinaryUrl: 'https://res.cloudinary.com/apollographql/image/upload/',
  blogUrl: 'https://community.apollographql.com/',
  stagingSchemaEndpoint:
    'https://schema-reporting.api.staging.c0.gql.zone/api/graphql',
  platformApiStagingEndpoint: 'https://api-staging.apollographql.com/graphql',
  stagingTracesEndpoint: 'https://usage-reporting.api.staging.c0.gql.zone',
  queryParameters: {
    // Only passed from the embed urls so dev tools opts out of telemetry
    // If this value is ever renamed, remember to also
    // update the inlined reference to it in `getRunTelemetryLocalStorage.ts` added in 0834d087
    RunTelemetry: 'runTelemetry',
    DownloadAuditExportId: 'downloadAuditExportId',
    Overlay: 'overlay',
    SelectedTimeRange: 'range',
    SelectedTimeRangeFrom: 'from',
    SelectedTimeRangeTo: 'to',
    ActiveReportPaneTab: 'tab',
    SelectedTraceId: 'trace',
    SelectedQueryId: 'query',
    SelectedQueryName: 'queryName',
    QueryListMetricSort: 'metric',
    QueryListMetricFilter: 'metricFilter',
    ClientFilter: 'clients',
    TypeQuery: 'typeQuery',
    SelectedVariant: 'variant',
    ContractVariant: 'contractVariant',
    SourceVariant: 'sourceVariant',
    GraphRef: 'graphRef',
    SelectedDurationBucket: 'traceBucket',
    GraphId: 'graphId',
    Referrer: 'referrer', // referrer is removed from url on page load
    Source: 'utm_source', // utm_source is removed from url on page load
    Campaign: 'utm_campaign', // utm_campaign is removed from url on page load
    Medium: 'utm_medium', // utm_medium is removed from url on page load
    // Pulsar Insights Page URL params
    InsightsTab: 'insightsTab',
    CoordinateKind: 'coordinateKind',
    FieldListAfterCursor: 'fieldsAfter',
    FieldListBeforeCursor: 'fieldsBefore',
    FieldListMetricFilter: 'fieldsFilter',
    FieldListMetricSort: 'fieldsSort',
    FieldListSortDirection: 'fieldsDir', // one of 'asc', 'desc'
    FieldListPaginationDirection: 'fieldsPageDir',
    FieldListPage: 'fieldsPage',
    FieldListSearchFilter: 'fieldSearch',
    OperationListSortDirection: 'operationsDir', // one of 'asc', 'desc'
    OperationListSearchFilter: 'operationSearch',
    OperationListAfterCursor: 'operationsAfter',
    OperationListBeforeCursor: 'operationsBefore',
    OperationListMetricFilter: 'operationsFilter',
    OperationListMetricFilters: 'operationsFilters',
    OperationListMetricSort: 'operationsSort',
    OperationListPage: 'operationsPage',
    OperationListPaginationDirection: 'operationsPageDir',
    FastModeRange: 'fastModeRange',
    // Explorer document links
    ExplorerDocument: 'document',
    ExplorerVariables: 'variables',
    ExplorerHeaders: 'headers',
    ExplorerPreflightOperationScript: 'script',
    ExplorerPostflightOperationScript: 'postflightOperationScript',
    EmbeddableExplorerDefaultDocument: 'defaultDocument',
    EmbeddableExplorerDefaultVariables: 'defaultVariables',
    EmbeddableExplorerDefaultHeaders: 'defaultHeaders',
    EmbeddableExplorerDefaultCollectionEntryId: 'defaultCollectionEntryId',
    EmbeddableExplorerDefaultCollectionId: 'defaultCollectionId',
    ExplorerSearchQuery: 'searchQuery',
    ExplorerURLState: 'explorerURLState',
    ExplorerURLIncludeCookies: 'includeCookies',
    ExplorerEndpoint: 'endpoint',
    ExplorerSubscriptionEndpoint: 'subscriptionEndpoint',
    TriggerId: 'triggerId',
    ComparisonId: 'comparisonId',
    Token: 'token',
    Email: 'email',
    LoginError: 'loginError',
    LoginErrorMessage: 'loginErrorMessage',
    AuditExportFrom: 'auditExportFrom',
    AuditExportTo: 'auditExportTo',
    AuditExportUsers: 'auditExportUsers',
    AuditExportGraphs: 'auditExportGraphs',
    CollectionId: 'collectionId',
    FocusCollectionId: 'focusCollectionId',
    SavedCollectionId: 'savedCollectionId',
    SavedCollectionEntryId: 'savedCollectionEntryId',
    SharedCollectionOriginVariantRef: 'sharedCollectionOriginVariantRef',
    PublishSchemaArch: 'publishArch',
    PublishSchemaUpdate: 'publishUpdate',
    PrefillGraphRef: 'prefillGraphRef',
    ShouldPromptForNewSubgraphName: 'shouldPromptForNewSubgraphName',
    NewSubgraphName: 'newSubgraphName',
    ProposalCommentId: 'proposalCommentId',
    CommentThreadId: 'commentThreadId',
  } as const,
  cookies: {
    Referrer: 'referrer', // referrer is removed from url on page load
    Source: 'utm_source', // utm_source is removed from url on page load
    Campaign: 'utm_campaign', // utm_campaign is removed from url on page load
    Medium: 'utm_medium', // utm_medium is removed from url on page load
    MarketoMunchkin: '_mkto_trk', // Marketo munchkin tracking cookie
    GoogleClientId: 'tracking_google_client_id', // cookie sent to servers to be added on user create
    UserSegment: 'user_segment', // cookie sent to servers to be added on user create
    ReferringPath: 'referringPath', // cookie set by the signup_tracer package
  } as const,
  metricsTabs: [
    {
      title: 'Usage',
      isAvailableInDemo: true,
      isOperationLevel: true,
      isServiceLevel: true,
      isVisibleOnlyWhenActive: false,
      value: 'usage',
    },
    {
      title: 'Errors',
      isAvailableInDemo: true,
      isOperationLevel: true,
      isServiceLevel: true,
      isVisibleOnlyWhenActive: false,
      value: 'errors',
    },
    {
      title: 'Traces',
      isAvailableInDemo: true,
      isOperationLevel: true,
      isServiceLevel: false,
      isVisibleOnlyWhenActive: false,
      value: 'traces',
    },
  ],
  orgLayoutTabs: {
    Supergraphs: 'Supergraphs',
    ChampionDashboard: 'Dashboard',
    Plan: 'Plan',
    AuditLogs: 'Audit Logs',
    Management: 'Management',
  },
  settingsSections: {
    inviteLink: '#invite-link',
  },
  modals: {
    buildConfig: 'build-config',
    configureBuildPipeline: 'configure-build-pipeline',
    billingUpgrade: 'upgrade-plan',
    enterpriseUpgrade: 'enterprise-upgrade',
    newOrganization: 'new-organization',
    preflightScript: 'preflight-script',
    preflightOperationScript: 'preflight-operation-script',
    postflightOperationScript: 'postflight-operation-script',
    inviteMember: 'invite-member',
    updateBillingEmail: 'update-billing-email',
    updateDisplayName: 'update-display-name',
    updateID: 'update-id',
    updateCompanyUrl: 'update-domain',
    updateDefaultUserRole: 'update-default-user-role',
    updateAccountAvatar: 'update-account-avatar',
    updateGraphAvatar: 'update-graph-avatar',
    updateCreditCard: 'update-credit-card',
    upgrade: 'upgrade',
    updateUserName: 'update-user-name',
    createGraphApiToken: 'create-graph-api-token',
    newUserKey: 'new-user-key',
    updateUserEmail: 'update-user-email',
    updateUserAvatar: 'update-user-avatar',
    createGraph: 'create-graph',
    deleteGraph: 'delete-graph',
    publishYourSchema: 'publish-your-schema',
    setupMetrics: 'setup-metrics',
    supportRequest: 'support-request',
    queryTraceInspector: 'query-trace-inspector',
    createSlackChannel: 'create-slack-channel',
    createPagerDutyChannel: 'create-pd-channel',
    buildErrors: 'build-errors',
    launchErrors: 'launch-errors',
    configureContractWizard: 'configure-contract-wizard',
    editContractConfigWizard: 'edit-contract-config-wizard',
    viewContractDetails: 'view-contract-details',
    configurePersistedQueriesWizard: 'configure-pq-wizard',
    addNotificationWizard: 'add-notification-wizard',
    configureExcludedClients: 'configure-excluded-clients',
    appChangelog: 'app-changelog',
    addHiddenGraphMember: 'add-hidden-graph-member',
    updateGraphVisibility: 'update-graph-visibility',
    updatePublicVariant: 'update-public-variant',
    deleteGraphVariant: 'delete-graph-variant',
    updateProtectedVariant: 'update-protected-variant',
    createOrgInviteLink: 'create-org-invite-link',
    connectionSettings: 'connection-settings',
    cancelSubscription: 'cancel-subscription',
    cancelSubscriptionFeedback: 'cancel-subscription-feedback',
    reactivateSubscription: 'reactivate-subscription',
    newAuditLogExport: 'audit-export',
    upsertAlert: 'upsert-alert',
    downloadAuditLogExport: 'download-audit-export',
    explorerShareableLinkSettings: 'shareable-link-settings',
    supergraphDiffViewer: 'supergraph-diff-viewer',
    editGraphReadme: 'edit-graph-readme',
    addGraphHomepageLink: 'add-graph-homepage-link',
    atlasGraphPeek: 'peek',
    embedExplorer: 'embed-explorer',
    embedSandbox: 'embed-sandbox',
    publishSandboxCreateNewGraph: 'sandbox-create-new-graph',
    graphSettingsDescriptionUpdate: 'graph-settings-description-update',
    graphSettingsTitleUpdate: 'graph-settings-title-update',
    operationChecksSettings: 'operation-checks-settings',
    createPersonalOperationCollection:
      'create-personal-operation-collection' as const,
    createSharedOperationCollection:
      'create-shared-operation-collection' as const,
    deleteOperationCollection: 'delete-operation-collection' as const,
    configureOperationCollection: 'configure-operation-collection' as const,
    viewCollectionEntryDetails: 'view-collection-entry-details' as const,
    deleteOperationCollectionEntry:
      'delete-operation-collection-entry' as const,
    saveOperationCollectionEntry: 'save-operation-collection-entry' as const,
    duplicateOperationCollection: 'duplicate-operation-collection' as const,
    renameOperationCollectionEntry:
      'rename-operation-collection-entry' as const,
    viewCollectionDetails: 'view-collection-details' as const,
    saveCollectionLoginPrompt: 'save-collection-login-prompt' as const,
    saveMetricsOperationToCollection:
      'save-metrics-operation-to-collection' as const,
    convertAccount: 'convert-account' as const,
    cancelPaidSubscription: 'cancel-paid-subscription' as const,
    roverAddSubgraph: 'rover-add-subgraph' as const,
    roverAddVariant: 'rover-add-variant' as const,
    addSubgraph: 'add-subgraph' as const,
    reuploadSubgraph: 'reupload-subgraph' as const,
    addVariant: 'add-variant' as const,
    deleteSubgraph: 'delete-subgraph' as const,
    editSubgraphRoutingUrl: 'edit-subgraph-routing-url' as const,
    addSecret: 'add-secret' as const,
    deleteSecret: 'delete-secret' as const,
    requestHigherLimit: 'request-higher-limit' as const,
    initiatingCloudEndpoint: 'initiating-cloud-endpoint' as const,
    ignoreLinterRuleViolation: 'ignore-linter-rule-violation' as const,
    unignoreLinterRuleViolation: 'unignore-linter-rule-violation' as const,
    editProposalPermissions: 'edit-proposal-permissions' as const,
    editProposalNumberOfApprovers: 'edit-proposal-number-of-approvers' as const,
    proposeSchemaChanges: 'propose-schema-changes' as const,
    addProposalSubgraph: 'add-proposal-subgraph' as const,
    publishRevision: 'publish-revision' as const,
    renameProposalSubgraph: 'rename-proposal-subgraph' as const,
    deleteProposalSubgraph: 'delete-proposal-subgraph' as const,
    editProposalStatus: 'edit-proposal-status' as const,
    deleteProposalComment: 'delete-proposal-comment' as const,
    replaceRequestedReviewers: 'replace-requested-reviewers' as const,
    addReview: 'add-review' as const,
    manageCapacity: 'manage-capacity' as const,
    renameProposalTitle: 'rename-proposal-title' as const,
    deleteOrganizationBlocker: 'delete-organization-blocker' as const,
    setProposalNotificationStatus: 'set-proposal-notification-status' as const,
    setProposalDescriptionTemplate:
      'set-proposal-description-template' as const,
    editReApprovalRequirementOnChange:
      'edit-re-approval-requirement-on-change' as const,
  },
  roleNameByRoleEnum: {
    // this is our graph admin legacy role for tokens, but we don't want users
    // to feel like they have something wrong by having a legacy key. we expect
    // we'll probably have these "legacy" keys around our system forever, users
    // just won't be able to make new ones
    [GraphQLTypes.UserPermission.LEGACY_GRAPH_KEY]: 'Legacy Admin Key',
    [GraphQLTypes.UserPermission.BILLING_MANAGER]: 'Billing Manager',
    [GraphQLTypes.UserPermission.CONSUMER]: 'Consumer',
    [GraphQLTypes.UserPermission.CONTRIBUTOR]: 'Contributor',
    [GraphQLTypes.UserPermission.DOCUMENTER]: 'Documenter',
    [GraphQLTypes.UserPermission.GRAPH_ADMIN]: 'Graph Admin',
    [GraphQLTypes.UserPermission.OBSERVER]: 'Observer',
    [GraphQLTypes.UserPermission.ORG_ADMIN]: 'Org Admin',
    [GraphQLTypes.UserPermission.PERSISTED_QUERY_PUBLISHER]:
      'Persisted Query Publisher',
  },
  auditStatusByEnum: {
    [GraphQLTypes.AuditStatus.QUEUED]: 'Queued',
    [GraphQLTypes.AuditStatus.CANCELLED]: 'Cancelled',
    [GraphQLTypes.AuditStatus.IN_PROGRESS]: 'In Progress',
    [GraphQLTypes.AuditStatus.COMPLETED]: 'Ready',
    [GraphQLTypes.AuditStatus.FAILED]: 'Failed',
    [GraphQLTypes.AuditStatus.EXPIRED]: 'Expired',
  } as const,
  roleOrder,
  orderedUserOverrideRoles: roleOrder.filter(
    (role) =>
      ![
        GraphQLTypes.UserPermission.BILLING_MANAGER,
        GraphQLTypes.UserPermission.ORG_ADMIN,
      ].includes(role),
  ),
  orderedGraphRoles,
  orderedGraphApiKeyRoles,
  freeRoles: [
    GraphQLTypes.UserPermission.BILLING_MANAGER,
    GraphQLTypes.UserPermission.CONSUMER,
  ],
  roleIsGraphApiKeyOnly(
    p: GraphQLTypes.UserPermission,
  ): p is GraphApiKeyOnlyRole {
    return graphApiKeyOnlyRoles.includes(p as GraphApiKeyOnlyRole);
  },
  roleIsGraphApiKeyOption(
    p: GraphQLTypes.UserPermission | string,
  ): p is GraphApiKeyRoleOption {
    return orderedGraphApiKeyRoles.includes(p as GraphApiKeyRoleOption);
  },
  zenDesksupportTicketPriorities: {
    P0: {
      label: 'Urgent',
      description: 'Service is severely impacted',
    },
    P1: {
      label: 'High',
      description: 'Limited capabilities / unstable with interruptions',
    },
    P2: {
      label: 'Normal',
      description: 'Issue but still able to use critical functionality',
    },
    P3: {
      label: 'Low',
      description: 'Low-to-no service impact',
    },
    // P4 does not exist in Zendesk
    P4: {
      label: 'Low',
      description: 'Low-to-no service impact',
    },
  },
  jsmSupportTicketPriorities: {
    // P0 does not exist in JSM
    P0: {
      label: 'Urgent',
      description: 'Service is severely impacted',
    },
    P1: {
      label: 'Urgent',
      description: 'Service is severely impacted',
    },
    P2: {
      label: 'High',
      description: 'Limited capabilities / unstable with interruptions',
    },
    P3: {
      label: 'Normal',
      description: 'Issue but still able to use critical functionality',
    },
    P4: {
      label: 'Low',
      description: 'Low-to-no service impact',
    },
  },
};
