import { RouteConfig } from './RouteConfig';

export const catchAllRouteConfig = new RouteConfig<
  Record<string, undefined>,
  Record<string, undefined>,
  unknown,
  unknown
>({
  definition: '/',
});
