import { gql, useQuery } from '@apollo/client';
import { GraphQLError } from 'graphql';
import React from 'react';
import { Redirect, RouteComponentProps, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

import * as routes from 'src/app/onboarding/routes';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import { Loading } from '../loading/Loading';

import { useIdleAction } from './useIdleAction';

export const AUTH_QUERY = gql<
  GraphQLTypes.App__AuthQuery,
  GraphQLTypes.App__AuthQueryVariables
>`
  query App__AuthQuery {
    isLoggedIn @client
    # ensure that we send a request to the backend that sets our auth headers
    me {
      id
      ... on User {
        logoutAfterIdleMs
      }
    }
  }
`;

const goLogout = () => {
  window.location.href = '/logout';
};

/**
 * Redirect to the signup and onboarding flow, putting the current url in
 * local storage. When the user finishes either logging in and gets redirected
 * to the LoggedInRoute in RoutedApp ('/') or finishes signing up and hits the
 * LoggedInRoute, it will redirect them back to the
 * original requested url.
 */
export const LoggedInRoute = ({
  component: Component,
  loadingComponent,
  render,
  ...rest
}: RouteProps & {
  loadingComponent?: React.ReactElement;
}) => {
  if (!Component && !render) {
    throw new Error(
      'LoggedInRoute must be used with either a component or render function',
    );
  }
  const { loading, data } = useQuery(AUTH_QUERY, {
    fetchPolicy: 'network-only',
    context: appLinkContext({
      catchErrors: [
        ignorePermissionsErrors,
        (error: GraphQLError) => {
          // The server is telling us of an error that can be fixed by logging
          // in through a web browser. We can ignore this error since we later
          // redirect to the login page for the "logged-in: false" header.
          return error.extensions?.needsWebLogin === true;
        },
      ],
    }),
  });

  useIdleAction({
    timeMs:
      (data?.me?.__typename === 'User' ? data.me.logoutAfterIdleMs : null) ??
      null,
    stableCallback: goLogout,
  });

  const [redirectAfterLogin, setRedirectAfterLogin] =
    useLocalStorage('redirectAfterLogin');

  if (loading) return loadingComponent || <Loading />;

  const renderFn = (props: RouteComponentProps) => {
    if (data && typeof data.isLoggedIn === 'boolean') {
      if (!data.isLoggedIn) {
        const { pathname } = window.location;
        if (pathname === '/') {
          setRedirectAfterLogin(null);
        } else {
          setRedirectAfterLogin(props.location);
        }

        // TODO (jason) this is incredibly confusing. We should refactor
        // invite links to be more explicit about where they set the join
        // token so this is less hard to follow instead of relying on our
        // AuthenticatedRoutes to handle the redirect to the
        // AccountInvitationHandler after an account is created.
        // For invites and quick signup, go straight to signup
        if (/\/invite\//.test(pathname)) {
          return <Redirect to={routes.signup.location({ from: pathname })} />;
        }
        return <Redirect to={routes.login.location({ from: pathname })} />;
      }
      if (redirectAfterLogin) {
        setRedirectAfterLogin(null);
        return <Redirect to={redirectAfterLogin} />;
      }
    }
    return Component ? <Component {...props} /> : render ? render(props) : null;
  };

  return <Route {...rest} render={renderFn} />;
};

/**
 * Unlike the LoggedOutRoute, this route component doens't introduce any
 * sideeffects, where a logged in user trying to access a public page ends up
 * getting redirected to the home page of the dashboard.
 */
type PublicRouteProps = {
  component?: (props: Record<string, unknown>) => React.ReactElement;
  render?: (props: RouteComponentProps) => React.ReactNode;
} & RouteProps;
export const PublicRoute = ({
  component: Component,
  render,
  ...rest
}: PublicRouteProps) => {
  if (!Component && !render) {
    throw new Error(
      'PublicRoute must be used with either a component or render function',
    );
  }

  const renderFn = (props: RouteComponentProps) =>
    Component ? <Component {...props} /> : render ? render(props) : null;

  return <Route {...rest} render={renderFn} />;
};

export const LoggedOutRoute = ({
  component: Component,
  render,
  ...rest
}: {
  component?: (props: Record<string, unknown>) => React.ReactElement;
  render?: (props: RouteComponentProps) => React.ReactNode;
} & RouteProps) => {
  if (!Component && !render) {
    throw new Error(
      'LoggedOutRoute must be used with either a component or render function',
    );
  }
  const { loading, data } = useQuery(AUTH_QUERY, {
    fetchPolicy: 'network-only',
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
  });
  if (loading) return <Loading />;

  const renderFn = (props: RouteComponentProps) => {
    // If we are already logged in, go to the home page
    if (
      data &&
      (data.me || (typeof data.isLoggedIn === 'boolean' && data.isLoggedIn))
    ) {
      const from = window.location.pathname;
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from },
          }}
        />
      );
    }
    return Component ? <Component {...props} /> : render ? render(props) : null;
  };

  return <Route {...rest} render={renderFn} />;
};
