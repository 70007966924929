import { ApolloLink } from '@apollo/client';
import { GraphQLError, GraphQLErrorExtensions } from 'graphql';
import _ from 'lodash';

// Here's to hoping this grows and maybe even moves to the schema...
enum GraphQLErrorCode {
  'FEATURE_UNAVAILABLE' = 'FEATURE_UNAVAILABLE',
  'EXPIRED_SSO_SESSION' = 'EXPIRED_SSO_SESSION',
  'INVALID_API_KEY' = 'INVALID_API_KEY',
  'INTERNAL_SERVER_ERROR' = 'INTERNAL_SERVER_ERROR',
}
interface CodedGraphQLError extends GraphQLError {
  extensions: GraphQLErrorExtensions & { code: GraphQLErrorCode };
}

function isCodedGraphQLError(error: GraphQLError): error is CodedGraphQLError {
  return (
    _.has(error.extensions, 'code') &&
    Object.values(GraphQLErrorCode).includes(error.extensions.code)
  );
}
export interface CatchErrorsContext {
  /**
   * Callback functions which should return true if the error can be
   * caught, otherwise returns false.  Caught errors will not be forwarded to
   * the rest of the link chain.
   */
  catchErrors?: ((error: GraphQLError) => boolean)[];
}

export const isExpiredSessionError = (error: GraphQLError) =>
  isCodedGraphQLError(error) &&
  error.extensions.code === GraphQLErrorCode.EXPIRED_SSO_SESSION;

export const isInvalidApiKeyError = (error: GraphQLError) =>
  isCodedGraphQLError(error) &&
  error.extensions.code === GraphQLErrorCode.INVALID_API_KEY;

export const ignorePermissionsErrors = (error: GraphQLError) =>
  error.message.includes('NOT_ALLOWED_BY_USER_ROLE') ||
  error.message.includes('Your accounts does not include access to') ||
  error.message.includes('This API key does not have permission to') ||
  error.message.includes('permission denied');

export const ignorePlanErrors = (error: GraphQLError) => {
  if (isCodedGraphQLError(error)) {
    return error.extensions.code === GraphQLErrorCode.FEATURE_UNAVAILABLE;
  }
  return false;
};

export const isInternalServerError = (error: GraphQLError) =>
  isCodedGraphQLError(error) &&
  error.extensions.code === GraphQLErrorCode.INTERNAL_SERVER_ERROR;

export const catchErrors = new ApolloLink((operation, forward) => {
  // attempt to make this somewhat more type-safe
  const { catchErrors: catchErrorsFns }: CatchErrorsContext =
    operation.getContext();

  return forward(operation).map((result) => {
    if (result.errors) {
      const filteredErrors = result.errors.filter((e) => {
        try {
          const wasCaught = catchErrorsFns?.some((catchError) => catchError(e));
          return !wasCaught;
        } catch (error) {
          console.warn(error); // eslint-disable-line no-console
        }
        // if there is an error, keep bubbling the error up the chain
        return true;
      });
      return {
        ...result,
        errors: filteredErrors.length > 0 ? filteredErrors : undefined,
      };
    }
    return result;
  });
});
