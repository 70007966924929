import React from 'react';

import { useRouteParams } from 'src/hooks/useRouteParams';
import Config from 'src/lib/config';
import { catchAllRouteConfig } from 'src/lib/routeConfig/catchAllRoute';

import {
  atlasExplorerRouteConfig,
  embeddableExplorerRouteConfig,
  proposalRouteConfig,
  variantRouteConfig,
} from '../routes';

export interface GraphRef {
  graphId: string;
  graphVariant: string;
}

export const parseGraphRef = (
  graphRef: string | undefined,
): GraphRef | null => {
  if (!graphRef) return null;
  const elements = graphRef.split('@');
  const graphId = elements[0];
  const graphVariant =
    elements.length > 1 && elements[1]
      ? elements[1]
      : Config.service.defaultVariant;
  return graphId.length
    ? {
        graphId,
        graphVariant,
      }
    : null;
};

export const graphRefToString = <Ref extends GraphRef | null>(
  graphRef: Ref,
): Ref extends null ? null : string => {
  return (
    graphRef ? `${graphRef.graphId}@${graphRef.graphVariant}` : null
  ) as Ref extends null ? null : string;
};

export const useGraphRef = (): GraphRef | null => {
  // In the embeddable route:
  //  The graphRef lives in a query param in the form `graphId@variant`
  // TODO(Maya): maybe we should move this into context in case the query
  // params get wiped from navigation, its fragile right now
  const graphRefFromEmbeddableRoute = parseGraphRef(
    useRouteParams(embeddableExplorerRouteConfig, catchAllRouteConfig).graphRef,
  );
  // In the atlas route:
  //  The graphRef lives in a query param in the form `graphId@variant`
  const graphRefFromAtlasRoute = parseGraphRef(
    useRouteParams(atlasExplorerRouteConfig, catchAllRouteConfig).graphRef,
  );
  // In the non-embeddable routes:
  //  The graphRef lives in the route `/:graphVisibilityType(graph|public)/:graphId/variant/:variant`
  const { graphId, graphVariant } = useRouteParams(
    variantRouteConfig,
    proposalRouteConfig,
    catchAllRouteConfig,
  );

  return React.useMemo(
    () =>
      graphId
        ? {
            graphId,
            graphVariant: graphVariant ?? Config.service.defaultVariant,
          }
        : graphRefFromEmbeddableRoute
        ? {
            graphId: graphRefFromEmbeddableRoute.graphId,
            graphVariant: graphRefFromEmbeddableRoute.graphVariant,
          }
        : graphRefFromAtlasRoute
        ? {
            graphId: graphRefFromAtlasRoute.graphId,
            graphVariant: graphRefFromAtlasRoute.graphVariant,
          }
        : null,
    [
      graphId,
      graphRefFromEmbeddableRoute,
      graphRefFromAtlasRoute,
      graphVariant,
    ],
  );
};
