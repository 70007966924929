import { BillingPlanKind, BillingPlanTier } from 'src/lib/graphqlTypes/types';

interface Args {
  tier: BillingPlanTier | undefined;
  kind: BillingPlanKind | undefined;
}

interface Result {
  isUsageBasedPlan: boolean;
  isFreePlan: boolean;
  isLegacyFreePlan: boolean;
  isPaidPlan: boolean;
  isEnterprisePlan: boolean;
  isDedicatedPlan: boolean;
}

const FREE_PLANS: Array<BillingPlanKind> = [
  BillingPlanKind.COMMUNITY,
  BillingPlanKind.SERVERLESS_FREE,
];

const PAID_PLANS: Array<BillingPlanKind> = [
  BillingPlanKind.TEAM_PAID,
  BillingPlanKind.SERVERLESS_PAID,
  BillingPlanKind.DEDICATED,
];

/**
 * Centralized method for checking plan types
 *
 * @param param0 object including the tier and kind for the plan
 * @returns object including boolean values for isUsageBasedPlan, isEnterprisePlan, isFreePlan, isPaidPlan
 */
export const useCheckPlanTypes = ({ tier, kind }: Args): Result => {
  // Until the DEDICATED plan is associated with the USAGE_BASED tier,
  // we need to check for the DEDICATED kind to determine if a plan is
  // usage-based.
  const isUsageBasedPlan =
    tier === BillingPlanTier.USAGE_BASED || kind === BillingPlanKind.DEDICATED;
  const isEnterprisePlan = tier === BillingPlanTier.ENTERPRISE;
  const isLegacyFreePlan = tier === BillingPlanTier.COMMUNITY;
  const isFreePlan = isLegacyFreePlan || !!(kind && FREE_PLANS.includes(kind));
  const isPaidPlan = !isFreePlan && !!(kind && PAID_PLANS.includes(kind));
  const isDedicatedPlan = (kind as string) === 'DEDICATED'; // TODO: update once exposed;

  return {
    isLegacyFreePlan,
    isUsageBasedPlan,
    isEnterprisePlan,
    isFreePlan,
    isPaidPlan,
    isDedicatedPlan,
  };
};
