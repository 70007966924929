import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  ThemingProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type LinkProps = OmitChakraProps<ChakraLinkProps, keyof ThemingProps>;

export const Link = forwardRef<LinkProps, 'a'>((props, ref) => {
  return <ChakraLink {...props} ref={ref} />;
});
