import { Heading, Link as OrbitLink } from '@apollo/orbit';
import React from 'react';
import { Link } from 'react-router-dom';

import { raw as ImageAstronaut } from 'src/assets/astronaut.svg';
import { raw as ImageSatelite } from 'src/assets/satellite.svg';
import { raw as ImageTelescope } from 'src/assets/telescope.svg';

const images: Record<'telescope' | 'satellite' | 'astronaut', string> = {
  telescope: ImageTelescope,
  satellite: ImageSatelite,
  astronaut: ImageAstronaut,
};

interface Props {
  image?: 'telescope' | 'satellite' | 'astronaut';
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

export const NotFound = ({ image = 'telescope', title, subtitle }: Props) => (
  <div className="flex size-full items-center justify-center">
    <div className="max-w-md text-center">
      <img className="mb-10" src={images[image]} alt={image} />
      <Heading as="h2" size="2xl" className="mb-4">
        {title || 'Page not found'}
      </Heading>
      <div>
        {subtitle || (
          <>
            Huh! Looks like we can't find the page you're trying to reach, try{' '}
            <OrbitLink as={Link} to="/">
              going home
            </OrbitLink>
            .
          </>
        )}
      </div>
    </div>
  </div>
);
