import classnames from 'classnames';
import React from 'react';

const privacyPolicyPdfUrl =
  'https://www.apollographql.com/Apollo-Privacy-Policy.pdf';

export const PrivacyPolicyLink = ({
  className,
  href = privacyPolicyPdfUrl,
}: {
  className?: string;
  href?: string;
}) => (
  <a
    className={classnames('inline text-link underline', className)}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    Privacy Policy
  </a>
);
