import React from 'react';
import { Redirect } from 'react-router-dom';

interface Props extends Omit<React.ComponentProps<typeof Redirect>, 'to'> {
  to: () => React.ComponentProps<typeof Redirect>['to'];
}

/**
 * Wrapper around `react-router`'s `Redirect` component that will lazily call
 * `to` to generate the destination.
 *
 * This will prevent us from having to generate the URL unless are already
 * matching the route.
 */
export function LazyRedirect({ to: lazyTo, ...props }: Props) {
  return <Redirect to={lazyTo()} {...props} />;
}
