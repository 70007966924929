import { ApolloError } from '@apollo/client';
import IconArrowLeft from '@apollo/icons/default/IconArrowLeft.svg';
import IconArrowRight from '@apollo/icons/default/IconArrowRight.svg';
import {
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@apollo/orbit';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { ErrorPage } from 'src/components/errorPage/ErrorPage';
import { useClearMeFromApolloCache } from 'src/hooks/useClearMeFromApolloCache';
import { useIdentity } from 'src/hooks/useIdentity';
import { useTrackCustomEvent } from 'src/hooks/useTrackCustomEvent';
import { EventCategory, getTrackingCookie } from 'src/lib/analytics';
import { ampli } from 'src/lib/analytics/amplitude';
import config from 'src/lib/config';
import { generateUniqueString } from 'src/lib/generateUniqueString';
import { useLDFlag } from 'src/lib/launchDarkly';

import { SurveyQuestion } from '../../components/surveyQuestion/SurveyQuestion';
import { QUESTION_BANK, QUESTION_KEY, SURVEYS } from '../../constants';
import {
  hasAccountExistsErrorMsg,
  useCreateOrg,
  useDefaultAccountDetails,
  useUpdateSurveyMutation,
} from '../../hooks';

import { raw as UnifiedOnboardingBackground } from './assets/unified-onboarding-background.svg';
import { PlanDetailCard } from './components/planDetailCard/PlanDetailCard';
import { UserResearchOptIn } from './components/userResearchOptIn/UserResearchOptIn';

const SURVEY_ID = 'unified_onboarding';
const SURVEY = SURVEYS[SURVEY_ID];

interface Props {
  onOrgCreationSuccess?: () => void;
}

export const UnifiedOnboardingSurvey = ({ onOrgCreationSuccess }: Props) => {
  const collectUserResearchData = useLDFlag(
    'astro-growth-user-research-opt-in',
  );
  const clearMeFromCache = useClearMeFromApolloCache();
  const trackCustomEvent = useTrackCustomEvent();
  const { me, isUser, meLoading } = useIdentity();

  useEffect(() => {
    if (me && me.email) {
      ampli.onboardingSurveyStarted({
        Email: me.email,
      });
    }
  }, [me]);

  const defaultAccountDetails = useDefaultAccountDetails({
    accountId: '',
    fullName: me?.fullName || '',
    accountName: '',
  });
  const { createOrg, loading: isCreatingOrg } = useCreateOrg();
  const [updateSurveyState, { loading }] = useUpdateSurveyMutation();

  const [activeQuestionIdx, setActiveQuestionIdx] = useState(0);
  const [createOrgError, setCreateOrgError] = useState('');
  const [selectedPlanType, setSelectedPlanType] = useState<
    'serverless' | 'ent-trial' | ''
  >('');
  const [showLoader, setShowLoader] = useState(false);
  const [showUserResearchQuestion, setShowUserResearchQuestion] =
    useState(false);

  useEffect(() => {
    if (!isCreatingOrg && createOrgError && selectedPlanType) {
      setSelectedPlanType('');
    }
  }, [createOrgError, isCreatingOrg, selectedPlanType]);

  // TODO: gracefully handle by opting to just set them on a new plan?
  if (!SURVEY) return <ErrorPage />;

  // Add a little time padding before closing survey so loader does not flash on screen
  const handleCloseSurvey = () => {
    setShowLoader(true);
    setTimeout(() => {
      if (me?.id) clearMeFromCache({ userId: me.id });
      onOrgCreationSuccess?.();
    }, 1000);
  };

  const handleCreateOrg = async (
    formValues: Record<QUESTION_KEY, string>,
    planId?: 'sub-engine-ent-trial',
    uniqueId?: string,
  ) => {
    if (!me || !isUser) return;

    if (createOrgError) setCreateOrgError('');

    setSelectedPlanType(
      planId === 'sub-engine-ent-trial' ? 'ent-trial' : 'serverless',
    );

    await createOrg({
      userId: me.id,
      values: {
        accountId: `${defaultAccountDetails.accountId}${
          uniqueId ? `-${uniqueId}` : ''
        }`, // default account id
        companyUrl: '',
        accountName: defaultAccountDetails.accountName, // default account name
      },
      planId,
    })
      .then(async (results) => {
        if (!results.isSuccess) throw results.error;
        if (!results.orgId) throw new Error('Failed to create a new org');

        // fire studio_sign_up event if org creation is successful
        trackCustomEvent({
          action: 'studio_sign_up',
          category: 'Created account' as EventCategory,
          label: 'Studio sign up flow',
          referringPath: getTrackingCookie(config.cookies.ReferringPath),
          email: me?.email,
        });

        try {
          await updateSurveyState({
            variables: {
              orgId: results.orgId,
              surveyId: SURVEY_ID,
              surveyState: SURVEY.map((questionKey) => ({
                questionKey,
                answerValue: formValues[questionKey]?.slice(0, 200) || '',
              })),
            },
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }

        if (collectUserResearchData) {
          setShowUserResearchQuestion(true);
        } else {
          handleCloseSurvey();
        }
      })
      .catch(async (err) => {
        setSelectedPlanType('');
        if (hasAccountExistsErrorMsg(err.message)) {
          await handleCreateOrg(formValues, planId, generateUniqueString());
        }
        setCreateOrgError(
          err instanceof ApolloError
            ? err.message
            : 'Failed to create a new organization for you. Please try again.',
        );
      });
  };

  const handleContinue = () => setActiveQuestionIdx((prevIdx) => prevIdx + 1);
  const handleBack = () => setActiveQuestionIdx((prevIdx) => prevIdx - 1);
  const handleUserResearchOptIn = async (consentsToContact: boolean) => {
    await ampli.unifiedOnboardingUserResearch({
      'Consented to Contact': consentsToContact,
      Email: me?.email ?? '',
      'Full Name': me?.fullName ?? '',
    });
    handleCloseSurvey();
  };

  return (
    <Formik
      initialValues={SURVEY.reduce(
        (result: Record<QUESTION_KEY, string>, current: QUESTION_KEY) => {
          const question = QUESTION_BANK[current];
          return { ...result, [question.name]: '' };
        },
        {} as Record<QUESTION_KEY, string>,
      )}
      onSubmit={async (values) => {
        Object.entries(values).forEach(([key, value]) => {
          if (key === 'primary_goal') {
            const question = QUESTION_BANK[key];
            const answer =
              question.options?.find((option) => {
                return option.value === value;
              })?.label || value;
            ampli?.unifiedOnboardingPrimaryGoal({
              'Answer Text': answer,
              'Question Text': question.label,
            });
          }

          if (key === 'current_use_case') {
            const question = QUESTION_BANK[key];
            const answer =
              question.options?.find((option) => {
                return option.value === value;
              })?.label || value;
            ampli?.unifiedOnboardingUseCase({
              'Answer Text': answer,
              'Question Text': question.label,
            });
          }
        });
      }}
      enableReinitialize
    >
      {({ values, handleSubmit }) => {
        const visibleQuestions = SURVEY.filter((questionKey) => {
          const question = QUESTION_BANK[questionKey];
          return !question?.checkIfVisible || question?.checkIfVisible(values);
        });
        const surveyLength = visibleQuestions?.length ?? 0;
        const activeQuestion = visibleQuestions[activeQuestionIdx]
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            QUESTION_BANK[visibleQuestions[activeQuestionIdx]!]
          : null;

        // TODO: revisit this default question
        const name: QUESTION_KEY = activeQuestion?.name || 'primary_goal';
        const value: string = values[name];

        return (
          <Form
            className="m-10 flex min-h-10 min-w-10 flex-col rounded-2xl font-body"
            data-theme="new-system-light"
            placeholder={undefined}
          >
            {createOrgError && <ErrorMessage>{createOrgError}</ErrorMessage>}

            {meLoading || defaultAccountDetails.loading || showLoader ? (
              <div className="m-auto flex h-52 w-40 items-center justify-center">
                <Spinner className="flex" size="lg" />
              </div>
            ) : showUserResearchQuestion ? (
              <UserResearchOptIn
                handleConsentSelection={handleUserResearchOptIn}
              />
            ) : (
              <>
                <div className="flex flex-row items-center justify-center align-middle">
                  <IconButton
                    aria-label="Continue"
                    onClick={handleBack}
                    className={classnames(
                      'focus:focus-ring h-fit min-h-0 min-w-0 cursor-pointer bg-transparent p-4 hover:bg-transparent hover:opacity-90',
                      activeQuestionIdx !== 0 ? 'visible' : 'invisible',
                    )}
                  >
                    <IconArrowLeft />
                  </IconButton>
                  <div className="text-code-sm mx-8 text-center font-semibold uppercase">
                    Step {activeQuestionIdx + 1} of {surveyLength + 1}
                  </div>
                  <IconButton
                    aria-label="Continue"
                    onClick={handleContinue}
                    className={classnames(
                      'focus:focus-ring h-fit min-h-0 min-w-0 cursor-pointer bg-transparent p-4 hover:bg-transparent hover:opacity-90',
                      activeQuestionIdx < surveyLength && value
                        ? 'visible'
                        : 'invisible',
                    )}
                  >
                    <IconArrowRight />
                  </IconButton>
                </div>

                {activeQuestion ? (
                  <SurveyQuestion
                    label={activeQuestion.label}
                    options={activeQuestion.options}
                    name={activeQuestion.name}
                    value={value}
                    onAnswerChange={handleContinue}
                    className="text-center font-heading"
                  />
                ) : activeQuestionIdx === surveyLength ? (
                  <>
                    <FormLabel className="mb-8 text-center font-heading text-2xl font-medium text-brand-primary">
                      Choose where to run your Apollo Router
                    </FormLabel>
                    <div className="flex flex-row gap-6">
                      <PlanDetailCard
                        tag="Cloud"
                        title="Fully-managed"
                        description="Get started quickly with GraphOS Cloud hosted GraphQL infrastructure."
                        details={[
                          'Free up to 10m requests',
                          'Full set of observability tools',
                          'Schema registry',
                        ]}
                        ctaProps={{
                          type: 'submit',
                          content: 'Start building today',
                          onClick: () => {
                            handleSubmit();
                            handleCreateOrg(values);
                            ampli.unifiedOnboardingPlanSelection({
                              'Plan Type': 'Cloud - Serverless Free',
                            });
                          },
                          isLoading:
                            selectedPlanType === 'serverless' &&
                            (loading || isCreatingOrg),
                        }}
                        variant="light"
                        className="flex-1"
                      />
                      <PlanDetailCard
                        tag="Enterprise"
                        title="Self-hosted"
                        description="Deploy to your private cloud. Free for 28 days."
                        details={[
                          'Unlimited requests',
                          'Advanced security & orchestration',
                          'Dedicated account manager',
                        ]}
                        ctaProps={{
                          type: 'submit',
                          content: 'Start a free trial',
                          onClick: () => {
                            handleSubmit();
                            handleCreateOrg(values, 'sub-engine-ent-trial');
                            ampli.unifiedOnboardingPlanSelection({
                              'Plan Type': 'Enterprise Trial',
                            });
                          },
                          isLoading:
                            selectedPlanType === 'ent-trial' &&
                            (loading || isCreatingOrg),
                        }}
                        variant="dark"
                        className="flex-1"
                      />
                    </div>
                  </>
                ) : null}
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export const UnifiedOnboardingSurveyModal = () => {
  const [showSurvey, setShowSurvey] = useState(true);

  useEffect(() => {
    document.title = 'GraphOS Onboarding';
  }, []);

  return (
    <Modal
      isOpen={showSurvey}
      onClose={() => null}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="2xl"
    >
      <ModalOverlay
        style={{
          backgroundImage: `url("${UnifiedOnboardingBackground}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        className="bg-brand-primary theme-container-new-system-light"
      />
      <ModalContent className="m-0 min-h-10 min-w-10 rounded-2xl bg-brand-tertiary p-0 theme-container-new-system-light">
        <ModalBody className="m-0 flex justify-center overflow-hidden p-0 align-middle">
          <UnifiedOnboardingSurvey
            onOrgCreationSuccess={() => {
              setShowSurvey(false);
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
